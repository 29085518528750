import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { CyberTable } from '../../lib/cyber-components/table'
import { LabelSmall, ParagraphSmall } from 'baseui/typography'

export const generateQuantityCaption = totals => {
  const keys = Object.keys(totals)
  let result = ' '

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = totals[key]
    result += `${value} ${key}`

    if (i !== keys.length - 1) {
      result += ', '
    }
  }

  return result
}

const OrdersDetails = ({ orders, totals, appointmentPreference }) => {
  const { t } = useTranslation()

  const columns = [
    t('Orders.ShowSchedulers.OrderInfoTableHeaders.Order'),
    ...(appointmentPreference?.showQuantityOnAppointmentCreation
      ? [t('Orders.ShowSchedulers.OrderInfoTableHeaders.Quantity')]
      : []),
    ...(appointmentPreference?.showWeightOnAppointmentCreation
      ? [t('Orders.ShowSchedulers.OrderInfoTableHeaders.Weight')]
      : []),
    ...(appointmentPreference?.showDeliveryInstructionsOnAppointmentCreation
      ? [t('Orders.ShowSchedulers.OrderInfoTableHeaders.DeliveryInstructions')]
      : [])
  ]

  const data = orders.map(order => [
    order.number,
    ...(appointmentPreference?.showQuantityOnAppointmentCreation
      ? [`${order.quantity || '-'} ${(order.quantity && order.quantityUom?.name) || ''}`]
      : []),
    ...(appointmentPreference?.showWeightOnAppointmentCreation
      ? [`${order.weight || '-'} ${(order.weight && order.weightUom?.name) || ''}`]
      : []),
    ...(appointmentPreference?.showDeliveryInstructionsOnAppointmentCreation
      ? [order.deliveryInstructions]
      : [])
  ])

  return (
    <FlexGridItem display="flex" alignItems="start" justifyContent="start">
      <FlexGrid marginBottom="scale1200">
        <FlexGridItem display="flex" alignItems="start" justifyContent="start">
          <CyberTable columns={columns} data={data} />
        </FlexGridItem>
        <FlexGridItem display="flex" alignItems="start" justifyContent="start" marginTop="scale600">
          <LabelSmall display="contents">Total:</LabelSmall>
          <ParagraphSmall display="contents">{generateQuantityCaption(totals)}</ParagraphSmall>
        </FlexGridItem>
      </FlexGrid>
    </FlexGridItem>
  )
}

export default OrdersDetails
