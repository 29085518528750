import React, { useState } from 'react'
import { HeadingXSmall } from 'baseui/typography'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { Block } from 'baseui/block'
import { useTranslation } from 'react-i18next'
import StyledSpinner from 'components/shared/styled-spinner'
import useSearchClient from 'components/shared/hooks/use-search-client'
import CyberSearchBooleanRefinement from 'cyber/search/boolean-refinement'
import { RAILS_ENV } from 'components/constants/rails-env'
import { SHIPPER } from 'components/constants/user-types'
import ConnectedPagination from './connected-pagination'
import { ConnectedSearchbox } from './ConnectedSearchbox'
import ShipperUserInstantSearchTable from './ShipperUserInstantSearchTable'
import { UserRolesProvider } from '../../contexts/user-roles.context'
import ConnectedRefinementList from '../../schedulers/SchedulerUserInstantSearch/connected-refinement-list'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import Header from 'components/ui/generic/Header'
import FilterSummary from 'components/appointments/appointment-instantsearch/FilterSummary'

const INDEX_NAME = `user_${RAILS_ENV}`
const FACET_FILTERS = [`user_type:${SHIPPER}`]

const Filters = ({ appliedFilters, setAppliedFilters }) => (
  <FiltersPopover tag={Object.entries(appliedFilters).length}>
    <Block
      backgroundColor="#fff"
      overrides={{
        Block: {
          style: () => ({
            maxWidth: '284px'
          })
        }
      }}>
      <HeadingXSmall margin="0 0 8px">Filters</HeadingXSmall>
      <Block display="flex" flexDirection="column" gridRowGap="scale400">
        <CyberSearchBooleanRefinement
          attributes={['admin']}
          setAppliedFilters={setAppliedFilters}
        />
        <ConnectedRefinementList attribute="user_role_name" setAppliedFilters={setAppliedFilters} />
      </Block>
    </Block>
  </FiltersPopover>
)

export const ShipperUserInstantSearch = () => {
  const { t } = useTranslation()
  const client = useSearchClient({
    queryBy: 'name,email_address,phone_number'
  })
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  if (!client) {
    return <StyledSpinner />
  }

  return (
    <UserRolesProvider>
      <InstantSearch indexName={INDEX_NAME} searchClient={client}>
        <Header
          title={t('Settings.SideMenu.Titles.InternalUsers.Text')}
          rightItems={[
            <Filters appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />,
            <ConnectedSearchbox />
          ]}
        />

        <Configure facetFilters={FACET_FILTERS} />
        <Block paddingBottom="scale200" minHeight="60vh">
          {Object.keys(appliedFilters).length > 0 && (
            <FilterSummary
              numberOfHits={numberOfHits}
              appliedFilters={appliedFilters}
              label="user"
            />
          )}
          <ShipperUserInstantSearchTable setNumberOfHits={setNumberOfHits} />
        </Block>
        <ConnectedPagination />
      </InstantSearch>
    </UserRolesProvider>
  )
}
