import { FlexGridItem } from 'baseui/flex-grid'
import { PencilSimple, Check } from '@phosphor-icons/react'
import { LabelXSmall, ParagraphXSmall } from 'baseui/typography'
import React, { ReactElement, useState } from 'react'
import { DATE_FORMAT } from 'components/constants/date-format'
import { upperFirst, camelCase } from 'lodash'
import { useTranslation } from 'react-i18next'

import TimePicker from 'components/ui/generic/TimePicker'
import DatePicker from 'components/ui/generic/DatePicker'
import FormControl from 'components/ui/generic/FormControl'
import { Milestones, MILESTONES_LABELS } from '../../../../models/Milestones'
import TimeFormatter from '../../../../utils/time-formatter'
import { ButtonStyled, Icon, MilestonesWrapper } from './MilestonesSection.styled'

interface MilestoneItemProps {
  milestonesKey: string
  milestones: Milestones
  updateMilestone: (milestonesKey: string, value?: Date) => void
  updateMilestoneState: (milestonesKey: string, value?: Date) => void
}

const MilestoneItem = ({
  milestonesKey,
  milestones,
  updateMilestone,
  updateMilestoneState
}: MilestoneItemProps): ReactElement => {
  const [editableMode, setEditableMode] = useState(false)
  const { t } = useTranslation()

  return milestones[milestonesKey] || editableMode ? (
    <MilestonesWrapper
      flexGridColumnCount={2}
      editableMode={editableMode}
      flexGridColumnGap="scale200"
      milestonesKey={milestonesKey}>
      <FlexGridItem height="20px">
        <LabelXSmall>
          {t(
            `Appointments.EditAppointmentModal.Milestones.${upperFirst(
              camelCase(milestonesKey)
            )}.Text`
          )}
        </LabelXSmall>
      </FlexGridItem>
      <FlexGridItem alignSelf="start" justifyContent="end" display="flex" height="20px">
        {editableMode ? (
          <Icon
            onClick={() => {
              updateMilestone(milestonesKey)
              setEditableMode(false)
            }}>
            <Check />
          </Icon>
        ) : (
          <Icon onClick={() => setEditableMode(prev => !prev)}>
            <PencilSimple />
          </Icon>
        )}
      </FlexGridItem>
      {!editableMode && (
        <ParagraphXSmall margin="0">
          {new TimeFormatter('fullDate').format(milestones[milestonesKey])}
        </ParagraphXSmall>
      )}
      {editableMode && (
        <>
          <FlexGridItem>
            <FormControl label={t(`Appointments.EditAppointmentModal.Milestones.Fields.Date.Text`)}>
              <DatePicker
                value={milestones[milestonesKey] ? [new Date(milestones[milestonesKey])] : null}
                onChange={({ date }) => {
                  updateMilestoneState(milestonesKey, date as any)
                }}
                formatString={DATE_FORMAT}
                placeholder={DATE_FORMAT.toUpperCase()}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl label={t(`Appointments.EditAppointmentModal.Milestones.Fields.Time.Text`)}>
              <TimePicker
                value={milestones[milestonesKey] ? new Date(milestones[milestonesKey]) : null}
                onChange={date => {
                  updateMilestoneState(milestonesKey, date as any)
                }}
                step={60}
              />
            </FormControl>
          </FlexGridItem>
        </>
      )}
    </MilestonesWrapper>
  ) : (
    <MilestonesWrapper
      flexGridColumnCount={2}
      editableMode={editableMode}
      milestonesKey={milestonesKey}>
      <FlexGridItem alignItems="center" justifyContent="start" display="flex">
        <LabelXSmall>{MILESTONES_LABELS[milestonesKey]}</LabelXSmall>
      </FlexGridItem>
      <FlexGridItem alignItems="center" justifyContent="end" display="flex">
        <ButtonStyled size="mini" onClick={() => updateMilestone(milestonesKey, new Date())}>
          {MILESTONES_LABELS[milestonesKey]}
        </ButtonStyled>
      </FlexGridItem>
    </MilestonesWrapper>
  )
}

export default MilestoneItem
