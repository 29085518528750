import React, { useState } from 'react'

import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Header from 'components/ui/generic/Header'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/specific/PrimaryButton'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { StyledLink } from 'baseui/link'
import authenticatedFetch from '../utils/authenticated-fetch'
import { fancyToast } from '../utils'

import { useHistory } from 'react-router-dom'
import Checkbox from 'components/ui/generic/Checkbox'
import { useTranslation } from 'react-i18next'

const NewOutboundCall = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [outboundCall, setOutboundCall] = useState<any>({})
  const history = useHistory()
  const { t } = useTranslation()
  const createOutboundCall = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/outbound_calls.json`,
      method: 'POST',
      body: {
        outboundCall: {
          ...outboundCall,
          // We need to get the correct OrderID, hardcoding this until we defined the designs
          orderId: 'fef071c9-ce53-4bb8-92e7-59eb8273db05'
        }
      }
    })

    if (status === 201) {
      fancyToast({ info: 'Outbound Call was successfully created' }, status)
      history.push('/settings/outbound_calls')
    } else {
      fancyToast(json, status)
      setLoading(false)
    }
  }

  return (
    <>
      <Header
        title={
          <Breadcrumbs
            overrides={{
              ListItem: {
                style: ({ $theme }) => ({
                  fontFamily: $theme.typography.HeadingXSmall.fontFamily
                })
              }
            }}>
            <StyledLink href="/settings/outbound_calls/new">new outbound call</StyledLink>
          </Breadcrumbs>
        }
      />

      <FlexGrid maxWidth="640px">
        <FlexGridItem>
          <FormControl htmlFor="outbound_call[to]" label={'to'}>
            <Input
              id="outbound_call[to]"
              autoComplete="off"
              to="outbound_call[to]"
              value={outboundCall.to}
              onChange={e => {
                setOutboundCall({
                  ...outboundCall,
                  to: e.currentTarget.value
                })
              }}
              maxLength={30}
            />
          </FormControl>
        </FlexGridItem>

        <FlexGridItem>
          <Button isLoading={loading} onClick={createOutboundCall}>
            {t('Common.Button.Create.Text')}
          </Button>
        </FlexGridItem>
      </FlexGrid>
    </>
  )
}

export default NewOutboundCall
