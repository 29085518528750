import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AddButton } from 'components/components/AddButton'
import { Block } from 'baseui/block'
import { ButtonGroup, SIZE } from 'baseui/button-group'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import DeleteOrderConfirmationModal from 'components/pages/OrderList/DeleteOrderConfirmationModal'
import {
  MagnifyingGlass,
  UploadSimple,
  Trash,
  FileSearch,
  CaretDown,
  Circle,
  CaretUp,
  CalendarBlank,
  PhoneCall,
  PhonePlus,
  Phone
} from '@phosphor-icons/react'
import { Modal } from 'baseui/modal'
import { Order } from 'components/models/Order'
import { PAGE_SIZE } from 'components/constants/page-size'
import Pagination from 'components/components/Pagination'
import { PaginationResponse } from 'components/types/pagination-response'
import { ParagraphLarge, ParagraphSmall } from 'baseui/typography'
import { camelCase, upperFirst } from 'lodash'
import { fancyToast } from 'components/utils'
import { orderService } from 'components/services/order/order.service'
import { useDebounce, useLocalStorage } from 'react-use'
import { useHistory } from 'react-router-dom'
import { useHttp } from 'components/hooks/use-http'
import { useOrdersContext } from 'components/contexts/orders.context'
import { usePagination } from 'components/hooks/use-pagination'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import StyledSpinner from '../../shared/styled-spinner'
import StandarFilterPanel from '../../base/shared/standarFilterPanel'
import OrderForm from './OrderCreateOrEdit'
import BulkUploadOrdersModal from './bulk-upload-orders-modal'
import CallModal from './CallModal'
import { OrderSearchButtonContainer, OrderSearchContainer } from './OrderList.styled'
import Button from 'components/ui/generic/Button'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import Input from 'components/ui/generic/Input'
import Header from 'components/ui/generic/Header'
import Table from 'components/ui/generic/Table'
import Tag from 'components/ui/generic/Tag'
import OrderFilterSummary from './OrderFilterSummary'
import ColumnsSelector from 'components/ui/specific/ColumnsSelector'
import { useOrderPreferencesContext } from 'components/contexts/order-preferences.context'
import moment from 'moment'
import { MM_DD_YYYY } from 'components/utils/time-formats'
import ORDER_CUSTOM_FIELDS_NAMES from '../../utils/custom-fields-constants'
import authenticatedFetch from 'components/utils/authenticated-fetch'

const SearchBar = ({ getSearchKeyword, user, searchLabel = '', cleanSelectedOrder }) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <OrderSearchContainer
      className={css({
        display: 'flex',
        gap: theme.sizing.scale500,
        [theme.mediaQuery.medium]: {
          gap: theme.sizing.scale600
        }
      })}>
      <Input
        startEnhancer={<MagnifyingGlass />}
        autoComplete="off"
        name="number"
        value={searchLabel}
        onChange={evt => getSearchKeyword(evt.currentTarget.value)}
        placeholder={t('Orders.SearchBox.Title.Text')}
      />
      {user.admin && (
        <OrderSearchButtonContainer>
          <AddButton
            onClick={() => cleanSelectedOrder()}
            title={t('Orders.CreateOrderModal.Header.Text')}
          />
        </OrderSearchButtonContainer>
      )}
    </OrderSearchContainer>
  )
}

const OrderList = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const {
    orders: { isModalOpen },
    actions: { setModal, closeModal, clearForm, setSelectedOrder }
  } = useOrdersContext()
  const {
    orderPreferences: {
      orderPreference: { customFieldLabelOne, customFieldLabelTwo }
    }
  } = useOrderPreferencesContext()
  const { t } = useTranslation()
  const [cachedColumns, setCachedColumns] = useLocalStorage(`ORDER_COLUMNS_BY_USER_AND_ORG`, {})

  const INITIAL_COLUMNS = [
    { name: 'OrderNumber', position: 1, disabled: true },
    { name: 'OrderType', position: 2 },
    { name: 'Facility', position: 3 },
    { name: 'Priority', position: 4 },
    { name: 'Status', position: 5 },
    { name: 'Customer', position: 6 },
    { name: 'StartDatePeriod', position: 7, isDate: true },
    { name: 'EndDatePeriod', position: 8, isDate: true },
    { name: 'CustomerPO', position: 9 },
    { name: 'LoadId', position: 10 },
    { name: 'Products', position: 11 },
    { name: 'Carrier', position: 12 },
    { name: 'UnitOfMeasure', position: 13 },
    { name: 'Quantity', position: 14 },
    { name: 'WeightUnitOfMeasure', position: 15 },
    { name: 'Weight', position: 16 },
    { name: 'CustomFieldOne', position: 17 },
    { name: 'CustomFieldTwo', position: 18 },
    { name: 'Actions', position: 19, disabled: true }
  ]

  const [search, setSearch] = useState<string>()
  const [queryParamsFilters, setQueryParamsFilters] = useState<string>()
  const [selectedOrderToDelete, setSelectedOrderToDelete] = useState<Order>(undefined)
  const [selectedOrderToCall, setSelectedOrderToCall] = useState<Order>(undefined)
  const { page, pageSize, setPage } = usePagination()
  const [showBulkModal, setShowBulkModal] = useState<boolean>(false)
  const [showCallModal, setShowCallModal] = useState<boolean>(false)
  const [shownColumns, setShownColumns] = useState(
    cachedColumns &&
      cachedColumns[currentUser?.shipperId] &&
      cachedColumns[currentUser?.shipperId][currentUser?.id]
      ? cachedColumns[currentUser?.shipperId][currentUser?.id]
      : INITIAL_COLUMNS.slice(0, 6).concat(INITIAL_COLUMNS[INITIAL_COLUMNS.length - 1])
  )
  const [findOrders, { data, loading }] = useHttp<PaginationResponse<Order>>(
    orderService.findOrders
  )

  const [css, theme] = useStyletron()
  const results = data?.results || []
  const history = useHistory()

  const DEFAULT_FILTER_PARAMS = [
    {
      attr: 'status',
      items: [
        {
          label: t('Orders.Table.Filters.Status.Items.Open.Text'),
          value: 'open',
          isActive: false
        },
        {
          label: t('Orders.Table.Filters.Status.Items.Closed.Text'),
          value: 'closed',
          isActive: false
        }
      ]
    },
    {
      attr: 'order_type',
      items: [
        {
          label: t('Orders.Table.Filters.OrderType.Items.SalesOrder.Text'),
          value: 'sales',
          isActive: false
        },
        {
          label: t('Orders.Table.Filters.OrderType.Items.PurchaseOrder.Text'),
          value: 'purchase',
          isActive: false
        },
        {
          label: t('Orders.Table.Filters.OrderType.Items.ReceivingOrder.Text'),
          value: 'receiving',
          isActive: false
        }
      ]
    },
    {
      attr: 'priority',
      items: [
        {
          label: t('Orders.Table.Filters.Priority.Items.High.Text'),
          value: 'high',
          isActive: false
        },
        {
          label: t('Orders.Table.Filters.Priority.Items.Regular.Text'),
          value: 'regular',
          isActive: false
        },
        {
          label: t('Orders.Table.Filters.Priority.Items.Low.Text'),
          value: 'low',
          isActive: false
        }
      ]
    }
  ]

  const [filterParams, setFilterParams] = useState(DEFAULT_FILTER_PARAMS)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useDebounce(() => findOrders({ page, pageSize, search, queryParamsFilters }), 200, [
    page,
    pageSize,
    search,
    queryParamsFilters
  ])
  useEffect(() => {
    if (currentUser?.userType !== 'shipper') {
      history.push('/')
    }
  }, [currentUser])

  const removeOrder = useCallback(
    async (id: string) => {
      const [_result, status] = await orderService.deleteOrder(id)
      if (status === 204) {
        findOrders({ page, pageSize, search, queryParamsFilters })
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.Order.Text'),
              action: t('Common.Actions.Deleted.Text')
            })
          },
          200
        )
      } else {
        fancyToast(
          {
            info: t('Common.Errors.Interpolated.Text', {
              model: t('Common.ModelType.Order.Text'),
              action: t('Common.Actions.Deleted.Text')
            })
          },
          500
        )
      }
    },
    [page, pageSize, search, queryParamsFilters]
  )

  const cleanOrderPreviousSelection = () => {
    clearForm()
    setModal(true)
  }

  const onCloseModal = useCallback(() => {
    closeModal()
    findOrders({ page, pageSize, search, queryParamsFilters })
  }, [page, pageSize, search, queryParamsFilters])

  const [callLoading, setCallLoading] = useState<boolean>(false)

  const [phone, setPhone] = React.useState('')

  const createOutboundCall = async (orderId: string) => {
    setCallLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/outbound_calls.json`,
      method: 'POST',
      body: {
        outboundCall: {
          to: '+1' + phone,
          orderId
        }
      }
    })

    if (status === 201) {
      fancyToast({ info: 'Outbound Call has been successfully scheduled' }, status)
    } else {
      fancyToast(json, status)
    }

    setCallLoading(false)
  }

  const orderRow = (order: Order) => {
    const flattenOrder = {
      ...order,
      carrier: order?.carrier?.name,
      customer: order?.scheduler?.name,
      facility: order?.facility?.name,
      unitOfMeasure: order?.quantityUom?.name,
      weightUnitOfMeasure: order?.weightUom?.name
    }
    const selectableFields = INITIAL_COLUMNS.filter(
      e => !e.disabled && shownColumns.some(selectedColumn => selectedColumn.name === e.name)
    ).map(e => {
      if (e.name === 'Priority') {
        let priority = <span />
        switch (flattenOrder.priority) {
          case 'low':
            priority = (
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                })}>
                <CaretDown /> {upperFirst(flattenOrder.priority)}
              </div>
            )
            break
          case 'high':
            priority = (
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                })}>
                <CaretUp color="#D92179" /> {upperFirst(flattenOrder.priority)}
              </div>
            )
            break
          default:
            priority = (
              <div
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                })}>
                <Circle color="#2666ff" /> {upperFirst(flattenOrder.priority)}
              </div>
            )
        }

        return priority
      }
      if (e.name === 'Status') {
        return <Tag closeable={false}>{flattenOrder.status}</Tag>
      }
      return e.isDate && flattenOrder[`${camelCase(e.name)}`]
        ? moment(flattenOrder[`${camelCase(e.name)}`]).format(MM_DD_YYYY)
        : upperFirst(flattenOrder[`${camelCase(e.name)}`])
    })
    return [
      <a href="#" onClick={() => setSelectedOrder(order.id)}>
        <span className={`priority-indicator priority-${order?.priority}`} />
        <strong
          className={css({
            color: '#04112B'
          })}>
          {order.number}
        </strong>
      </a>,
      ...selectableFields,

      <ButtonGroup shape="circle" size={SIZE.mini}>
        {!phone ? (
          <Button
            onClick={() => {
              setShowCallModal(true)
              setSelectedOrderToCall(order)
            }}
            title="Add phone to schedule">
            <PhonePlus />
          </Button>
        ) : (
          <Button
            isLoading={callLoading}
            onClick={() => {
              createOutboundCall(order.id)
            }}
            title="Schedule with CallAI">
            <Phone style={{ cursor: 'pointer' }} />
          </Button>
        )}

        <Button onClick={() => console.log('click')} title="Schedule appointment">
          <CalendarBlank style={{ cursor: 'pointer' }} />
        </Button>

        <Button onClick={() => setSelectedOrderToDelete(order)} title="Delete order">
          <Trash style={{ cursor: 'pointer' }} />
        </Button>
      </ButtonGroup>
    ]
  }

  const onCallClose = () => {
    setShowCallModal(false)
    setCallLoading(false)
    setSelectedOrderToCall(undefined)
  }

  const onUploadClick = useCallback(() => {
    setShowBulkModal(prev => !prev)
    findOrders({ page, pageSize, search, queryParamsFilters })
  }, [page, pageSize, search, queryParamsFilters])

  const countTotalFilters = filters => {
    const totalCount = filters?.reduce(
      (count, filter) => count + filter?.items?.filter(item => item?.isActive).length,
      0
    )
    return totalCount > 0 ? totalCount : ''
  }

  const columnLabelCallback = ({ name }) => {
    if (name === ORDER_CUSTOM_FIELDS_NAMES.customFieldOne && customFieldLabelOne.length) {
      return customFieldLabelOne
    }

    if (name === ORDER_CUSTOM_FIELDS_NAMES.customFieldTwo && customFieldLabelTwo.length) {
      return customFieldLabelTwo
    }

    return t(`Orders.Table.Columns.${name}.Label.Text`)
  }

  const toggleColumn = (checked, { name, position }) =>
    !checked
      ? setShownColumns(prevState =>
          prevState.filter(c => c.name !== name).sort((a, b) => a.position - b.position)
        )
      : setShownColumns(prevState =>
          [...prevState, { name, position }].sort((a, b) => a.position - b.position)
        )

  useEffect(() => {
    setCachedColumns({
      ...cachedColumns,
      [currentUser?.shipperId]: {
        ...((cachedColumns && cachedColumns[currentUser?.shipperId]) || {}),
        [currentUser?.id]: shownColumns
      }
    })
    // fancyToast(
    //   {
    //     info: t(
    //       'New order P1676565 from Consignee Y to be delivered Oct 24, 2024. Add a phone number to schedule an appointment via CallAI.'
    //     )
    //   },
    //   200
    // )
  }, [shownColumns, currentUser?.shipperId, currentUser?.id])

  if (!currentUser) {
    return <StyledSpinner />
  }

  console.log('Phone:', phone)

  return (
    <>
      <Header
        title={t('Orders.Header.Text')}
        rightItems={[
          <Button onClick={onUploadClick} startEnhancer={() => <UploadSimple />}>
            {t('Orders.UploadButton.Text')}
          </Button>,
          <FiltersPopover tag={countTotalFilters(filterParams)}>
            <StandarFilterPanel
              getQueryParams={e => {
                setQueryParamsFilters(e)
                setPage(1)
              }}
              filtersList={filterParams}
              setFiltersList={setFilterParams}
            />
          </FiltersPopover>,
          <ColumnsSelector
            labelCallback={columnLabelCallback}
            shownColumns={shownColumns}
            initialColumns={INITIAL_COLUMNS}
            toggleColumn={toggleColumn}
          />,
          <SearchBar
            getSearchKeyword={e => setSearch(e)}
            user={currentUser}
            searchLabel={search}
            cleanSelectedOrder={cleanOrderPreviousSelection}
          />
        ]}
      />

      <Block width="100%">
        {countTotalFilters(filterParams) > 0 && (
          <ParagraphSmall display="inline" width="auto" marginRight="scale300">
            {t('Orders.Table.Filters.Showing.Text')}{' '}
            <b>
              {data?.total}{' '}
              {data?.total > 1
                ? t('Orders.Table.Filters.Order.Multiple')
                : t('Orders.Table.Filters.Order.Text')}
            </b>{' '}
          </ParagraphSmall>
        )}
        <OrderFilterSummary filters={filterParams} setFiltersList={setFilterParams} />
        <Block paddingBottom="scale200" minHeight="60vh">
          {loading ? (
            <Block display="flex" justifyContent="center" alignItems="center">
              <StyledSpinner />
            </Block>
          ) : results.length === 0 ? (
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                paddingTop: theme.sizing.scale1600
              })}>
              <FileSearch size={theme.sizing.scale2400} />
              <ParagraphLarge>{t('Orders.Table.NoOrdersFound.Text')}</ParagraphLarge>
            </div>
          ) : (
            <Table
              columns={shownColumns.map(columnLabelCallback)}
              data={results.map((order: Order) => orderRow(order))}
            />
          )}
        </Block>
        <Pagination
          total={Math.ceil(data?.total / PAGE_SIZE) || 1}
          page={page}
          onPageChange={setPage}
        />
      </Block>

      <Modal
        size="auto"
        overrides={{
          Dialog: { style: { width: '100%', maxWidth: '1230px' } },
          Close: {
            style: ({ $theme }) => ({
              right: $theme.sizing.scale800,
              top: $theme.sizing.scale800
            })
          }
        }}
        isOpen={isModalOpen}
        onClose={onCloseModal}
        closeable
        animate
        autoFocus
        unstable_ModalBackdropScroll>
        <OrderForm onClose={onCloseModal} />
      </Modal>
      <DeleteOrderConfirmationModal
        order={selectedOrderToDelete}
        setSelectedOrderToDelete={setSelectedOrderToDelete}
        onConfirm={removeOrder}
      />
      <CallModal
        showCallModal={showCallModal}
        onClose={onCallClose}
        order={selectedOrderToCall}
        setCallLoading={setCallLoading}
        phone={phone}
        setPhone={setPhone}
      />
      <BulkUploadOrdersModal showBulkModal={showBulkModal} onClose={onUploadClick} />
    </>
  )
}

export default OrderList
