import React from 'react'

import { camelCase, upperFirst } from 'lodash'
import { useStyletron } from 'baseui'
import ChildOption from './childOption'
import { LabelMedium } from 'baseui/typography'
import { useTranslation } from 'react-i18next'

import Accordion from 'components/ui/generic/Accordion'

const ParentOption = ({ attribute, items, reloadList }) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <Accordion
      disabled={false}
      title={t(`Common.Dropdown.Titles.${upperFirst(camelCase(attribute))}.Text`)}>
      {items?.length > 0 ? (
        items.map((item, i) => {
          return (
            <ChildOption
              i={i}
              isChecked={item.isActive}
              label={item.label}
              updateItem={() => reloadList(items, item, attribute)}
            />
          )
        })
      ) : (
        <LabelMedium color={theme.colors.contentInverseTertiary}>
          {t('Common.Dropdown.NoOptions.Text')}
        </LabelMedium>
      )}
    </Accordion>
  )
}

export default ParentOption
