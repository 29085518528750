import React from "react";
import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1 } from "baseui/typography";
import { useStyletron } from "baseui";

const Success = () => {
  const [css, theme] = useStyletron();
  return (
    <HeadingLevel>
      <Heading>Schedule</Heading>
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
        })}
      >
        <img
          className={css({ width: "300px" })}
          src="https://storage.googleapis.com/terusama-beta/animated-check.gif"
        />

        <Paragraph1>Your appointment has been successfully updated.</Paragraph1>
      </div>
    </HeadingLevel>
  );
};

export default Success;
