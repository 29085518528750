import { useStyletron } from 'baseui'
import { ParagraphMedium } from 'baseui/typography'
import React from 'react'

const ResultBox = ({
  type,
  content
}: {
  type: string
  content: { label: string; text: string | string[] }
}): JSX.Element => {
  const [css] = useStyletron()

  return (
    <div className={css({ ...(type === 'single' && { display: 'flex' }) })}>
      {type === 'single' ? (
        <>
          <ParagraphMedium margin="0" className={css({ fontWeight: '600' })}>
            {content.label}:&nbsp;
          </ParagraphMedium>
          <ParagraphMedium margin="0">{content.text}</ParagraphMedium>
        </>
      ) : (
        <>
          <ParagraphMedium margin="0" className={css({ fontWeight: '600' })}>
            {content.label}
          </ParagraphMedium>
          <div className={css({ flexDirection: 'column' })}>
            {typeof content.text === 'object' ? (
              content.text.map(caption => <ParagraphMedium margin="0">{caption}</ParagraphMedium>)
            ) : (
              <ParagraphMedium margin="0">{content.text}</ParagraphMedium>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ResultBox
