import FormControl from 'components/ui/generic/FormControl'
import React, { ReactElement, useEffect, useState } from 'react'
import { Facility } from '../../models/Facility'
import ScrollableColumn from './ScrollableColumn'
import { ScrollableContainer, SelectTimeHeader } from './SelectTime.styled'

interface AvailableTimeSlots {
  timeSlot: string
  available: true
}

interface SelecTimeProps {
  availableTimeslots: AvailableTimeSlots[]
  facility: Facility
  onTimeSelect: Function
  setAppointment: Function
}

const ELEMENTS_PER_COLUMN = 4

const SelectTime = ({
  availableTimeslots,
  facility,
  onTimeSelect,
  setAppointment
}: SelecTimeProps): ReactElement => {
  const [formattedTimeslots, setFormattedTimeslots] = useState([])

  useEffect(() => {
    const newFormattedTimeslots = []
    let temp = []

    availableTimeslots.forEach((el, idx) => {
      temp.push(el)
      if ((idx + 1) % ELEMENTS_PER_COLUMN === 0 || availableTimeslots.length === idx + 1) {
        newFormattedTimeslots.push(temp)
        temp = []
      }
    })

    setFormattedTimeslots(newFormattedTimeslots)
  }, [availableTimeslots])

  return (
    <FormControl label="Time">
      <ScrollableContainer>
        {formattedTimeslots.map(setOfTimeslots => (
          <ScrollableColumn
            setOfTimeslots={setOfTimeslots}
            onTimeSelect={onTimeSelect}
            facility={facility}
            setAppointment={setAppointment}
          />
        ))}
      </ScrollableContainer>
    </FormControl>
  )
}

export default SelectTime
