import { Card, StyledAction, StyledBody } from "baseui/card";
import React from "react";
import { useStyletron } from "baseui";
import { ListItem, ListItemLabel } from "baseui/list";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { truncate } from "../../shared/css-mixins";
import TimeFormatter from "../../utils/time-formatter";

const AppointmentListItem = ({ selected, appointment }) => {
  const [css, theme] = useStyletron();
  const {
    arrivalTime,
    purchaseOrderIdentifiers,
    createdByOrganizationName,
    timeZone,
  } = appointment;
  const formattedArrivalTime = new TimeFormatter("shortTime").format(
    arrivalTime,
    timeZone
  );

  return (
    <FlexGrid
      overrides={{
        Block: { style: { borderBottom: `solid 1px ${theme.colors.mono700}` } },
      }}
      backgroundColor={selected ? theme.colors.accent100 : null}
      margin="scale0"
      padding="scale0"
      flexGridColumnCount={1}
      flexGridColumnGap="scale100"
      flexGridRowGap="scale100"
    >
      <FlexGridItem>
        <div className={css({ ...truncate, ...theme.typography.LabelMedium })}>
          {formattedArrivalTime}
        </div>
      </FlexGridItem>
      <FlexGridItem>
        <div className={css({ ...truncate, ...theme.typography.LabelMedium })}>
          {purchaseOrderIdentifiers}
        </div>
      </FlexGridItem>
      <FlexGridItem>
        <div className={css({ ...truncate, ...theme.typography.LabelMedium })}>
          {createdByOrganizationName}
        </div>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default AppointmentListItem;
