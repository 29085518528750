import { ButtonGroup } from 'baseui/button-group'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'
import { capitalize } from 'lodash'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import React, { useContext } from 'react'
import useSizing from 'components/shared/hooks/use-sizing'
import { useTranslation } from 'react-i18next'

const DockTypes = ({ questions, setQuestions, index }) => {
  const { assignedEquipmentTypes } = useContext(CurrentUserContext)
  const question = questions[index]
  const { equipmentTypeIds } = question
  const selected =
    equipmentTypeIds?.map(dockType => {
      return assignedEquipmentTypes?.findIndex(eqType => eqType.id === dockType)
    }) || []
  const custombreakpoints = useSizing()
  const { t } = useTranslation()

  const onClick = (dockTypeIndex: number) => {
    const newQuestions = [...questions]
    const dockType = assignedEquipmentTypes[dockTypeIndex]

    if (!equipmentTypeIds) {
      newQuestions[index].equipmentTypeIds = [dockType.id]
      setQuestions(newQuestions)
      return
    }

    if (!equipmentTypeIds?.includes(dockType.id)) {
      newQuestions[index].equipmentTypeIds = [...equipmentTypeIds, dockType.id]
      setQuestions(newQuestions)
    } else {
      newQuestions[index].equipmentTypeIds = equipmentTypeIds?.filter(
        value => value !== dockType.id
      )
      setQuestions(newQuestions)
    }
  }

  return (
    <FormControl label={t('Settings.AppointmentTypes.CustomQuestions.EquipmentTypes')}>
      <ButtonGroup
        mode="checkbox"
        selected={selected}
        size="compact"
        overrides={{
          Root: {
            style: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: '8px'
            }
          }
        }}
        onClick={(event, dockTypeIndex) => onClick(dockTypeIndex)}>
        {assignedEquipmentTypes.map((dockType, index) => {
          return (
            <Button key={index} type="button">
              {capitalize(dockType.name)}
            </Button>
          )
        })}
      </ButtonGroup>
    </FormControl>
  )
}

export default DockTypes
