import Input from 'components/ui/generic/Input'
import React, { FormEvent, ReactElement } from 'react'

interface TextTypeProps {
  value: string
  onChange: (e: FormEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: boolean
  prompt?: string
}

const TextType = ({ value, onChange, disabled, error, prompt }: TextTypeProps): ReactElement => (
  <Input onChange={onChange} value={value || ''} disabled={disabled} error={error} name={prompt} />
)

export default TextType
