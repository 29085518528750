import { Card, StyledBody } from "baseui/card";
import React from "react";
import { Block } from "baseui/block";
import { Heading } from "baseui/heading";

const GuestCheckinCard = ({ selected }) => {
  return (
    <Card
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return {
              userSelect: "none",
              boxSizing: "border-box",
              height: "100%",
              border: `solid 2px ${
                selected ? $theme.colors.negative400 : $theme.colors.negative100
              }`,
            };
          },
        },
      }}
    >
      <Block display="flex" alignItems="center" justifyContent="center">
        <Heading>Other</Heading>
      </Block>
    </Card>
  );
};

export default GuestCheckinCard;
