import { useStyletron } from 'baseui'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { HeadingXSmall, ParagraphXSmall } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DriversSearchInput = ({
  value,
  handleValueUpdate,
  getResults
}: {
  value: string
  handleValueUpdate: (string) => void
  getResults: () => void
}): JSX.Element => {
  const [css] = useStyletron()
  const { t } = useTranslation()

  return (
    <div>
      <HeadingXSmall margin="16px 0 0" className={css({ fontWeight: '600' })}>
        {t('DriversCheckin.Form.Label')}
      </HeadingXSmall>
      <ParagraphXSmall margin="0 0 32px">{t('DriversCheckin.Form.Caption')}</ParagraphXSmall>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
        <Input
          value={value}
          startEnhancer={<MagnifyingGlass />}
          autoComplete="off"
          onChange={e => handleValueUpdate(e.currentTarget.value)}
          key="search-input"
          placeholder={t('DriversCheckin.Form.Placeholder')}
        />
        <Button size="default" onClick={getResults}>
          {t('DriversCheckin.Form.Button')}
        </Button>
      </div>
    </div>
  )
}

export default DriversSearchInput
