import { useStyletron } from 'baseui'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const velostics = require('../../../assets/images/velostics-logo.svg') as string

const Logo = () => {
  const [css] = useStyletron()

  return (
    <NavLink to="/" activeStyle={{ display: 'block' }}>
      <img
        className={css({
          width: '80px',
          height: '28px',
          top: '25px'
        })}
        src={velostics}
        id="appLogo01"
        alt="Velostics Logo"
      />
    </NavLink>
  )
}

export default Logo
