import { Block } from 'baseui/block'
import { StyledSpinnerNext } from 'baseui/spinner'
import { EARLY, LATE, ON_TIME } from 'components/checkins/check-in-statuses'
import moment from 'moment'
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { Appointment } from '../../../../appointments/types'
import { StatusCodes } from '../../../../constants/http-status-codes'
import { CurrentUserContext } from '../../../../homepage/current-user-context'
import { Milestones, MILESTONES_LABELS, MILESTONE_KEYS } from '../../../../models/Milestones'
import { milestoneService } from '../../../../services/milestone.service'
import { fancyToast } from '../../../../utils'
import MilestoneItem from './MilestoneItem'
import { useTranslation } from 'react-i18next'
import { upperFirst, camelCase } from 'lodash'

interface MilestonesProps {
  appointment: Appointment
  setAppointment: Dispatch<SetStateAction<Appointment>>
}

const MilestonesSection = ({ appointment, setAppointment }: MilestonesProps): ReactElement => {
  const { currentUser } = useContext(CurrentUserContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [milestones, setMilestones] = useState<Milestones>({
    status: '',
    checkedOutAt: null,
    checkedInAt: null,
    inGateAt: null,
    atDockAt: null,
    outDockAt: null,
    facilityId: appointment?.facilityId,
    appointmentId: appointment?.id,
    userId: currentUser.id,
    id: appointment?.checkinId
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (appointment.checkinId) {
      setLoading(true)
      milestoneService.get(appointment.checkinId).then(([result, status]) => {
        if (status === StatusCodes.OK) {
          setMilestones(result)
        }
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [appointment?.checkinId])

  const updateMilestoneState = (key: string, value: Date) => {
    setMilestones({
      ...milestones,
      [key]: value
    })
  }

  const updateMilestone = (key: string, value?: Date) => {
    const arrivalTime = moment(appointment.arrivalTime)
    let updatedMilestones
    let updatedStatus = ON_TIME

    if (arrivalTime.diff(moment(), 'minutes') > 30) {
      updatedStatus = EARLY
    }

    if (arrivalTime.diff(moment(), 'minutes') < -30) {
      updatedStatus = LATE
    }

    if (value) {
      updatedMilestones = {
        ...milestones,
        status: key === 'checkedInAt' ? updatedStatus : milestones.status,
        [key]: value
      }
    }

    milestoneService.update(updatedMilestones || milestones).then(([result, status]) => {
      if (status === StatusCodes.OK || status === StatusCodes.CREATED) {
        setMilestones(result)
        if (!appointment.checkinId) {
          setAppointment(prevAppointment => ({
            ...prevAppointment,
            checkinId: result.id
          }))
        }
        fancyToast(
          {
            info: t('Common.Info.Interpolated.Text', {
              model: t('Common.ModelType.Timestamp.Text'),
              action: t('Common.Actions.Updated.Text')
            })
          },
          status
        )
      } else {
        fancyToast(
          {
            info: t('Common.Errors.Interpolated.Text', {
              model: t('Common.ModelType.Timestamp.Text'),
              action: t('Common.Actions.Updated.Text')
            })
          },
          status
        )
      }
    })
  }

  return (
    <>
      {loading ? (
        <Block display="flex" justifyContent="center" paddingTop="10px">
          <StyledSpinnerNext />
        </Block>
      ) : (
        MILESTONE_KEYS.map((element: string) => (
          <MilestoneItem
            milestonesKey={element}
            milestones={milestones}
            updateMilestone={updateMilestone}
            updateMilestoneState={updateMilestoneState}
          />
        ))
      )}
    </>
  )
}
export default MilestonesSection
