import Select from 'components/ui/generic/Select'
import React from 'react'

const ReasonCode = ({ appointment, setAppointment, disabled = false }) => {
  const comment = appointment?.comment
  return (
    <Select
      id="reason-code"
      placeholder="Select a reason..."
      searchable={false}
      clearable={false}
      disabled={disabled}
      options={[
        { label: 'User Error', id: 'User Error' },
        { label: 'Carrier Unavailable', id: 'Carrier Unavailable' },
        { label: 'Weather Issue', id: 'Weather Issue' },
        { label: 'Traffic', id: 'Traffic' },
        { label: 'Production issue', id: 'Production issue' },
        { label: 'Customer request', id: 'Customer request' }
      ]}
      value={
        comment
          ? [
              {
                id: comment,
                label: comment
              }
            ]
          : []
      }
      onChange={({ option, type }) => {
        setAppointment({
          ...appointment,
          comment: option.id
        })
      }}
    />
  )
}

export default ReasonCode
