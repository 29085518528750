import React from 'react'
import { useStyletron } from 'baseui'
import {
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
  ALIGN
} from 'baseui/header-navigation'
import { HeadingXSmall } from 'baseui/typography'

const VHeader = ({ title, leftItems = [], centerItems = [], rightItems = [], overrides }) => {
  const [css, theme] = useStyletron()
  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: ({ $theme }) => {
            return {
              paddingTop: $theme.sizing.scale600,
              paddingBottom: $theme.sizing.scale600,
              paddingLeft: $theme.sizing.scale600,
              paddingRight: $theme.sizing.scale600,
              marginLeft: '-16px',
              marginRight: '-16px',
              borderBottomColor: $theme.colors.gray,
              marginBottom: $theme.sizing.scale800,
              backgroundColor: $theme.colors.white,
              [theme.mediaQuery.medium]: {
                backgroundColor: $theme.colors.transparent,
                borderBottomColor: $theme.colors.transparent,
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 0,
                marginRight: 0
              }
            }
          }
        },
        ...overrides
      }}>
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem className={css({ paddingLeft: '0 !important' })}>
          <HeadingXSmall margin={`${theme.sizing.scale400} 0`}>{title}</HeadingXSmall>
        </StyledNavigationItem>
        {leftItems && leftItems.length > 0
          ? leftItems.map((item, index) => (
              <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
            ))
          : ''}
      </StyledNavigationList>
      <StyledNavigationList
        $align={ALIGN.center}
        className={css({
          display: 'none',
          [theme.mediaQuery.large]: {
            display: 'inline'
          }
        })}>
        {centerItems && centerItems.length > 0
          ? centerItems.map((item, index) => (
              <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
            ))
          : ''}
      </StyledNavigationList>
      {rightItems && rightItems.length > 0 ? (
        <StyledNavigationList $align={ALIGN.right} className={css({})}>
          {rightItems.map((item, index) => (
            <StyledNavigationItem key={index}>{item}</StyledNavigationItem>
          ))}
        </StyledNavigationList>
      ) : (
        ''
      )}
    </HeaderNavigation>
  )
}
export default VHeader
