import React, { useState, useContext } from 'react'
import { HeadingLevel, Heading } from 'baseui/heading'
import { ProgressSteps, Step } from 'baseui/progress-steps'
import { Paragraph1 } from 'baseui/typography'
import DriverInformation from './steps/driver-information'
import LoadDetails from './steps/load-details'
import AdditionalInformation from './steps/additional-information'
import Finish from './steps/finish'
import { CurrentUserContext } from '../../homepage/current-user-context'
import InactiveTimer from '../../utils/inactive-timer'
import useSizing from '../../shared/hooks/use-sizing'
import { authService } from 'components/services/auth.service'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'
import { useTranslation } from 'react-i18next'

export const StepContext = React.createContext({
  step: null,
  setStep: null
})

const CheckinKiosk = () => {
  const { isMediumAndUp } = useSizing()
  const { currentFacility } = useCurrentFacilityContext()
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()

  // This isn't really a boolean, which is why I'm not just Integer.parseInt(!currentUser.name)
  const [step, setStep] = useState<number>(currentUser.name ? 1 : 0)
  const [user, setUser] = useState<any>({
    ...currentUser,
    notificationPreferenceAttributes: {
      ...currentUser.notificationPreferenceAttributes
    }
  })
  const [appointment, setAppointment] = useState<any>(null)

  const formattedTitle = (index, title) => {
    return step === index && isMediumAndUp ? <Heading>{title}</Heading> : title
  }

  if (!currentFacility) {
    return (
      <Paragraph1>
        We were unable to locate facility <code>{location.host.split('.')[0]}</code>.{' '}
        {t('Constants.AssistanceMessage.Text')}
      </Paragraph1>
    )
  }

  return (
    <HeadingLevel>
      <InactiveTimer onTimer={authService.logout} timeoutSeconds={300} />
      <Heading>{currentFacility.name} Check-in</Heading>
      <StepContext.Provider value={{ ...{ step, setStep } }}>
        <HeadingLevel>
          <ProgressSteps
            current={step}
            overrides={{
              Root: { style: { width: '100%' } }
            }}>
            <Step title={formattedTitle(0, 'Your Information')}>
              <DriverInformation {...{ user, setUser }} />
            </Step>
            <Step title={formattedTitle(1, 'Select Appointment')}>
              <LoadDetails
                selectedAppointment={appointment}
                setSelectedAppointment={setAppointment}
              />
            </Step>
            <Step title={formattedTitle(2, 'Additional Information')}>
              <AdditionalInformation {...{ appointment, setAppointment }} />
            </Step>
            <Step title={formattedTitle(3, 'Finish')}>
              <Finish />
            </Step>
          </ProgressSteps>
        </HeadingLevel>
      </StepContext.Provider>
    </HeadingLevel>
  )
}

export default CheckinKiosk
