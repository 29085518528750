import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { StyledLink } from 'baseui/link'
import { ParagraphMedium, ParagraphXSmall } from 'baseui/typography'
import { DOCUMENT_TYPE } from 'components/models/Question'
import { getFileName } from 'components/utils/parse-filename'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ResultBox from '../ResultBox'
import { AnimatedCheck, BtnContainer, MessageContainer } from './DriversUpdateAnswersMessage.styled'

const DriversUpdateAnswersMessage = ({
  appointment,
  reset
}: {
  appointment: any
  reset: () => void
}): JSX.Element => {
  const [css] = useStyletron()
  const { t } = useTranslation()

  return (
    <MessageContainer>
      <AnimatedCheck
        src="https://storage.googleapis.com/terusama-beta/animated-check.gif"
        alt="Appointment checkin"
      />

      <ParagraphMedium className={css({ textAlign: 'center' })}>
        {t('DriversCheckin.UpdateAnswersMessage.Label')}
      </ParagraphMedium>
      <ParagraphXSmall>{t('DriversCheckin.UpdateAnswersMessage.Caption')}</ParagraphXSmall>
      <div>
        <ResultBox
          type="multi"
          content={{
            label: t('DriversCheckin.UpdateAnswersMessage.AppointmentId'),
            text: appointment.confirmationId
          }}
          key={appointment.confirmationId}
        />
        <ResultBox
          type="single"
          content={{
            label: appointment.purchaseOrderLabel,
            text: appointment.purchaseOrderIdentifiers.split(',').map((p, i, arr) => (
              <span key={p}>
                {` ${p}`}
                {i !== arr.length - 1 && ','}
              </span>
            ))
          }}
          key={appointment.purchaseOrderLabel ?? 'PO#'}
        />
        {appointment?.answersAttributes
          ?.sort((a, b) => a.question.position - b.question.position)
          .map(ans => (
            <>
              {ans.type === DOCUMENT_TYPE ? (
                <>
                  <ParagraphMedium className={css({ fontWeight: '600' })}>
                    {ans.prompt}:
                  </ParagraphMedium>
                  <ul>
                    {ans.attachedDocuments.map(doc => (
                      <li>
                        <StyledLink href={doc?.documentSecureUrl}>
                          {getFileName(doc?.documentSecureUrl)}
                        </StyledLink>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <ResultBox
                  key={ans.prompt}
                  type="single"
                  content={{ label: ans.prompt, text: ans.response }}
                />
              )}
            </>
          ))}
      </div>
      <BtnContainer>
        <Button onClick={reset}>{t('DriversCheckin.UpdateAnswersMessage.Button')}</Button>
      </BtnContainer>
    </MessageContainer>
  )
}

export default DriversUpdateAnswersMessage
