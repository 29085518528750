import React from 'react'
import { Link } from 'react-router-dom'

const OutboundCalls = () => {
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    fetch('/outbound_calls.json')
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching data:', error))
  }, [])

  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Created At</th>
          <th>Human Finished At</th>
          <th>
            <Link to="/settings/outbound_calls/new">Create New Call</Link>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(call => (
          <tr key={call.id}>
            <td>{call.id}</td>
            <td>{call.created_at}</td>
            <td>{call.human_finished_at}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default OutboundCalls
