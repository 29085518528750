import {
  SET_IS_ANSWER_LIST_UPDATED,
  SET_IS_CHECKED_IN,
  SET_LOADING,
  SET_MATCHES,
  SET_PICKED_APPOINTMENT,
  SET_SHOW_QUESTION_LIST,
  UPDATE_SEARCH_TERM
} from './actions'

interface DriversCheckinState {
  searchTerm: string
  matches: Array<any>
  pickedAppointment: any
  isCheckedIn: boolean
  loading: boolean
  isAnswerListUpdated: boolean
  showQuestionList: boolean
  isFormDisabled: boolean
}

export const initialState = {
  searchTerm: '',
  matches: [],
  pickedAppointment: null,
  isCheckedIn: false,
  loading: false,
  isAnswerListUpdated: false,
  showQuestionList: false,
  isFormDisabled: false
}

export const driversCheckinReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any }
): DriversCheckinState => {
  if (type === UPDATE_SEARCH_TERM) {
    return { ...state, searchTerm: payload }
  }

  if (type === SET_LOADING) {
    return { ...state, loading: payload }
  }

  if (type === SET_PICKED_APPOINTMENT) {
    return { ...state, pickedAppointment: payload }
  }

  if (type === SET_MATCHES) {
    return { ...state, matches: payload }
  }

  if (type === SET_IS_CHECKED_IN) {
    return { ...state, isCheckedIn: payload }
  }

  if (type === SET_SHOW_QUESTION_LIST) {
    return { ...state, showQuestionList: payload }
  }

  if (type === SET_IS_ANSWER_LIST_UPDATED) {
    return { ...state, isAnswerListUpdated: payload }
  }

  return state
}
