import React, { useEffect, useMemo, useState } from 'react'
import { DockCapacity } from 'components/models/DockCapacity'
import { ButtonGroup, SHAPE } from 'baseui/button-group'
import { facilityService } from '../../services/facility.service'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'
import FormControl from 'components/ui/generic/FormControl'

interface EquipmentType {
  record: any
  setRecord: any
  dockCapacitiesAttributes: DockCapacity[]
  label: string
  recordAttributeName: string
  equipmentCapacityShared?: boolean
  openScheduling?: boolean
  disabled?: boolean
}

const EquipmentTypeWithCapacity = ({
  record,
  setRecord,
  dockCapacitiesAttributes,
  label,
  recordAttributeName,
  equipmentCapacityShared,
  openScheduling,
  disabled = false
}: EquipmentType) => {
  const [selected, setSelected] = useState<number>(undefined)
  const [docks, setDocks] = useState([])
  const { t } = useTranslation()

  const availableEqTypes = useMemo(
    () =>
      dockCapacitiesAttributes.filter(
        capacity =>
          capacity.appointmentTypeId ===
            (equipmentCapacityShared ? null : record.appointmentTypeId) && capacity.limit > 0
      ),
    [dockCapacitiesAttributes, equipmentCapacityShared, record.appointmentTypeId]
  )

  useEffect(() => {
    if (record.id) {
      facilityService.dockTypes(record.id, openScheduling).then(([json]) => {
        setDocks(json)
      })
    }
  }, [])

  useEffect(() => {
    if (record.id) {
      setSelected(
        availableEqTypes.findIndex(element => element.equipmentTypeId === record.equipmentTypeId)
      )
    }
  }, [availableEqTypes])

  return (
    <FormControl label={label} disabled={disabled}>
      <ButtonGroup
        shape={SHAPE.pill}
        onClick={(_event, index) => {
          let dockId
          if (record.id) {
            dockId = (docks as any).filter(
              dock =>
                dock.equipmentTypes.filter(
                  et => et.id === availableEqTypes[index]?.equipmentTypeId
                )[0]
            )[0]?.id
          }
          setSelected(index)
          setRecord({
            ...record,
            [recordAttributeName]: availableEqTypes[index]?.equipmentTypeId,
            dockId: dockId || record.dockId
          })
        }}
        overrides={{
          Root: { style: { flexWrap: 'wrap', gap: '10px' } }
        }}
        selected={selected}
        size="compact">
        {availableEqTypes?.map((capacities: DockCapacity, index: number) => (
          <Button key={index}>{t(capacities.equipmentType.name)}</Button>
        ))}
      </ButtonGroup>
    </FormControl>
  )
}

export default EquipmentTypeWithCapacity
