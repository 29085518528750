import React, { useState, useEffect } from 'react'
import authenticatedFetch from 'components/utils/authenticated-fetch'

import DateAndTimeStep from 'components/facilities/steps/date-and-time-step'
import StyledSpinner from 'components/shared/styled-spinner'
import { Card, StyledAction } from 'baseui/card'
import PurchaseOrderIdentifiers from '../../appointments/fields/purchase-order-identifiers'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import Header from 'components/ui/generic/Header'
import FormControl from 'components/ui/generic/FormControl'
import Button from 'components/ui/specific/ErrorButton'
import ArrivalTime from '../../appointments/fields/arrival-time'
import ErrorMessageButton from 'components/shared/error-message-button'

import CancelAppointment from '../../appointments/modals/cancel-appointment'
import moment from 'moment'
import { isEqual } from 'lodash'
import { fancyToast } from 'components/utils'
import { useStyletron } from 'baseui'
import OpenSchedulingScheduleReasonCode from './OpenSchedulingScheduleReasonCode'
import OpenSchedulingScheduleSuccess from './OpenSchedulingScheduleSuccess'
import Recaptcha from 'components/shared/recaptcha'
import ScheduleFormAnswer from '../../appointments/schedule/schedule-form-answer'
import { trim } from 'lodash'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { answerService } from 'components/services/answer.service'
import checkAnswersRequireness from '../../utils/answer-requireness-validation'
import EquipmentTypeWithCapacity from '../../components/EquipmentType/EquipmentTypeWithCapacity'
import useSizing from '../../shared/hooks/use-sizing'
import { CHECKED_OUT } from 'components/utils/appointment-statuses'
import { useTranslation } from 'react-i18next'

const OpenSchedulingScheduleAppointment = ({ match }) => {
  const [css, theme] = useStyletron()
  const [loading, setLoading] = useState<boolean>(false)
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [appointment, setAppointment] = useState(null)
  const [initialPurchaseOrders, setInitialPurchaseOrders] = useState(null)
  const [facility, setFacility] = useState(null)
  const { customBreakPoints } = useSizing()
  const [recaptchaComplete, setRecaptchaComplete] = useState<boolean>(
    process.env.RAILS_ENV === 'test' || process.env.RAILS_ENV === 'development' ? true : false
  )
  const {
    answers,
    temporalFiles,
    documentSelections,
    actions: { setAnswers, updateFileUploadState, resetTemporalFilesState }
  } = useCQStateValue()

  const formErrors = () => {
    const filteredPurchaseOrders = appointment.purchaseOrders.filter(({ _destroy }) => !_destroy)
    const purchaseOrdersChanged = !isEqual(filteredPurchaseOrders, initialPurchaseOrders)
    const errorValidations = [
      {
        label: 'At least one purchase order must be present.',
        status: filteredPurchaseOrders.length > 0
      },
      {
        // TODO(adenta) this should really take into account the `days_in_advanced` field
        label: "Purchase orders can't be changed within 24 hours of appointment.",
        status:
          !purchaseOrdersChanged ||
          moment().add(1, 'day').isBefore(moment(appointment?.arrivalTime))
      },
      {
        label: "Purchase orders can't be updated on LTL appointments",
        status: !purchaseOrdersChanged || !appointment.lessThanTruckload
      },
      {
        label: 'Reason for Reschedule Must be Selected',
        status: !!appointment?.comment
      },
      {
        label: 'Resolve Captcha to proceed',
        status: !!recaptchaComplete
      },
      { label: 'Answers must meet the requirements', status: !answers.some(e => e.error) }
    ]

    checkAnswersRequireness(answers, temporalFiles, documentSelections, errorValidations)

    return errorValidations
  }

  useEffect(() => {
    authenticatedFetch({
      path: `/appointments/${match.params.id}/get_facility_for_open_scheduling.json`
    }).then(([json, status]) => {
      if ([304, 200].includes(status)) {
        setAppointment({ ...json })
        setInitialPurchaseOrders(json.purchaseOrders)
        setAnswers(json.answersAttributes)
      }
    })
  }, [])

  useEffect(() => {
    if (appointment && !facility) {
      authenticatedFetch({
        path: `/facilities/${appointment.facilityId}/open_scheduling_facility.json`
      }).then(([json, status]) => {
        if ([304, 200].includes(status)) {
          setFacility(json[0])
        }
      })
    }
  }, [appointment])

  const rescheduleAppointment = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/appointments/${appointment.id}/open_scheduling.json`,
      method: 'PATCH',
      body: {
        appointment: {
          arrivalTime: appointment.arrivalTime,
          purchaseOrdersAttributes: appointment.purchaseOrders.map(
            (order: { identifier: string; id: string }) => ({
              ...order,
              identifier: trim(order.identifier)
            })
          ),
          quantity: appointment.quantity,
          comment: appointment.comment,
          dockId: appointment.dockId,
          equipmentTypeId: appointment.equipmentTypeId,
          ...(answers && { answersAttributes: answers })
        }
      }
    })

    setLoading(false)

    if (status === 200) {
      setAppointment(json)
      setUpdated(true)
    } else {
      fancyToast(json, status)
    }
  }

  const updateAppointmentType = () => {
    if (documentSelections.length) {
      documentSelections.forEach(({ documentId, questionId }) =>
        answerService.removeFile(documentId, questionId)
      )
    }
    if (temporalFiles.length) {
      return updateFileUploadState(true)
    }
    return rescheduleAppointment()
  }

  const isFormDisabled = () => appointment?.status === CHECKED_OUT

  useEffect(() => {
    if (temporalFiles.length && temporalFiles.every(tf => tf?.uploadState === 'saved')) {
      resetTemporalFilesState()
      rescheduleAppointment()
    }
  }, [answers, temporalFiles])

  const { t } = useTranslation()

  if (!facility || !appointment) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '60vh',
          flexDirection: 'column'
        })}>
        <div className={css({ paddingBottom: theme.sizing.scale200 })}>
          <StyledSpinner />
        </div>
      </div>
    )
  }

  if (updated) {
    return <OpenSchedulingScheduleSuccess />
  }

  console.log('formErrors()', formErrors())

  return (
    <>
      <CancelAppointment
        isOpen={openCancelModal}
        openScheduling={true}
        close={() => {
          setOpenCancelModal(false)
        }}
        {...{ appointment, setAppointment }}
      />
      <Header title={t('Common.Button.UpdateAppointment.Text')} />
      <FlexGrid
        flexGridColumnGap="scale800"
        flexGridRowGap="scale200"
        flexGridColumnCount={[1, 1, isFormDisabled() ? 1 : 2]}
        display="flex">
        <FlexGridItem flex="1.5">
          <Card
            title={`${facility.name} Appointment`}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.white,
                  borderTopWidth: $theme.borders.borderWidth,
                  borderBottomWidth: $theme.borders.borderWidth,
                  borderLeftWidth: $theme.borders.borderWidth,
                  borderRightWidth: $theme.borders.borderWidth,
                  borderTopColor: $theme.colors.inputBorder,
                  borderBottomColor: $theme.colors.inputBorder,
                  borderLeftColor: $theme.colors.inputBorder,
                  borderRightColor: $theme.colors.inputBorder,
                  maxWidth: '800px',
                  margin: '0 auto'
                })
              }
            }}>
            <PurchaseOrderIdentifiers
              facility={facility}
              purchaseOrders={appointment.purchaseOrders}
              setPurchaseOrders={purchaseOrders =>
                setAppointment({ ...appointment, purchaseOrders })
              }
              openScheduling={true}
              disabled={isFormDisabled()}
            />
            <EquipmentTypeWithCapacity
              openScheduling={true}
              record={appointment}
              setRecord={setAppointment}
              dockCapacitiesAttributes={facility?.appointmentPreference?.dockCapacitiesAttributes}
              equipmentCapacityShared={facility?.appointmentPreference?.equipmentCapacityShared}
              label="Equipment Type"
              recordAttributeName="equipmentTypeId"
              disabled={isFormDisabled()}
            />

            <FormControl label="Arrival Time">
              <ArrivalTime
                {...{
                  appointment,
                  facility
                }}
              />
            </FormControl>
            <FormControl label="Reason to reschedule">
              <OpenSchedulingScheduleReasonCode
                appointment={appointment}
                setAppointment={setAppointment}
                disabled={isFormDisabled()}
              />
            </FormControl>
            <ScheduleFormAnswer disabled={isFormDisabled()} />
            <StyledAction>
              <FlexGrid
                flexGridRowGap="scale600"
                flexGridColumnGap="scale600"
                flexGridColumnCount={1}>
                <FlexGridItem
                  display="flex"
                  gridColumnGap="scale600"
                  justifyContent="space-between">
                  <Button onClick={() => setOpenCancelModal(true)} disabled={isFormDisabled()}>
                    {t('Common.Button.CancelAppointment.Text')}
                  </Button>
                  <ErrorMessageButton
                    errors={formErrors()}
                    buttonProps={{
                      onClick: updateAppointmentType,
                      isLoading: loading
                    }}
                    statefulTooltipProps={{ placement: 'left' }}
                    label={t('Common.Button.UpdateAppointment.Text')}
                  />
                </FlexGridItem>
              </FlexGrid>
            </StyledAction>
          </Card>
          <div
            style={{
              marginTop: '10px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Recaptcha
              onChange={() => {
                setRecaptchaComplete(true)
              }}
            />
          </div>
        </FlexGridItem>
        {!isFormDisabled() && (
          <FlexGridItem flex="1">
            <DateAndTimeStep
              {...{ appointment, setAppointment, facility }}
              purchaseOrders={appointment.purchaseOrders}
            />
          </FlexGridItem>
        )}
      </FlexGrid>
    </>
  )
}

export default OpenSchedulingScheduleAppointment
