import React, { useState, useEffect } from 'react'
import { Block } from 'baseui/block'
import { StatefulCalendar } from 'baseui/datepicker'

import { Spinner } from 'baseui/spinner'
import Datepicker from 'components/ui/generic/DatePicker'
import FormControl from 'components/ui/generic/FormControl'
import Button from 'components/ui/generic/Button'
import CenterBlock from 'components/ui/generic/CenterBlock'
import { useTranslation } from 'react-i18next'
import TimeFormatter from '../../utils/time-formatter'
import authenticatedFetch from '../../utils/authenticated-fetch'
import StyledSpinner from '../../shared/styled-spinner'

import useSizing from '../../shared/hooks/use-sizing'
import SelectTime from './SelectTime'
import { facilityService } from '../../services/facility.service'
import { KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'

interface DateAndTimeStepProps {
  facility: any
  appointment: any
  setAppointment: Function
  onTimeSelect?: Function
  purchaseOrders?: { identifier: string }[]
}

const MINUTES_TO_MILISECONDS_FACTOR = 60000

const DateAndTimeStep = (props: DateAndTimeStepProps) => {
  const { facility, appointment, setAppointment, onTimeSelect, purchaseOrders } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [availableTimeslots, setAvailableTimeslots] = useState<any>([])
  const [availableDays, setAvailableDays] = useState<any>([])
  const { customBreakPoints } = useSizing()
  const { t } = useTranslation()

  useEffect(() => {
    if (!facility) {
      return
    }

    facilityService.availableDays(facility.id, purchaseOrders).then(([json]) => {
      const { lessThanTruckloadDays, fullTruckloadDays } = json
      setAvailableDays({
        lessThanTruckloadDays: lessThanTruckloadDays.map(d => new Date(d)),
        fullTruckloadDays: fullTruckloadDays.map(d => new Date(d))
      })
    })
  }, [facility])

  if (availableDays.length === 0) {
    return <StyledSpinner />
  }

  const selectDate = ({ date }) => {
    if (
      (facility.firstComeFirstServeOpenTime &&
        facility.firstComeFirstServeCloseTime &&
        appointment.quantity <= facility.maxLtlQuantity) ||
      appointment.lessThanTruckload
    ) {
      date.setHours(new Date(facility.firstComeFirstServeOpenTime).getHours())
      date.setMinutes(new Date(facility.firstComeFirstServeOpenTime).getMinutes())

      setAppointment({ ...appointment, arrivalTime: date })
      onTimeSelect && onTimeSelect()
      return
    }
    setLoading(true)

    const tzoffset = date.getTimezoneOffset() * MINUTES_TO_MILISECONDS_FACTOR
    const localISOTime = new Date(date - tzoffset).toISOString()

    const dateParam = `date=${localISOTime}`
    const dockTypeParam = appointment.equipmentTypeId
      ? `&equipment_type_id=${appointment.equipmentTypeId}`
      : appointment.dock?.equipmentTypeId
      ? `&equipment_type_id=${appointment.dock?.equipmentTypeId}`
      : ''
    const appointmentTypeParam = appointment.appointmentTypeId
      ? `&appointment_type_id=${appointment.appointmentTypeId}`
      : ''

    const availableTimeSlotParams = `${dateParam}${dockTypeParam}${appointmentTypeParam}`

    authenticatedFetch({
      path: `/facilities/${facility.id}/available_timeslots.json?${availableTimeSlotParams}`
    })
      .then(([json, status]) => {
        setLoading(false)
        if (status === 200) {
          setAvailableTimeslots(json)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const includeDates = () => {
    return appointment.quantity <= facility.maxLtlQuantity
      ? availableDays.lessThanTruckloadDays
      : availableDays.fullTruckloadDays
  }

  return customBreakPoints.sm ? (
    <>
      <Block>
        <FormControl label={t('Scheduler.OpenScheduling.Steps.Fields.Date.Label.Text')}>
          <Datepicker
            formatString="MM/dd/yyyy"
            includeDates={includeDates()}
            onDayClick={selectDate}
          />
        </FormControl>
      </Block>
      {loading && (
        <Block display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Block>
      )}
      {availableTimeslots?.length > 0 && (
        <SelectTime
          availableTimeslots={availableTimeslots}
          setAppointment={setAppointment}
          facility={facility}
          onTimeSelect={onTimeSelect}
        />
      )}
    </>
  ) : (
    <Block
      display="flex"
      width="100%"
      gridGap="32px"
      flexDirection={['column', 'column', 'row']}
      flexWrap>
      <Block flex=".5">
        <StatefulCalendar
          onDayClick={selectDate}
          includeDates={includeDates().length ? includeDates() : null}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                borderTopLeftRadius: $theme.borders.inputBorderRadius,
                borderTopRightRadius: $theme.borders.inputBorderRadius,
                borderBottomLeftRadius: $theme.borders.inputBorderRadius,
                borderBottomRightRadius: $theme.borders.inputBorderRadius,
                overflow: 'hidden',
                ...$theme.borders.border200
              })
            },
            CalendarContainer: {
              style: ({ $theme }) => ({
                boxSizing: 'border-box',
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
              })
            },
            CalendarHeader: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.light,
                paddingTop: $theme.sizing.scale300,
                paddingLeft: $theme.sizing.scale300,
                paddingRight: $theme.sizing.scale300,
                paddingBottom: $theme.sizing.scale300
              })
            },

            MonthHeader: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.light
              })
            },
            MonthYearSelectButton: {
              style: ({ $theme }) => ({
                color: $theme.colors.text,
                fontSize: $theme.typography.LabelSmall.fontSize,
                ':focus': {
                  backgroundColor: $theme.colors.accent,
                  outline: 'none'
                }
              })
            },
            WeekdayHeader: {
              style: ({ $theme }) => ({
                fontSize: $theme.typography.LabelSmall.fontSize,
                color: $theme.colors.text,
                height: $theme.sizing.scale1000,
                width: $theme.sizing.scale1000
              })
            },
            PrevButton: {
              style: ({ $theme }) => {
                return {
                  color: $theme.colors.text,
                  borderRadius: $theme.borders.buttonBorderRadius,
                  ':focus': {
                    backgroundColor: $theme.colors.white
                  }
                }
              }
            },
            NextButton: {
              style: ({ $theme }) => {
                return {
                  color: $theme.colors.text,
                  borderRadius: $theme.borders.buttonBorderRadius,
                  ':focus': {
                    backgroundColor: $theme.colors.white
                  }
                }
              }
            },
            Day: {
              style: ({ $theme, $selected }) => ({
                fontSize: $theme.typography.LabelSmall.fontSize,
                paddingTop: $theme.sizing.scale200,
                paddingBottom: $theme.sizing.scale200,
                paddingRight: $theme.sizing.scale200,
                paddingLeft: $theme.sizing.scale200,
                height: $selected ? $theme.sizing.scale1000 : $theme.sizing.scale1000,
                width: $theme.sizing.scale1000
              })
            }
          }}
        />
      </Block>
      <Block
        width="100%"
        display="flex"
        flexDirection={['row', 'row', 'row']}
        overflow="auto"
        gridRowGap="scale400"
        gridColumnGap="scale400"
        maxHeight="50vh"
        flexWrap
        flex="1">
        {loading ? (
          <CenterBlock>
            <Spinner />
          </CenterBlock>
        ) : (
          availableTimeslots.map(({ timeSlot, available }, index) => {
            return (
              <Button
                key={index}
                kind={appointment?.arrivalTime === timeSlot ? KIND.primary : KIND.secondary}
                onClick={() => {
                  setAppointment({
                    ...appointment,
                    arrivalTime: timeSlot
                  })
                  onTimeSelect && onTimeSelect()
                }}
                disabled={!available}
                size="mini"
                minWidth="120px">
                {new TimeFormatter('shortTime').format(timeSlot, facility.timeZone)}
              </Button>
            )
          })
        )}
      </Block>
    </Block>
  )
}

export default DateAndTimeStep
