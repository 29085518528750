import RecurringAppIcon from 'components/components/RecurringAppIcon'

import { CurrentUserContext } from 'components/homepage/current-user-context'
import React, { useContext } from 'react'
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import {
  Package,
  Drop,
  Van,
  Truck,
  Snowflake,
  LadderSimple,
  BatteryFull,
  CaretLeft
} from '@phosphor-icons/react'

const DOCK_TYPE_ICON = {
  ['van']: Package,
  ['tanker']: Drop,
  ['flatbed']: Truck,
  ['reefer']: Snowflake,
  ['container']: BatteryFull,
  ['rail']: LadderSimple,
  ['Van (Floor Loaded)']: Van,
  ['Van (Pallet Loaded)']: Van,
  ['van_floor']: Van,
  ['van_pallet']: Van
}

const DEFAULT_ICON_COLOR = '#fff'
const DEFAULT_ICON_NAME = 'FaPallet'
const ICON_SIZES = {
  flatbed: {
    height: 10,
    width: 16
  },
  default: {
    height: 20,
    width: 20
  }
}

const EventIcon = ({
  event
}: {
  event: {
    extendedProps: {
      equipmentTypeId?: string
      recurringAppointmentBlueprintId?: string
      daysOfWeek?: boolean
    }
  }
}) => {
  const {
    extendedProps: { equipmentTypeId, recurringAppointmentBlueprintId, daysOfWeek }
  } = event

  const { shipperEquipmentTypes } = useContext(CurrentUserContext)
  const equipmentType = shipperEquipmentTypes?.find(element => element.id === equipmentTypeId)

  const DockTypeIcon = equipmentType?.name && DOCK_TYPE_ICON[equipmentType.name]

  return (
    <>
      {equipmentType?.color ? (
        <CaretLeft
          weight="fill"
          color={equipmentType?.color}
          size={ICON_SIZES.default.width + 8}
          style={{
            position: 'absolute',
            zIndex: '-1',
            transform: 'rotate(45deg) translateX(-20px)'
          }}
        />
      ) : (
        ''
      )}
      {DockTypeIcon ? (
        <DockTypeIcon size={ICON_SIZES.default.width} height={ICON_SIZES.default.height} />
      ) : (
        <IconPickerItem
          icon={(equipmentType?.icon as IconList) || DEFAULT_ICON_NAME}
          size={ICON_SIZES.default.width}
          color={equipmentType?.color || DEFAULT_ICON_COLOR}
        />
      )}
      {(recurringAppointmentBlueprintId || daysOfWeek) && <RecurringAppIcon />}
    </>
  )
}

export default EventIcon
