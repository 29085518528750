import React from 'react'
import { Button, SIZE, SHAPE, KIND } from 'baseui/button'

const VButton = ({ size, shape, kind, IconStart, IconEnd, children, overrides, ...props }) => {
  return (
    <Button
      shape={shape || SHAPE.pill}
      kind={kind || KIND.secondary}
      size={size || SIZE.compact}
      startEnhancer={IconStart || undefined}
      endEnhancer={IconEnd || undefined}
      overrides={{
        BaseButton: {
          style: ({ $theme, $disabled }) => ({
            borderColor:
              kind === KIND.primary || kind === KIND.tertiary || $disabled
                ? 'transparent'
                : $theme.borders.borderColor,
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            borderTopStyle: $theme.borders.borderStyle,
            borderBottomStyle: $theme.borders.borderStyle,
            borderLeftStyle: $theme.borders.borderStyle,
            borderRightStyle: $theme.borders.borderStyle,
            backgroundColor:
              kind === KIND.primary
                ? $theme.colors.accent
                : $disabled
                ? $theme.colors.inputFillDisabled
                : 'transparent',
            paddingTop: size === SIZE.mini ? $theme.sizing.scale200 : $theme.sizing.scale400,
            paddingBottom: size === SIZE.mini ? $theme.sizing.scale200 : $theme.sizing.scale400,
            paddingRight:
              shape === SHAPE.circle || IconEnd || size === SIZE.mini
                ? $theme.sizing.scale400
                : $theme.sizing.scale500,
            paddingLeft:
              shape === SHAPE.circle || IconStart || size === SIZE.mini
                ? $theme.sizing.scale400
                : $theme.sizing.scale500,
            opacity: $disabled ? 90 : 100,
            minWidth: props.minWidth || 'auto'
          })
        },
        ...overrides
      }}
      {...props}>
      {children}
    </Button>
  )
}
export default VButton
