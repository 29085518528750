import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import FormControl from 'components/ui/generic/FormControl'
import { TrixEditor } from 'react-trix'
import { useTranslation } from 'react-i18next'
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography'
import { useStyletron } from 'baseui'
import NumberButton from 'components/components/NumberButton/NumberButton'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { DockCapacity } from 'components/models/DockCapacity'
import Input from 'components/ui/generic/Input'
import Checkbox from 'components/ui/generic/Checkbox'
import Button from 'components/ui/specific/PrimaryButton'
import { Block } from 'baseui/block'
import authenticatedFetch from '../../utils/authenticated-fetch'
import StyledSpinner from '../../shared/styled-spinner'
import { fancyToast } from '../../utils'
import { FirstComeFirstServeFields } from './FirstComeFirstServeFields'
import { DockCapacities } from './DockCapacities'
import { AppointmentDurationSection } from './AppointmentDurationSection/AppointmentDurationSection'
import OrderValidationSection from './OrderValidationSection'
import FieldsFromOrderSelect from './FieldsFromOrderSelect'
import { FormDivider } from './AppointmentPreferenceForm.styled'
import getOrderFieldMap from './getOrderFieldMap'
import ErrorMessageButton from 'components/shared/error-message-button'

export const AppointmentPreferenceForm = ({ facility }) => {
  const { appointmentPreferenceId, appointmentTypes } = facility
  const [appointmentPreference, setAppointmentPreference] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [shouldUpdateAppointmentDurations, setShouldUpdateAppointmentDurations] =
    useState<boolean>(false)
  const { currentUser } = useContext(CurrentUserContext)
  const isAdmin = useMemo(() => !!currentUser?.admin, [currentUser?.admin])
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  const secondCapacityLayerTriggerOptions = useMemo(() => {
    const options = []

    if (appointmentPreference) {
      const { minDaysInAdvance, maxDaysInAdvance } = appointmentPreference

      for (let i = minDaysInAdvance; i < maxDaysInAdvance; i++) {
        options.push({
          id: i,
          label: t(
            'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Day.Text',
            { count: i }
          )
        })
      }
    }

    return options
  }, [appointmentPreference])

  useEffect(() => {
    const orderFieldMap = getOrderFieldMap()
    if (appointmentPreferenceId) {
      authenticatedFetch({
        path: `/appointment_preferences/${appointmentPreferenceId}.json`
      }).then(([json, _status]) => {
        const fields = JSON.parse(json.invitationsSetup.fields ?? '[]').map(field => {
          return {
            id: orderFieldMap[field],
            field
          }
        })

        setAppointmentPreference({
          ...json,
          schedulers: json.schedulers?.map(scheduler => scheduler.id),
          invitationsSetup: { ...json.invitationsSetup, fields }
        })
      })
    }
  }, [facility])

  const getEquipmentTypes = useCallback(
    () =>
      appointmentPreference?.dockCapacitiesAttributes
        ?.filter((dockCapacity: DockCapacity) => dockCapacity.appointmentTypeId === null)
        ?.map((dockCapacity: DockCapacity) => dockCapacity.equipmentType),
    [appointmentPreference?.dockCapacitiesAttributes]
  )

  const updateAppointmentPreference = async () => {
    const { minDaysInAdvance, maxDaysInAdvance, secondCapacityLayerTrigger } = appointmentPreference

    if (
      appointmentPreference.secondCapacityLayerActive &&
      (minDaysInAdvance > secondCapacityLayerTrigger ||
        maxDaysInAdvance < secondCapacityLayerTrigger)
    ) {
      fancyToast({ error: t('AppointmentPreferences.Form.Error.UpdateTriggerValue') }, 500)
      return
    }

    setLoading(true)
    const firstComeFirstServeOpenTime =
      appointmentPreference.firstComeFirstServeOpenTime &&
      appointmentPreference.firstComeFirstServeOpenTime[0].id
    const firstComeFirstServeCloseTime =
      appointmentPreference.firstComeFirstServeCloseTime &&
      appointmentPreference.firstComeFirstServeCloseTime[0].id

    const formattedFields = appointmentPreference.invitationsSetup.fields.map(({ field }) => field)

    const [json, status] = await authenticatedFetch({
      path: `/appointment_preferences/${appointmentPreferenceId}.json`,
      method: 'PATCH',
      body: {
        appointmentPreference: {
          ...appointmentPreference,
          firstComeFirstServeOpenTime,
          firstComeFirstServeCloseTime,
          shouldUpdateAppointmentDurations,
          invitationsSetup: {
            ...appointmentPreference.invitationsSetup,
            fields: JSON.stringify(formattedFields)
          }
        }
      }
    })

    if (status === 200) {
      fancyToast(
        {
          info: t('Common.Info.Interpolated.Text', {
            model: t('Common.ModelType.AppointmentPreferences.Text'),
            action: t('Common.Actions.Updated.Text')
          })
        },
        status
      )

      const orderFieldMap = getOrderFieldMap()

      const fields = JSON.parse(json.invitationsSetup?.fields).map(field => {
        return {
          id: orderFieldMap[field],
          field
        }
      })

      setAppointmentPreference({
        ...json,
        schedulers: json.schedulers?.map(scheduler => scheduler.id),
        appointmentDurationInMinutes: appointmentPreference.appointmentDurationInMinutes,
        invitationsSetup: { ...json.invitationsSetup, fields }
      })
    } else {
      fancyToast(json, status)
    }
    setLoading(false)
    setShouldUpdateAppointmentDurations(false)
  }

  const handleChangeInvitationCheckbox = (e, key) => {
    setAppointmentPreference({
      ...appointmentPreference,
      invitationsSetup: {
        ...appointmentPreference.invitationsSetup,
        [key]: e.currentTarget.checked
      }
    })
  }

  const handleChangeInvitationText = (text, key) => {
    setAppointmentPreference({
      ...appointmentPreference,
      invitationsSetup: {
        ...appointmentPreference.invitationsSetup,
        [key]: text
      }
    })
  }

  const handleChangeInvitationFields = value =>
    setAppointmentPreference({
      ...appointmentPreference,
      invitationsSetup: {
        ...appointmentPreference.invitationsSetup,
        fields: value
      }
    })

  const validateAppointmentDuration = () => {
    const activeEquipmentsIds = getEquipmentTypes()?.map(equipment => equipment.id)
    return (
      appointmentPreference?.appointmentDurationsAttributes?.filter(
        equipment =>
          equipment.duration < appointmentPreference.appointmentDurationInMinutes &&
          activeEquipmentsIds.includes(equipment.equipmentType?.id)
      ).length < 1
    )
  }

  const formErrors = (): { label: string; status: boolean }[] => {
    return [
      {
        label: t(
          'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Validations.AppointmentDuration.Text'
        ),
        status: !!validateAppointmentDuration()
      }
    ]
  }

  if (!appointmentPreference) {
    return <StyledSpinner />
  }

  const { minDaysInAdvance, maxDaysInAdvance } = appointmentPreference

  return (
    <Block maxWidth={'720px'}>
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Header.Text')}
      </HeadingXSmall>
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale200">
        <FlexGridItem>
          <FormControl
            label={t('AppointmentPreferences.Form.Fields.MinimumRequiredDaysNotice.Label.Text')}>
            <NumberButton
              disabled={!isAdmin}
              count={minDaysInAdvance}
              limit={maxDaysInAdvance}
              setCount={count => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  minDaysInAdvance: count
                })
              }}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            label={t('AppointmentPreferences.Form.Fields.MaximumRequiredDaysNotice.Label.Text')}>
            <NumberButton
              disabled={!isAdmin}
              count={maxDaysInAdvance}
              inferiorLimit={minDaysInAdvance + 1}
              setCount={count => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  maxDaysInAdvance: count
                })
              }}
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid>
        <FlexGridItem>
          <FormControl
            label={t('AppointmentPreferences.Form.Fields.AutoCancelMissedAppointments.Label.Text')}>
            <Checkbox
              checked={appointmentPreference.autoCancelMissedCheckins}
              disabled={!isAdmin}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  autoCancelMissedCheckins: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.AutoCancelMissedAppointments.Caption.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>
      <FormDivider />
      <FlexGrid>
        <AppointmentDurationSection
          facility={facility}
          appointmentPreference={appointmentPreference}
          setAppointmentPreference={setAppointmentPreference}
          disabled={!isAdmin}
          equipmentTypes={getEquipmentTypes()}
          setShouldUpdateAppointmentDurations={setShouldUpdateAppointmentDurations}
        />
      </FlexGrid>
      <FormDivider />
      <FlexGrid>
        <DockCapacities
          appointmentPreference={appointmentPreference}
          setAppointmentPreference={setAppointmentPreference}
          disabled={!isAdmin}
          equipmentTypes={getEquipmentTypes()}
          appointmentTypes={appointmentTypes}
          secondCapacityLayerTriggerOptions={secondCapacityLayerTriggerOptions}
        />
      </FlexGrid>
      <FormDivider />
      <FlexGrid>
        <OrderValidationSection
          appointmentPreference={appointmentPreference}
          setAppointmentPreference={setAppointmentPreference}
          disabled={!isAdmin}
        />
      </FlexGrid>

      {isAdmin && (
        <FormControl
          label={t('AppointmentPreferences.Form.Fields.DriverWelcomeMessage.Label.Text')}
          caption={t('AppointmentPreferences.Form.Fields.DriverWelcomeMessage.Caption.Text')}>
          <TrixEditor
            value={appointmentPreference.driverWelcomeMessage}
            onChange={text => {
              setAppointmentPreference({
                ...appointmentPreference,
                driverWelcomeMessage: text
              })
            }}
            onEditorReady={() => {}}
            mergeTags={[]}
          />
        </FormControl>
      )}
      <FormDivider />
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.EmailInvitation.Label.Text')}
      </HeadingXSmall>
      <ParagraphSmall marginTop="scale200" marginBottom="scale200">
        {t('AppointmentPreferences.Form.Fields.EmailInvitation.Subtext1')}
      </ParagraphSmall>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800" width="240px">
        <FlexGridItem>
          <Checkbox
            checked={appointmentPreference.invitationsSetup.carrier}
            onChange={e => handleChangeInvitationCheckbox(e, 'carrier')}
            label={t('AppointmentPreferences.Form.Fields.EmailInvitation.Carrier')}
          />
        </FlexGridItem>
        <FlexGridItem>
          <Checkbox
            checked={appointmentPreference.invitationsSetup.customer}
            onChange={e => handleChangeInvitationCheckbox(e, 'customer')}
            label={t('AppointmentPreferences.Form.Fields.EmailInvitation.Customer')}
          />
        </FlexGridItem>
      </FlexGrid>
      <ParagraphSmall marginTop="scale800" marginBottom="scale400">
        {t('AppointmentPreferences.Form.Fields.EmailInvitation.Subtext2')}
      </ParagraphSmall>
      <FormControl label={t('AppointmentPreferences.Form.Fields.EmailInvitation.Intro')}>
        <TrixEditor
          value={appointmentPreference.invitationsSetup.intro}
          onChange={text => handleChangeInvitationText(text, 'intro')}
          onEditorReady={() => {}}
          mergeTags={[]}
        />
      </FormControl>
      <FormControl label={t('AppointmentPreferences.Form.Fields.EmailInvitation.Close')}>
        <TrixEditor
          value={appointmentPreference.invitationsSetup.close}
          onChange={text => handleChangeInvitationText(text, 'close')}
          onEditorReady={() => {}}
          mergeTags={[]}
        />
      </FormControl>
      <FieldsFromOrderSelect
        value={appointmentPreference.invitationsSetup?.fields ?? []}
        setValue={handleChangeInvitationFields}
        orderPreference={appointmentPreference.orderPreference}
      />
      <FormDivider />
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.LabelsAndCaptions.Header.Text')}
      </HeadingXSmall>
      <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale600" marginBottom="20px">
        <FlexGridItem>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale600" width="500px">
            <FlexGridItem>
              <FormControl
                label={t(
                  'AppointmentPreferences.Form.Fields.LabelsAndCaptions.Fields.PurchaseOrderIdentifiersLabel.Label.Text'
                )}>
                <Input
                  autoComplete="off"
                  name="purchaseOrderIdentifiers"
                  value={appointmentPreference?.purchaseOrderLabels?.purchaseOrderIdentifiers}
                  onChange={evt => {
                    setAppointmentPreference({
                      ...appointmentPreference,
                      purchaseOrderLabels: {
                        ...appointmentPreference.purchaseOrderLabels,
                        purchaseOrderIdentifiers: evt.currentTarget.value
                      }
                    })
                  }}
                  maxWidth="230px"
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl
                label={t(
                  'AppointmentPreferences.Form.Fields.LabelsAndCaptions.Fields.PurchaseOrderIdentifiersCaption.Label.Text'
                )}>
                <Input
                  autoComplete="off"
                  name="schedulerLabel"
                  value={
                    appointmentPreference?.purchaseOrderLabels?.purchaseOrderIdentifiersCaption
                  }
                  onChange={evt => {
                    setAppointmentPreference({
                      ...appointmentPreference,
                      purchaseOrderLabels: {
                        ...appointmentPreference.purchaseOrderLabels,
                        purchaseOrderIdentifiersCaption: evt.currentTarget.value
                      }
                    })
                  }}
                  maxWidth="230px"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              checked={appointmentPreference.showAppointmentIdOnCalendar}
              onChange={e => {
                setAppointmentPreference({
                  ...appointmentPreference,
                  showAppointmentIdOnCalendar: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.LabelsAndCaptions.Fields.ShowAppointmentIdOnCalendar.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>

      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.FacilitySchedulingModules.Header.Text')}
      </HeadingXSmall>
      <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale600" marginBottom="20px">
        <FlexGridItem>
          <FormControl>
            <Checkbox
              checked={true}
              disabled
              label={t(
                'AppointmentPreferences.Form.Fields.FacilitySchedulingModules.Fields.AppointmentScheduling.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              checked={appointmentPreference.enableDriversSelfCheckin}
              disabled
              label={t(
                'AppointmentPreferences.Form.Fields.FacilitySchedulingModules.Fields.DriverSelfCheckIn.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Checkbox
              checked={appointmentPreference.enableDockAssignment}
              disabled
              label={t(
                'AppointmentPreferences.Form.Fields.FacilitySchedulingModules.Fields.DockManagement.Label.Text'
              )}
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>

      {isAdmin && (
        <Block display="flex" justifyContent="flex-end">
          <ErrorMessageButton
            disabled={!isAdmin}
            label={t('AppointmentPreferences.Form.UpdateButton.Text')}
            errors={formErrors()}
            buttonProps={{
              isLoading: loading,
              onClick: updateAppointmentPreference
            }}
          />
        </Block>
      )}
    </Block>
  )
}
