import { Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import React, { ReactElement } from 'react'
import ScheduleFormAnswerInput from './schedule-form-answer-input'
import { sortBy } from 'lodash'

export interface AnswersAttributes {
  id: string
  prompt: string
  response: string
  questionId: string
  readOnly: boolean
  disabled: boolean
  question: Question
  attachedDocuments?: Array<any>
}

const ScheduleFormAnswer = ({ disabled = false }: { disabled?: boolean }): ReactElement => {
  const {
    answers,
    actions: { updateAnswer }
  } = useCQStateValue()

  const enabledAnswers = answers
    ?.filter(answer => !answer.question.disabled)
    .sort((a, b) => a.question.position - b.question.position)

  if (!answers || answers?.length === 0) {
    return <></>
  }

  return (
    <>
      {enabledAnswers.map((answer: AnswersAttributes) => (
        <ScheduleFormAnswerInput
          key={answer?.questionId}
          disabled={disabled}
          answer={answer}
          setAnswer={e => {
            updateAnswer(e)
          }}
        />
      ))}
    </>
  )
}

export default ScheduleFormAnswer
