import { useStyletron } from 'baseui'
import { HeadingSmall } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DriversNavbar = (): JSX.Element => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <nav
      className={css({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        alignItems: 'center',
        height: '60px',
        backgroundColor: `${theme.colors.primary}`,
        position: 'fixed',
        top: 0,
        left: 0
      })}>
      <img
        alt="velostics-logo"
        src="https://storage.googleapis.com/terusama-beta/velostics-logo.svg"
        style={{ border: '1px solid white', borderRadius: '50%', height: '60%' }}
      />
      <HeadingSmall margin={0} color="none" className={css({ color: `${theme.colors.white}` })}>
        {t('DriversCheckin.Header.Title')}
      </HeadingSmall>
    </nav>
  )
}

export default DriversNavbar
