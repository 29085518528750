import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Homepage from '../homepage/Homepage'
import AppointmentRoutes from '../appointments/appointment-routes'
import CheckinRoutes from '../checkins/checkin-routes'
import UserRoutes from '../users/user-routes'
import UserOnboarding from '../users/user-onboarding'
import ShipperRoutes from '../shipper/shipper-routes'
import DockBlockRoutes from '../dock-blocks/dock-block-routes'
import InsightsRoutes from '../insights/insights-routes'
import SchedulerDashboard from '../old_scheduler/dashboard'
import PublicCalendar from '../homepage/public-calendar'
import OldSchedulerRoutes from 'components/old_scheduler/scheduler-routes'
import SchedulerRoutes from 'components/schedulers/scheduler-routes'
import { FacilityRoutes } from 'components/routes/FacilityRoutes'
import { DocksRoutes } from 'components/routes/DocksRoutes'
import { SettingsRoutes } from '../routes/SettingsRoutes'
import { MetabaseAnalytics } from 'components/pages/MetabaseAnalytics'
import { PrivateRoute } from 'components/components/PrivateRoute'
import { SHIPPER } from 'components/constants/user-types'
import OrdersList from '../pages/OrderList'
import DockAssignment from '../pages/DockAssignment'

const SlotRoutes = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        roles={[SHIPPER]}
        path={[`${match.url}analytics`, `${match.url}analytics/:id`]}
        component={MetabaseAnalytics}
      />
      <Route path={`${match.url}appointments`} component={AppointmentRoutes} />
      <Route path={`${match.url}dock_assignments`} component={DockAssignment} />
      <Route path={`${match.url}calendar`} component={PublicCalendar} />
      <Route path={`${match.url}checkins`} component={CheckinRoutes} />
      <Route path={`${match.url}docks`} component={DocksRoutes} />
      <Route path={`${match.url}dock_blocks`} component={DockBlockRoutes} />
      <Route path={`${match.url}external_appointments`} component={SchedulerDashboard} />
      <Route path={`${match.url}facilities`} component={FacilityRoutes} />
      <Route path={`${match.url}insights`} component={InsightsRoutes} />
      <Route path={`${match.url}old_schedulers`} component={OldSchedulerRoutes} />
      <Route path={`${match.url}onboarding`} component={UserOnboarding} />
      <Route exact path={`${match.url}orders`} component={OrdersList} />
      <Route path={`${match.url}shippers`} component={ShipperRoutes} />
      <Route path={`${match.url}schedulers`} component={SchedulerRoutes} />
      <Route path={`${match.url}settings`} component={SettingsRoutes} />
      <Route path={`${match.url}users`} component={() => <Redirect to="/" />} />
      <Route path={`${match.url}user`} component={UserRoutes} />
      <Route path={`${match.url}consignee`} component={Homepage} />
      <Route path={`${match.url}supplier`} component={Homepage} />
      <Route path={`${match.url}shipper`} component={Homepage} />
      <Route exact path={match.url} component={Homepage} />
      <Route component={() => <Redirect to="/" />} />
    </Switch>
  </>
)

export default SlotRoutes
