import React from 'react'
import FullCalendar, { CalendarOptions } from '@fullcalendar/react'
import { calendarTranslations } from 'translations/calendar-languages'
import i18n from 'translations/i18n'
interface PremiumCalendarProps {
  innerRef?: any
}

const PremiumCalendar = ({ innerRef, ...props }: CalendarOptions & PremiumCalendarProps) => {
  return (
    <FullCalendar
      locale={calendarTranslations[i18n.language]}
      schedulerLicenseKey={process.env.FULL_CALENDAR_LICENSE_KEY}
      ref={innerRef}
      {...props}
    />
  )
}

export default PremiumCalendar
