import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from 'baseui/tag'
import { camelCase, upperFirst } from 'lodash'

const VTag = ({ children, onActionClick, closeable }) => {
  const { t } = useTranslation()
  return (
    <Tag
      overrides={{
        Text: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            maxWidth: 'none'
          })
        },
        Root: {
          style: ({ $theme }) => ({
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            marginLeft: 0,
            marginTop: 0,
            backgroundColor: $theme.colors.gray
          })
        }
      }}
      closeable={closeable}
      onActionClick={onActionClick}>
      {upperFirst(children)}
    </Tag>
  )
}

export default VTag
