import styled from 'styled-components'

export const AnimatedCheck = styled.img`
  width: 50%;
  border-radius: 50%;

  @media (orientation: landscape) {
    width: auto;
    height: 25vh;
    margin-top: -30px;
  }
`

export const ResultsContainer = styled.div`
  margin-top: 16px;
  overflow-y: scroll;
  height: calc(100vh - 325px);

  @media (orientation: landscape) {
    height: 80vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
