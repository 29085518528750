import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connectRefinementList } from 'react-instantsearch-dom'
import Select from 'components/ui/generic/Select'
import { useDebounce } from 'react-use'
import { facilityService, searchService } from '../../services'
import { sortBy } from 'lodash'
import {
  DEFAULT_UNASSIGNED_DOCK_OPTION,
  useDockAssigmentContext
} from '../../contexts/dock-assigment.context'

const JBUILDER_TEMPLATE_PREFIX = 'hits'

const NonConnectedDockAssignmentFacilitySelect = ({ refine }) => {
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState([])
  const [facility, setFacility] = useState([])

  const {
    selectedFacility,
    actions: { setSelectedFacility, setDockOptions, setSelectedDocks }
  } = useDockAssigmentContext()

  useEffect(() => {
    if (selectedFacility) {
      refine(selectedFacility.id)
      setFacility(selectedFacility)
      setSelectedDocks([DEFAULT_UNASSIGNED_DOCK_OPTION])
      const { docks } = selectedFacility
      const activeDocks = docks.filter(dock => dock.enabled)
      setDockOptions(
        activeDocks?.length > 0
          ? [
              DEFAULT_UNASSIGNED_DOCK_OPTION,
              ...activeDocks?.map(dock => ({
                id: dock.id,
                label: dock.name,
                commodityTypes: dock.commodityTypes,
                equipmentTypeIds: dock.equipmentTypes.map(et => et.id),
                appointmentTypeIds: dock.appointmentTypeIds
              }))
            ]
          : []
      )
    }
  }, [selectedFacility])

  useDebounce(
    async () => {
      const { hits } = await facilityService.findFacilitiesWithoutPagination({
        search,
        filters: [
          {
            appointment_preferences: {
              enable_dock_assignment: true
            }
          }
        ],
        template: JBUILDER_TEMPLATE_PREFIX
      })
      setOptions(sortBy(hits, 'createdAt'))
    },
    200,
    [search]
  )

  const onChange = (
    value: {
      id: string
      label: string
    }[]
  ) => {
    facilityService.getFacility(value[0].id).then(result => {
      if (result) {
        refine(value[0].id)
        setSearch('')
        setSelectedFacility(result)
      }
    })
  }

  return (
    <Select
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves
      value={facility}
      onChange={({ value }) => {
        onChange(value)
      }}
      placeholder=""
      options={options}
      labelKey="name"
      onInputChange={e => {
        setSearch(e.currentTarget.value)
      }}
      minWidth="135px"
    />
  )
}

const ConnectedDockAssignmentFacilitySelect = connectRefinementList(
  NonConnectedDockAssignmentFacilitySelect
)

const DockAssignmentFacilitySelect = () => {
  return (
    <ConnectedDockAssignmentFacilitySelect
      attribute="facility_id"
      defaultRefinement={['facility_id']}
    />
  )
}

export default DockAssignmentFacilitySelect
