import React from 'react'
import { Route, Switch } from 'react-router-dom'
import OpenSchedulingUpdateAppointment from 'components/pages/OpenSchedulingUpdateAppointment'
import OpenSchedulingCreateAppointment from 'components/pages/OpenSchedulingCreateAppointment'
import OpenSchedulingScheduleAppointment from 'components/pages/OpenSchedulingScheduleAppointment'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import SidebarNavbarTemplate from 'components/templates/SidebarNavbarTemplate'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import Fallback from 'components/shared/Fallback'

export const OpenSchedulingRoutes = ({ match }) => {
  return (
    <CustomQuestionsProvider>
      <Switch>
        <SidebarNavbarTemplate>
          <ErrorBoundary fallback={<Fallback />}>
            <Route exact path={`${match.url}/update`} component={OpenSchedulingUpdateAppointment} />
            <Route
              exact
              path={`${match.url}/:id/schedule`}
              component={OpenSchedulingScheduleAppointment}
            />
            <Route exact path={match.url} component={OpenSchedulingCreateAppointment} />
          </ErrorBoundary>
        </SidebarNavbarTemplate>
      </Switch>
    </CustomQuestionsProvider>
  )
}
