import { OnChangeParams } from 'baseui/select'
import { LabelXSmall } from 'baseui/typography'
import Select from 'components/ui/generic/Select'
import React, { ReactElement, useState } from 'react'
import { capitalize } from 'lodash'
import { QuestionPermission } from 'components/models/QuestionPermission'
import { Question } from '../../../appointments/types'
import { FieldContainer, PermissionRowContainer } from './QuestionsPermissions.styled'
import { INTERNAL, Role } from '../../../models/Role'
import i18n from 'translations/i18n'

const VIEW_ONLY = 'View Only'
const NONE = 'None'
const VIEW_EDIT = 'View+Edit'

const accessControlOptions = [
  { label: VIEW_EDIT, value: VIEW_EDIT, canEdit: true, canView: true },
  { label: VIEW_ONLY, value: VIEW_ONLY, canEdit: false, canView: true },
  { label: NONE, value: NONE, canEdit: false, canView: false }
]

const getRequiredOptions = () => [
  {
    label: i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.Row.Yes'),
    required: true
  },
  {
    label: i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.Row.No'),
    required: false
  }
]

const getShowOnCreateOptions = () => [
  {
    label: i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.Row.Yes'),
    showOnCreate: true
  },
  {
    label: i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.Row.No'),
    showOnCreate: false
  }
]

interface AccessControlOptions {
  label: string
  canEdit: boolean
  canView: boolean
}

interface RequiredOptions {
  label: string
  required: boolean
}

interface ShowOnCreateOptions {
  label: string
  showOnCreate: boolean
}
interface PermissionRowProps {
  questions: Question[]
  setQuestions: (question: Question[]) => void
  index: number
  permission: QuestionPermission
  userRoleId?: string
  role: Role
}

const getDefaultValueForAccessControl = (permission: QuestionPermission) => {
  if (permission?.canEdit) {
    return [{ label: VIEW_EDIT, value: VIEW_EDIT, canEdit: true, canView: true }]
  }

  if (permission?.canView) {
    return [{ label: VIEW_ONLY, value: VIEW_ONLY, canEdit: false, canView: true }]
  }

  return [{ label: NONE, value: NONE, canEdit: false, canView: false }]
}

const getDefaultValueForRequired = (permission: QuestionPermission) => {
  if (permission?.required) {
    return [{ label: 'Yes', required: true }]
  }
  return [{ label: 'No', required: false }]
}

const getDefaultValueForShowOnCreate = (permission: QuestionPermission) => {
  if (permission?.showOnCreate) {
    return [{ label: 'Yes', showOnCreate: true }]
  }

  return [{ label: 'No', showOnCreate: false }]
}

const PermissionRow = ({
  questions,
  setQuestions,
  index,
  permission,
  userRoleId,
  role
}: PermissionRowProps): ReactElement => {
  const [accessControl, setAccessControl] = useState<AccessControlOptions[]>(
    getDefaultValueForAccessControl(permission)
  )

  const [required, setRequired] = useState<RequiredOptions[]>(
    getDefaultValueForRequired(permission)
  )

  const [showOnCreate, setShowOnCreate] = useState<ShowOnCreateOptions[]>(
    getDefaultValueForShowOnCreate(permission)
  )

  const [disableRequiredField, setDisableRequiredField] = useState<boolean>(!permission?.canEdit)

  const [disableShowOnCreateField, setDisableShowOnCreateField] = useState<boolean>(
    permission?.required || !(permission?.canEdit && permission?.canView)
  )

  const getQuestionAttributesWithoutCurrentOne = newQuestions =>
    newQuestions[index].questionPermissionsAttributes?.filter(p => p.userRoleId !== userRoleId) ||
    []

  const updateAccessControlSelect = ({ option }: OnChangeParams) => {
    const { label, canEdit, canView } = option

    setAccessControl(accessControlOptions.filter((op: AccessControlOptions) => op.label === label))

    const newQuestions = [...questions]

    newQuestions[index].questionPermissionsAttributes = [
      ...getQuestionAttributesWithoutCurrentOne(newQuestions),
      {
        ...(permission || {}),
        required: label === VIEW_ONLY || label === NONE ? false : required[0].required,
        canEdit,
        canView,
        showOnCreate: label === NONE ? false : permission.showOnCreate,
        questionId: newQuestions[index].id,
        isNew: !newQuestions[index].id,
        userRoleId
      }
    ]
    setQuestions(newQuestions)

    if (label === VIEW_ONLY || label === NONE) {
      setRequired([{ label: 'No', required: false }])
      setDisableRequiredField(true)
    } else {
      setDisableRequiredField(false)
    }

    if (label === NONE) {
      setShowOnCreate([{ label: 'No', showOnCreate: false }])
      setDisableShowOnCreateField(true)
    } else {
      setDisableShowOnCreateField(false)
    }
  }

  const updateRequiredSelect = ({ option }: OnChangeParams) => {
    const { label, required } = option

    setRequired(requiredOptions.filter((op: RequiredOptions) => op.label === label))

    const newQuestions = [...questions]

    newQuestions[index].questionPermissionsAttributes = [
      ...getQuestionAttributesWithoutCurrentOne(newQuestions),
      {
        ...(permission || {}),
        canEdit: accessControl[0]?.canEdit,
        canView: accessControl[0]?.canView,
        showOnCreate: required || permission.showOnCreate,
        required,
        questionId: newQuestions[index].id,
        userRoleId
      }
    ]
    setQuestions(newQuestions)

    if (required) {
      setShowOnCreate([{ label: 'Yes', showOnCreate: true }])
      setDisableShowOnCreateField(true)
    } else {
      setDisableShowOnCreateField(false)
    }
  }

  const updateShowOnCreate = ({ option }: OnChangeParams) => {
    const { label, showOnCreate } = option
    setShowOnCreate(showOnCreateOptions.filter((op: ShowOnCreateOptions) => op.label === label))

    const newQuestions = [...questions]

    newQuestions[index].questionPermissionsAttributes = [
      ...getQuestionAttributesWithoutCurrentOne(newQuestions),
      {
        ...(permission || {}),
        canEdit: accessControl[0]?.canEdit,
        canView: accessControl[0]?.canView,
        showOnCreate,
        required: required[0].required,
        questionId: newQuestions[index].id,
        userRoleId
      }
    ]
    setQuestions(newQuestions)
  }

  const requiredOptions = getRequiredOptions()
  const showOnCreateOptions = getShowOnCreateOptions()

  return (
    <PermissionRowContainer data-testid="permision-row">
      <FieldContainer justifyContent="start">
        <LabelXSmall>{capitalize(role?.name)}</LabelXSmall>
      </FieldContainer>
      <FieldContainer justifyContent="center">
        <Select
          placeholder=""
          openOnClick
          valueKey="value"
          clearable={false}
          options={accessControlOptions}
          value={accessControl}
          onChange={updateAccessControlSelect}
          disabled={role.audience === INTERNAL && !role.shipperId}
          overrides={{
            Root: { style: { width: '100px', fontSize: '0.8rem' } },
            IconsContainer: {
              style: {
                width: '20px',
                padding: 0
              }
            }
          }}
        />
      </FieldContainer>
      <FieldContainer justifyContent="center">
        <Select
          placeholder=""
          openOnClick
          overrides={{
            Root: { style: { width: '100px', fontSize: '0.8rem' } },
            IconsContainer: {
              style: {
                width: '20px',
                padding: 0
              }
            }
          }}
          clearable={false}
          valueKey="required"
          options={requiredOptions}
          value={required}
          disabled={disableRequiredField || (role.audience === INTERNAL && !role.shipperId)}
          onChange={updateRequiredSelect}
        />
      </FieldContainer>
      <FieldContainer justifyContent="center">
        <Select
          placeholder=""
          openOnClick
          overrides={{
            Root: { style: { width: '100px', fontSize: '0.8rem' } },
            IconsContainer: {
              style: {
                width: '20px',
                padding: 0
              }
            }
          }}
          clearable={false}
          valueKey="showOnCreate"
          options={showOnCreateOptions}
          value={showOnCreate}
          disabled={disableShowOnCreateField}
          onChange={updateShowOnCreate}
        />
      </FieldContainer>
    </PermissionRowContainer>
  )
}

export default PermissionRow
