import React from 'react'
import { Route } from 'react-router-dom'
import FacilityBook from '../facilities//facility-book'
import FacilityCamera from '../facilities//facility-camera'
import FacilitySchedule from '../facilities/weekly-schedule'
import FacilityRepeatingAppointments from '../facilities/repeating-appointments'
import { FacilitySearch } from 'components/facilities/FacilitySearch'
import { FacilityForm } from 'components/facilities/FacilityForm'
import { FacilityDocks } from 'components/facilities/FacilityDocks'

export const FacilityRoutes = ({ match }) => (
  <>
    <Route exact path={match.url} component={FacilitySearch} />
    <Route exact path={`${match.url}/:handle`} component={FacilityForm} />
    <Route exact path={`${match.url}/:handle/docks`} component={FacilityDocks} />
    <Route exact path={`${match.url}/:handle/book`} component={FacilityBook} />
    <Route path={`${match.url}/:handle/camera`} component={FacilityCamera} />
    <Route path={`${match.url}/:handle/schedule`} component={FacilitySchedule} />
    <Route
      path={`${match.url}/:handle/repeating_appointments`}
      component={FacilityRepeatingAppointments}
    />
  </>
)
