import { useStyletron } from 'baseui'
import React from 'react'
import DriversNavbar from './DriversNavbar'
import DriversSearchForm from './DriversSearchForm/DriversSearchForm'

const DriversCheckinPage = (): JSX.Element => {
  const [css] = useStyletron()

  return (
    <div className={css({ background: '#f5f9ff', paddingTop: '10px' })}>
      <DriversNavbar />
      <DriversSearchForm />
    </div>
  )
}

export default DriversCheckinPage
