import styled from 'styled-components'

export const FormContainer = styled.div`
  padding: 0 16px;
  @media (orientation: landscape) {
    max-width: 480px;
    margin: 0 auto;
  }
`

export const ResultsContainer = styled.div`
  margin-top: 16px;
  overflow-y: scroll;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  @media (orientation: landscape) {
    max-width: 640px;
    margin: 16px auto;
    height: 50vh;
  }
`
export const NoResults = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15px;
`
