import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { fancyToast } from 'components/utils'
import ErrorMessageButton from 'components/shared/error-message-button'
import { Block } from 'baseui/block'
import PhoneInput from 'components/ui/generic/PhoneInput'
import Select from 'components/ui/generic/Select'
import authenticatedFetch from '../../utils/authenticated-fetch'

const CallModal = ({ showCallModal, onClose, order, setCallLoading, phone, setPhone }) => {
  const [contact, setContact] = React.useState([
    {
      label: 'Alice Blue',
      id: '1'
    }
  ])
  const [country, setCountry] = React.useState(undefined)

  const onModalClose = () => {
    onClose()
    setContact([])
    // setPhone('')
  }

  const createOutboundCall = async () => {
    setCallLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/outbound_calls.json`,
      method: 'POST',
      body: {
        outboundCall: {
          to: '+1' + phone,
          orderId: order?.id
        }
      }
    })

    if (status === 201) {
      fancyToast({ info: 'Outbound Call has been successfully scheduled' }, status)
    } else {
      fancyToast(json, status)
    }

    setCallLoading(false)
    onModalClose()
  }

  const formErrors = () => [
    { label: 'Phone number is required', status: !!phone },
    { label: 'Phone number should be at least 10 digits', status: phone?.length === 10 }
  ]

  return (
    <Modal
      isOpen={showCallModal}
      onClose={onModalClose}
      size={SIZE.auto}
      unstable_ModalBackdropScroll>
      <ModalHeader>{'Schedule with Voice AI'}</ModalHeader>
      <ModalBody>
        <Block width="520px">
          <p>
            Add a phone number (or update the customer contact) to call the customer to schedule a
            delivery appointment for order <strong>{order?.number}</strong>.
          </p>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800" flexGridRowGap="scale800">
            <FlexGridItem>
              <FormControl label={'Contact'}>
                <Select
                  value={contact}
                  onChange={params => setContact(params.value)}
                  options={[
                    {
                      label: 'Alice Blue',
                      id: '1'
                    },
                    {
                      label: 'Mario Duplat',
                      id: '2'
                    },
                    {
                      label: 'Aqulino Jimenez',
                      id: '3'
                    }
                  ]}
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl label={'Phone number'}>
                <PhoneInput
                  country={country}
                  onCountryChange={({ option }) => setCountry(option)}
                  text={phone}
                  onTextChange={e => setPhone(e.currentTarget.value)}
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
        </Block>
      </ModalBody>
      <ModalFooter>
        <ErrorMessageButton
          label="Start call & close"
          errors={formErrors()}
          buttonProps={{
            type: 'submit',
            onClick: createOutboundCall
          }}
        />
      </ModalFooter>
    </Modal>
  )
}

export default CallModal
