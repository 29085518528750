import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import React from 'react'
import { ParagraphSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { AppointmentPreference } from '../../models/AppointmentPreference'

const SecondCapacityLayerTrigger = ({
  options,
  secondCapacityLayerTrigger,
  setAppointmentPreference
}) => {
  const [value, setValue] = React.useState([
    {
      id: secondCapacityLayerTrigger || 0,
      label: secondCapacityLayerTrigger || 0
    }
  ])

  const onChange = option => {
    setValue([
      {
        id: option.id,
        label: option.id
      }
    ])

    setAppointmentPreference((prevAP: AppointmentPreference) => ({
      ...prevAP,
      secondCapacityLayerTrigger: option.id
    }))
  }

  const { t } = useTranslation()

  return (
    <FormControl
      label={t(
        'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Trigger.Label.Text'
      )}
      caption={t(
        'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Trigger.Caption.Text'
      )}>
      {options?.length > 0 ? (
        <Select
          options={options}
          value={value}
          searchable={false}
          clearable={false}
          onChange={({ option }) => onChange(option)}
          maxDropdownHeight="250px"
          overrides={{
            Root: { style: { maxWidth: '200px' } }
          }}
        />
      ) : (
        <ParagraphSmall color="negative">
          {t(
            'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Trigger.Validations.MinMaxValues.Text'
          )}
        </ParagraphSmall>
      )}
    </FormControl>
  )
}

export default SecondCapacityLayerTrigger
