import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CyberTable } from 'cyber/table'
import { PurchaseOrderLabelsContext } from 'components/facilities/facility-book'

const OpenSchedulingUpdateAppointmentsTable = ({ appointments }) => {
  const purchaseOrderLabelsContext = useContext(PurchaseOrderLabelsContext)
  const { purchaseOrderLabels } = purchaseOrderLabelsContext

  return (
    <CyberTable
      columns={[
        purchaseOrderLabels
          ? purchaseOrderLabels?.purchase_order_identifiers
          : 'Unique Load Identifer',
        'Arrival Time',
        'Created By',
        'Actions'
      ]}
      data={appointments.map(appointment => {
        const {
          purchaseOrderIdentifiers,
          id,
          humanReadableArrivalTime,
          createdByName
        } = appointment

        return [
          purchaseOrderIdentifiers,
          humanReadableArrivalTime,
          createdByName,
          <Link to={`/openscheduling/${id}/schedule`}>Update</Link>
        ]
      })}
    />
  )
}

export default OpenSchedulingUpdateAppointmentsTable
