import React, { useState, useContext } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import { Plus, Minus } from '@phosphor-icons/react'
import { useDebounce } from 'react-use'
import { orderService } from 'components/services/order/order.service'
import { Order } from 'components/models/Order'
import { StatusCodes } from 'components/constants/http-status-codes'
import { fancyToast } from 'components/utils'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'baseui'

import Input from 'components/ui/generic/Input'
import Button from 'components/ui/specific/SecondaryButton'
import FormControl from 'components/ui/generic/FormControl'

import useSizing from '../../../shared/hooks/use-sizing'
import { PurchaseOrderLabelsContext } from '../../facility-book'

const PurchaseOrderIdentifiers = ({
  purchaseOrders,
  setPurchaseOrders,
  regularExpression,
  facilityId,
  setOrderValidation,
  orderValidation,
  schedulerId
}) => {
  const purchaseOrderLabelsContext = useContext(PurchaseOrderLabelsContext)

  const { purchaseOrderIdentifiers, purchaseOrderIdentifiersCaption } =
    purchaseOrderLabelsContext?.purchaseOrderLabels || {}
  const [loading, setLoading] = useState<boolean>()
  const { customBreakPoints } = useSizing()
  const { t } = useTranslation()

  const [css, theme] = useStyletron()

  useDebounce(
    async () => {
      validateOrder({
        orders: purchaseOrders?.map((order: Order) => order.identifier),
        facilityId,
        schedulerId
      })
    },
    500,
    [purchaseOrders, schedulerId]
  )

  const validateOrder = async (order: any) => {
    setLoading(true)
    const [json, status] = await orderService.validateOrder(order)
    if (json && status !== StatusCodes.OK) {
      fancyToast({ info: json.error }, status)
    } else {
      setOrderValidation(json)
    }
    setLoading(false)
  }

  return (
    <FormControl
      label={
        purchaseOrderIdentifiers ||
        t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrderIdentifiers.Label.Text')
      }
      caption={
        purchaseOrderIdentifiersCaption ||
        t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrderIdentifiers.Caption.Text')
      }>
      <FlexGrid
        flexGridRowGap="scale400"
        flexGridColumnGap="scale400"
        flexGridColumnCount={customBreakPoints.mdMax ? 1 : 3}
        paddingRight="88px">
        {purchaseOrders.map((purchaseOrder, index) => {
          const { identifier } = purchaseOrder
          return (
            <FlexGridItem key={index}>
              {index === 0 && identifier && (
                <div
                  className={css({
                    display: 'flex',
                    position: 'absolute',
                    right: '0',
                    gap: theme.sizing.scale200
                  })}>
                  <Button
                    shape="circle"
                    onClick={() => {
                      const newPurchaseOrders = [...purchaseOrders, { identifier: '' }]
                      setPurchaseOrders(newPurchaseOrders)
                    }}>
                    <Plus />
                  </Button>
                  <Button
                    shape="circle"
                    disabled={purchaseOrders.length === 1}
                    onClick={() => {
                      if (purchaseOrders.length > 1) {
                        setPurchaseOrders(purchaseOrders.slice(0, -1))
                      }
                    }}>
                    <Minus />
                  </Button>
                </div>
              )}
              <Input
                name={`appointment[purchase_order_number][${index}]`}
                positive={regularExpression.test(identifier)}
                error={orderValidation?.errors?.filter(error => error.index === index)[0]}
                value={identifier}
                onChange={e => {
                  const newPurchaseOrders = [...purchaseOrders]
                  purchaseOrders[index].identifier = e.currentTarget.value
                  setPurchaseOrders(newPurchaseOrders)
                }}
              />
            </FlexGridItem>
          )
        })}
      </FlexGrid>
    </FormControl>
  )
}

export default PurchaseOrderIdentifiers
