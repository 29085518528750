import React, { useState } from 'react'
import { Block } from 'baseui/block'
import { ProgressSteps } from 'baseui/progress-steps'
import { HeadingSmall, ParagraphSmall } from 'baseui/typography'
import ReactHtmlParser from 'react-html-parser'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import Recaptcha from 'components/shared/recaptcha'
import moment from 'moment'
import { useStyletron } from 'baseui'
import { ArrowLeft, CaretLeft } from '@phosphor-icons/react'
import VelosticsDemo from 'components/shared/velostics_demo'
import { SCHEDULER } from 'components/models/User'
import { trim } from 'lodash'
import { useTranslation } from 'react-i18next'
import Step from 'components/ui/generic/Step'
import Button from 'components/ui/specific/SecondaryButton'
import Header from 'components/ui/generic/Header'
import { fancyToast } from '../../utils'
import authenticatedFetch from '../../utils/authenticated-fetch'
import AdditionalInformation from '../../facilities/book/additional-information'
import PurchaseOrderStep from '../../facilities/steps/purchase-order-step'
import DateAndTimeStep from '../../facilities/steps/date-and-time-step'
import TimeFormatter from '../../utils/time-formatter'
import UserOnboardingModal from '../../facilities/user-onboarding-modal'
import AppointmentFeedback from '../../shared/appointment-feedback'
import { BackIcon } from '../../facilities/FacilityBook.styled'
import useSizing from '../../shared/hooks/use-sizing'
import OthersToNotify from '../../facilities/OthersToNotify'
import ErrorMessageButton from '../../shared/error-message-button'

export const OpenSchedulingPOLabelsContext = React.createContext({
  purchaseOrderLabels: null
})

const OpenSchedulingCreateAppointment = props => {
  const [css, theme] = useStyletron()
  const [facility, setFacility] = useState<any>({})
  const [progress, setProgress] = React.useState(1)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [recaptchaComplete, setRecaptchaComplete] = useState<boolean>(
    !!(process.env.RAILS_ENV === 'test' || process.env.RAILS_ENV === 'development')
  )
  const { customBreakPoints } = useSizing()
  const [appointment, setAppointment] = useState<any>({})
  const {
    answers,
    actions: { resetTemporalFilesState, updateFileUploadState }
  } = useCQStateValue()
  const [email, setEmail] = useState<string>()
  const [purchaseOrders, setPurchaseOrders] = React.useState([{ identifier: '' }])
  const [allOthersToNotifyAreValid, setAllOthersToNotifyAreValid] = useState<boolean>(true)
  const { t } = useTranslation()

  const createAppointment = async () => {
    setLoading(true)

    const slotCount =
      (facility && facility.appointmentType && facility.appointmentType.slotCount) || 1

    const [json, status] = await authenticatedFetch({
      path: `/appointments/create_for_open_scheduling.json`,
      method: 'POST',
      body: {
        appointment: {
          ...appointment,
          purchaseOrdersAttributes: appointment.purchaseOrdersAttributes.map(
            (order: { identifier: string }) => ({
              identifier: trim(order.identifier)
            })
          ),
          ...(answers && { answersAttributes: answers, questions: answers }),
          facilityId: facility.id,
          slotCount,
          schedulerEmail: email,
          othersToNotify: appointment?.othersToNotify
            ?.split(/[;,]/)
            .map((email: string) => email.trim())
        }
      }
    })
    setLoading(false)
    if (status === 201) {
      resetTemporalFilesState()
      setAppointment({ ...appointment, id: json.id, confirmationId: json.confirmationId })
      setProgress(5)
    } else if (status === 409) {
      fancyToast(json, status)
      setProgress(3)
    } else {
      fancyToast(json, status)
    }
  }

  const appointmentTimeMessage = () => {
    if (
      facility.firstComeFirstServeOpenTime &&
      facility.firstComeFirstServeCloseTime &&
      appointment.quantity <= facility.maxLtlQuantity
    ) {
      const timeRange = new TimeFormatter('shortTime').formatRange(
        facility.firstComeFirstServeOpenTime,
        facility.firstComeFirstServeCloseTime,
        facility.timeZone
      )
      return `from ${timeRange}`
    }
    const formattedTime = new TimeFormatter('shortTime').format(
      moment(new Date(appointment.arrivalTime))
        .add(appointment.quantity * 5, 'minutes')
        .toDate(),
      facility.timeZone
    )

    return `at ${formattedTime}`
  }

  const formatPurchaseOrders = () => {
    return (
      appointment &&
      appointment.purchaseOrdersAttributes &&
      appointment.purchaseOrdersAttributes
        .map(({ identifier }) => {
          return identifier
        })
        .join(', ')
    )
  }

  const goBackOneStep = () => {
    if (Math.min(1, progress) <= 1) {
      resetTemporalFilesState()
      updateFileUploadState(false)
    }
    setProgress(Math.min(1, progress))
  }

  return (
    <>
      <UserOnboardingModal />
      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        {progress > 1 && (
          <CaretLeft onClick={goBackOneStep} style={{ marginTop: -25, cursor: 'pointer' }} />
        )}
        <Header
          title={`${facility?.shipperName || ''} ${t(
            'Scheduler.OpenScheduling.Header.Appointment.Text'
          )} ${t('Scheduler.OpenScheduling.Header.SchedulingPortal.Text')}`}
        />
      </div>
      <Block maxWidth="720px">
        <ProgressSteps
          current={progress}
          overrides={{
            Root: {
              style: {
                width: '100%',
                transform: customBreakPoints?.mdMax ? 'translateX(-25px)' : ''
              }
            }
          }}>
          <Step title={t('Scheduler.OpenScheduling.Steps.TermsOfService.Header.Text')} isActive>
            <FlexGrid flexGridRowGap="scale200">
              <FlexGridItem>
                <ParagraphSmall>
                  {facility.id
                    ? facility.note
                      ? ReactHtmlParser(facility.note)
                      : t(
                          'Scheduler.OpenScheduling.Steps.TermsOfService.SubTitles.NotRequireSpecialTerms.Text'
                        )
                    : t(
                        'Scheduler.OpenScheduling.Steps.TermsOfService.SubTitles.PleaseSelectFacility.Text'
                      )}
                </ParagraphSmall>
              </FlexGridItem>
            </FlexGrid>
          </Step>
          <Step title={t('Scheduler.OpenScheduling.Steps.OrderInformation.Header.Text')}>
            <OpenSchedulingPOLabelsContext.Provider
              value={{
                purchaseOrderLabels:
                  facility &&
                  facility.appointmentPreference &&
                  facility.appointmentPreference.purchaseOrderLabels
              }}>
              <PurchaseOrderStep
                {...{
                  facility,
                  setFacility,
                  appointment,
                  setAppointment,
                  setProgress,
                  setEmail,
                  setPurchaseOrders,
                  purchaseOrders
                }}
              />
            </OpenSchedulingPOLabelsContext.Provider>
          </Step>
          <Step title={t('Scheduler.OpenScheduling.Steps.AdditionalInformation.Header.Text')}>
            <FlexGrid
              flexGridColumnCount={customBreakPoints.mdMax ? 1 : 3}
              flexGridColumnGap="scale400">
              <FlexGridItem>
                <AdditionalInformation
                  {...{ appointment, setAppointment }}
                  afterSubmit={() => setProgress(3)}
                  loading={loading}
                  appointmentType={appointment.appointmentType}
                  userType={SCHEDULER}
                />
              </FlexGridItem>
            </FlexGrid>
          </Step>
          <Step title={t('Scheduler.OpenScheduling.Steps.DateTime.Header.Text')}>
            <DateAndTimeStep
              {...{
                facility,
                appointment,
                setAppointment,
                purchaseOrders
              }}
              onTimeSelect={() => setProgress(4)}
            />
          </Step>
          <Step title={t('Scheduler.OpenScheduling.Steps.Review.Header.Text')}>
            <OthersToNotify
              appointment={appointment}
              setAppointment={setAppointment}
              setAllOthersToNotifyAreValid={setAllOthersToNotifyAreValid}
            />
            <ParagraphSmall>
              {t('Scheduler.OpenScheduling.Steps.Review.SubTitles.SchedulingAppointmentFor.Text')}{' '}
              <strong>{formatPurchaseOrders()}</strong>{' '}
              {t('Scheduler.OpenScheduling.Steps.Review.SubTitles.On.Text')}{' '}
              <strong>
                {new TimeFormatter('shortDate').format(appointment.arrivalTime, facility.timeZone)}
              </strong>{' '}
              {appointmentTimeMessage()}
            </ParagraphSmall>
            {recaptchaComplete ? (
              <ErrorMessageButton
                errors={[
                  {
                    label: t(
                      'Scheduler.OpenScheduling.Steps.Review.Validations.MailsMustBeValid.Text'
                    ),
                    status: allOthersToNotifyAreValid
                  }
                ]}
                buttonProps={{ onClick: createAppointment, isLoading: loading }}
                label={t('Scheduler.OpenScheduling.Steps.Review.Buttons.Submit.Text')}
              />
            ) : (
              <Recaptcha
                onChange={() => {
                  setRecaptchaComplete(true)
                }}
              />
            )}
          </Step>
          <Step title={t('Scheduler.OpenScheduling.Steps.Finish.Header.Text')}>
            <HeadingSmall>
              {t('Scheduler.OpenScheduling.Steps.Finish.SubTitles.YouAreAllSet.Text')}
            </HeadingSmall>
            <ParagraphSmall>
              {t('Scheduler.OpenScheduling.Steps.Finish.SubTitles.YourAppointment.Text')}{' '}
              <b>{appointment.confirmationId}</b>{' '}
              {t('Scheduler.OpenScheduling.Steps.Finish.SubTitles.HasBeenScheduled.Text')}
            </ParagraphSmall>
            <FlexGrid width="100%" flexGridRowGap="scale800">
              <FlexGridItem>
                <AppointmentFeedback appointmentId={appointment.id} openScheduling />
              </FlexGridItem>
              <FlexGridItem>
                <Button
                  onClick={() => {
                    window.location.reload()
                  }}>
                  {t(
                    'Scheduler.OpenScheduling.Steps.Finish.Buttons.ScheduleAnotherAppointment.Text'
                  )}
                </Button>
              </FlexGridItem>
              <FlexGridItem>
                <VelosticsDemo />
              </FlexGridItem>
            </FlexGrid>
          </Step>
        </ProgressSteps>
      </Block>
    </>
  )
}

export default OpenSchedulingCreateAppointment
