import { FlexGrid } from 'baseui/flex-grid'
import Header from 'components/ui/generic/Header'
import { PurchaseOrderLabelsContext } from 'components/facilities/facility-book'
import Recaptcha from 'components/shared/recaptcha'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import React, { useEffect, useState } from 'react'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../constants/default-shipper-subdomain'
import OpenSchedulingUpdateAppointmentsTable from './OpenSchedulingUpdateAppointmentsTable'
import OpenSchedulingUpdateSearchBar from './OpenSchedulingUpdateSearchBar'

const OpenSchedulingUpdateAppointment = () => {
  const subdomain = window.location.hostname.split('.')[0]
  const [appointments, setAppointments] = useState([])
  const [mainAppointment, setMainAppointment] = useState(null)
  const [recaptchaComplete, setRecaptchaComplete] = useState<boolean>(
    process.env.RAILS_ENV === 'test' || process.env.RAILS_ENV === 'development' ? true : false
  )

  useEffect(() => {
    authenticatedFetch({
      path: `/facilities/open_scheduling_list.json?subdomain=${
        subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain
      }`
    })
      .then(([facilityLabels, _status]) => {
        if (facilityLabels.length >= 1) {
          setMainAppointment(facilityLabels[0])
        }
      })
      .catch(console.log)
  }, [])

  return (
    <>
      <PurchaseOrderLabelsContext.Provider
        value={{
          purchaseOrderLabels:
            mainAppointment &&
            mainAppointment.appointmentPreference &&
            mainAppointment.appointmentPreference.purchase_order_field_names
        }}>
        <Header
          title="Appointments"
          rightItems={[
            <OpenSchedulingUpdateSearchBar
              setAppointments={setAppointments}
              recaptchaComplete={recaptchaComplete}
            />
          ]}
        />

        {appointments.length > 0 && (
          <OpenSchedulingUpdateAppointmentsTable appointments={appointments} />
        )}
      </PurchaseOrderLabelsContext.Provider>

      <div style={{ marginTop: '30px' }}>
        <FlexGrid alignItems="center" justifyContent="center">
          <Recaptcha
            onChange={() => {
              setRecaptchaComplete(true)
            }}
          />
        </FlexGrid>
      </div>
    </>
  )
}

export default OpenSchedulingUpdateAppointment
