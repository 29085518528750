import React from 'react'
import { Table } from 'baseui/table-semantic'

const VTable = ({ overrides, ...props }) => {
  return (
    <Table
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            ...$theme.borders.border400,
            borderRadius: $theme.borders.radius200,
            overflow: 'hidden'
          })
        },
        TableHeadCell: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            paddingTop: $theme.sizing.scale400,
            paddingBottom: $theme.sizing.scale400,
            paddingLeft: $theme.sizing.scale600,
            paddingRight: $theme.sizing.scale600
          })
        },
        TableBodyCell: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            paddingTop: $theme.sizing.scale400,
            paddingBottom: $theme.sizing.scale400,
            paddingLeft: $theme.sizing.scale600,
            paddingRight: $theme.sizing.scale600,
            borderBottomColor: $theme.colors.transparent,
            verticalAlign: 'middle'
          })
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VTable
