import React, { useContext } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import AppointmentSearch from './appointment-search'
import Schedule from './schedule'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { SHIPPER } from 'components/models/User'
import SchedulerDashboard from 'components/old_scheduler/dashboard'
import AppointmentInstantsearch from './appointment-instantsearch'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'

const AppointmentRoutes = ({ match }) => {
  const { currentUser } = useContext(CurrentUserContext)

  let SearchPage = SchedulerDashboard
  if (currentUser?.userType === SHIPPER) {
    SearchPage = AppointmentInstantsearch
  } else if (currentUser?.userType === SHIPPER) {
    SearchPage = AppointmentSearch
  }
  return (
    <CustomQuestionsProvider>
      <Switch>
        <Route
          path={match.url}
          exact
          component={currentUser?.schedulerMode ? SchedulerDashboard : SearchPage}
        />
        <Route exact path={`${match.url}/:handle/schedule`} component={Schedule} />
        <Redirect
          exact
          from={`${match.url}/:handle/reschedule`}
          to={`${match.url}/:handle/schedule`}
        />
      </Switch>
    </CustomQuestionsProvider>
  )
}

export default AppointmentRoutes
