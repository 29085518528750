import React from 'react'
import { Tabs } from 'baseui/tabs'

const VTabs = ({ children, position, ...props }) => {
  return (
    <Tabs
      overrides={{
        TabBar: {
          style: ({ $theme }) => ({
            position: 'absolute',
            right: $theme.sizing.scale1000,
            top: 0
          })
        },
        TabContent: {
          style: () => ({ paddingLeft: 0, position: 'relative', maxWidth: '1200px' })
        },
        Tab: {
          style: ({ $theme }) => ({
            lineHeight: $theme.sizing.scale1000,
            fontSize: $theme.typography.LabelXSmall.fontSize,
            [$theme.mediaQuery.large]: {
              fontSize: $theme.typography.LabelSmall.fontSize
            }
          })
        }
      }}
      {...props}>
      {children}
    </Tabs>
  )
}
export default VTabs
