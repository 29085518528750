import React, { useState } from 'react'
import { ParagraphSmall } from 'baseui/typography'
import TimeFormatter from '../../utils/time-formatter'

const ArrivalTime = ({ appointment, facility }) => {
  const { lessThanTruckload, timeZone, arrivalTime } = appointment
  const { firstComeFirstServeOpenTime, firstComeFirstServeCloseTime } = facility

  const [initialArrivalTime, setInitialArrivalTime] = useState(appointment.arrivalTime)

  const timeUpdated = appointment.arrivalTime !== initialArrivalTime

  const LessThanTruckloadText = () => {
    return (
      <ParagraphSmall>
        {new TimeFormatter('shortTime').formatRange(
          firstComeFirstServeOpenTime,
          firstComeFirstServeCloseTime,
          timeZone
        )}{' '}
        on{' '}
        {timeUpdated && (
          <>
            <br />
            <span style={{ color: 'grey' }}>
              {new TimeFormatter('shortDate').format(initialArrivalTime, timeZone)} →{' '}
            </span>
          </>
        )}
        {new TimeFormatter('shortDate').format(arrivalTime, timeZone)}
      </ParagraphSmall>
    )
  }

  const FullTruckloadText = () => {
    return (
      <ParagraphSmall>
        {timeUpdated && (
          <span style={{ color: 'grey' }}>
            {new TimeFormatter('fullDate').format(initialArrivalTime, timeZone)} →{' '}
          </span>
        )}
        {new TimeFormatter('fullDate').format(arrivalTime, timeZone)}
      </ParagraphSmall>
    )
  }

  if (!arrivalTime) {
    return <ParagraphSmall>Unscheduled</ParagraphSmall>
  }

  return lessThanTruckload ? <LessThanTruckloadText /> : <FullTruckloadText />
}

export default ArrivalTime
