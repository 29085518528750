import { InstantAppointment } from 'components/appointments/types'
import { DEFAULT_APPOINTMENT_DURATIONS } from 'components/constants/default-appointment-durations'
import { getAnswers, getIdentifiers } from 'components/utils/format-answer-identifiers'
import hitTransformer from 'cyber/search/hit-transformer'
import moment from 'moment'
import { FullCalendarEvent } from '../types'
import { FIXED_UNASSIGNED_COLUMN } from '../../../contexts/dock-assigment.context'

export const INTEGRATIONS = 'integrations'
export const DAILY_PLAN = 'DAILY_PLAN'
export const DOCK_ASSIGNMENT = 'DOCK_ASSIGNMENT'
export const APPOINTMENT_TYPE = 'Appointment'

function useEvents(hits, facilities, page = DAILY_PLAN) {
  return hits.map(hitTransformer).map((hit: InstantAppointment): FullCalendarEvent => {
    const {
      id,
      arrivalTime,
      purchaseOrderIdentifiers,
      equipmentTypeId,
      color,
      schedulerName,
      facilityId,
      appointmentTypeId,
      recurringAppointmentBlueprintId,
      confirmationId,
      status,
      appointmentTypeName,
      dockId,
      dockName,
      dockTime,
      carrierName,
      identifiersJson,
      commodityType,
      carrierScac
    } = hit

    const answerIdentifiers = getIdentifiers(identifiersJson)
    const APPOINTMENT_DURATION_FALLBACK = 60

    const title =
      schedulerName?.toLowerCase() === INTEGRATIONS || !schedulerName ? '' : schedulerName
    const subtitle = [carrierName, ...answerIdentifiers].filter(item => item).join(' | ')

    let duration = DEFAULT_APPOINTMENT_DURATIONS[equipmentTypeId] || APPOINTMENT_DURATION_FALLBACK

    const purchaseOrders =
      purchaseOrderIdentifiers.length > 0 ? purchaseOrderIdentifiers.join(', ') : null

    if (
      facilityId &&
      facilities[facilityId]?.appointmentDurationsHash &&
      facilities[facilityId]?.appointmentDurationsHash[appointmentTypeId] &&
      facilities[facilityId]?.appointmentDurationsHash[appointmentTypeId][equipmentTypeId]
    ) {
      duration =
        facilities[facilityId].appointmentDurationsHash[appointmentTypeId][equipmentTypeId].duration
    }

    const showAppointmentId =
      facilities[facilityId]?.appointmentPreference.showAppointmentIdOnCalendar

    const identifiers = getAnswers(identifiersJson)

    const startTime =
      page === DAILY_PLAN ? arrivalTime : (dockTime as any) !== -1 ? dockTime : arrivalTime

    return {
      id,
      color,
      start: startTime.toISOString(),
      end: moment(startTime).add(duration, 'minutes').toISOString(),
      title,
      resourceId: page === DAILY_PLAN ? facilityId : dockId || FIXED_UNASSIGNED_COLUMN.id,
      extendedProps: {
        purchaseOrderIdentifiers,
        equipmentTypeId,
        subtitle,
        appointmentTypeId,
        commodityType,
        type: APPOINTMENT_TYPE,
        recurringAppointmentBlueprintId,
        confirmationId,
        showAppointmentId,
        schedulerName,
        status,
        dockName,
        appointmentTypeName,
        purchaseOrders,
        carrierName,
        identifiers,
        carrierScac,
        dockTime
      }
    }
  })
}

export default useEvents
