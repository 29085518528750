import React, { createContext, useContext, useReducer } from 'react'
import customQuestionsReducers from '../reducers/customQuestions.reducer'

interface AnswerAttrContextProps {
  temporalFiles: Array<any>
  documentSelections: Array<any>
  answers: Array<any>
  questions: Array<any>
  settings: {
    startFileUpload: boolean
  }
}

const initialState: AnswerAttrContextProps = {
  temporalFiles: [],
  documentSelections: [],
  answers: [],
  questions: [],
  settings: {
    startFileUpload: false
  }
}

export const CustomQuestionsContext = createContext({} as any)

export const CustomQuestionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(customQuestionsReducers, initialState)

  const setAnswers = answers => {
    dispatch({ type: 'SET_ANSWERS', payload: answers })
  }

  const updateAnswer = answer => {
    dispatch({ type: 'UPDATE_ANSWERS', payload: answer })
  }

  const setQuestions = questions => {
    dispatch({ type: 'SET_QUESTIONS', payload: questions })
  }

  const updateQuestion = question => {
    dispatch({ type: 'UPDATE_QUESTION', payload: question })
  }

  const loadTemporalFiles = (questionId, files, isHidden?) => {
    dispatch({
      type: 'SET_TEMPORAL_FILES',
      payload: {
        questionId,
        files,
        isHidden
      }
    })
  }

  const updateTemporalFiles = questionId => {
    dispatch({
      type: 'UPDATE_TEMPORAL_FILES_STATE',
      payload: {
        questionId
      }
    })
  }
  const removeTemporalFile = (questionId, id) => {
    dispatch({
      type: 'REMOVE_TEMPORAL_FILES',
      payload: { questionId, fileId: id }
    })
  }

  const resetTemporalFilesState = () => {
    dispatch({
      type: 'RESET_TEMPORAL_FILES',
      payload: true
    })
  }

  const addFileSelection = (documentId, questionId) => {
    dispatch({
      type: 'ADD_FILE_SELECTION',
      payload: { documentId, questionId }
    })
  }

  const updateFileUploadState = value => {
    dispatch({
      type: 'FILE_UPLOAD_STATE',
      payload: value
    })
  }

  const actions = {
    setAnswers,
    updateAnswer,
    setQuestions,
    updateQuestion,
    loadTemporalFiles,
    updateTemporalFiles,
    removeTemporalFile,
    resetTemporalFilesState,
    updateFileUploadState,
    addFileSelection
  }

  return (
    <CustomQuestionsContext.Provider value={{ ...state, actions }}>
      {children}
    </CustomQuestionsContext.Provider>
  )
}

export const useCQStateValue = () => useContext(CustomQuestionsContext)
