import React from 'react'
import { Select, SIZE } from 'baseui/select'

const VSelect = ({
  size = '',
  options = [],
  minWidth,
  maxWidth,
  maxDropdownHeight,
  overrides,
  disabled,
  ...props
}) => {
  return (
    <Select
      size={size || SIZE.compact}
      options={options}
      disabled={disabled}
      maxDropdownHeight={maxDropdownHeight || '50vh'}
      overrides={{
        ControlContainer: {
          style: ({ $theme }) => ({
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            minWidth: minWidth || '',
            maxWidth: maxWidth || '',
            minHeight: $theme.sizing.scale1000
          })
        },
        SingleValue: {
          style: ({ $theme }) => ({
            lineHeight: $theme.sizing.scale800
          })
        },
        ValueContainer: {
          style: () => ({
            flexWrap: 'nowrap',
            overflow: 'auto'
          })
        },
        Tag: {
          props: {
            overrides: {
              Root: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.backgroundTertiary,
                  color: $theme.colors.contentSecondary
                })
              },
              Text: {
                style: ({ $theme }) => ({
                  fontSize: $theme.typography.LabelXSmall.fontSize,
                  maxWidth: '13em'
                })
              }
            }
          }
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VSelect
