import React from 'react'
import { Input, SIZE } from 'baseui/input'

const VInput = ({ size = '', overrides, value, startEnhancer, ...props }) => {
  return (
    <Input
      size={size || SIZE.compact}
      value={value}
      startEnhancer={startEnhancer || ''}
      {...props}
      overrides={{
        ...overrides,
        Root: {
          style: ({ $theme }) => ({
            borderTopWidth: $theme.borders.borderWidth,
            borderBottomWidth: $theme.borders.borderWidth,
            borderLeftWidth: $theme.borders.borderWidth,
            borderRightWidth: $theme.borders.borderWidth,
            minHeight: $theme.sizing.scale1000,
            paddingLeft: 0,
            ...overrides?.Root?.style
          })
        }
      }}
    />
  )
}
export default VInput
