import { EmoticonRating } from 'baseui/rating'
import React, { useState } from 'react'
import { useStyletron } from 'baseui'

import Textarea from 'components/ui/generic/Textarea'
import Button from 'components/ui/generic/Button'
import { HeadingXSmall } from 'baseui/typography'
import { fancyToast } from '../utils'
import authenticatedFetch from '../utils/authenticated-fetch'
import Pretag from './pretag'

interface AppointmentFeedbackProps {
  appointmentId: string
  openScheduling?: boolean
}

const AppointmentFeedback = ({ appointmentId, openScheduling }: AppointmentFeedbackProps) => {
  const [css, theme] = useStyletron()

  const [rating, setRating] = useState(null)
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const [finished, setFinished] = useState<boolean>(false)

  const selectEmoticon = async ({ value }) => {
    if (appointmentId) {
      setRating(value)
      const [json, status] = await authenticatedFetch({
        path: `/appointments/${appointmentId}${openScheduling ? '/open_scheduling' : ''}.json`,
        body: { appointment: { metadata: { rating: value } } },
        method: 'PATCH'
      })

      if (status === 200 && value > 3) {
        fancyToast({ info: 'Thanks for the feedback!' }, status)
        setFinished(true)
      } else if (status !== 200) {
        fancyToast(json, status)
      }
    }
  }

  const submitWrittenFeedback = async () => {
    if (appointmentId) {
      const [json, status] = await authenticatedFetch({
        path: `/appointments/${appointmentId}${openScheduling ? '/open_scheduling' : ''}.json`,
        body: { appointment: { metadata: { feedbackMessage } } },
        method: 'PATCH'
      })

      if (status === 200) {
        fancyToast({ info: 'Thanks for the feedback!' }, status)
        setFinished(true)
      } else {
        fancyToast(json, status)
      }
    }
  }

  return (
    <div className={css({ visibility: finished ? 'hidden' : 'initial' })}>
      <HeadingXSmall marginBottom="8px">
        How was your appointment scheduling experience?
      </HeadingXSmall>
      <EmoticonRating value={rating} onChange={selectEmoticon} />
      {rating && rating <= 3 && (
        <>
          <Textarea
            placeholder="What could we do better?"
            value={feedbackMessage}
            onChange={e => setFeedbackMessage(e.currentTarget.value)}
          />
          <Button
            onClick={submitWrittenFeedback}
            overrides={{ BaseButton: { style: { width: '100%' } } }}>
            Submit
          </Button>
        </>
      )}
    </div>
  )
}

export default AppointmentFeedback
