import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography'
import DockCapacityContainer from './DockCapacityContainer'
import SecondCapacityLayerTrigger from './SecondCapacityLayerTrigger'
import { useTranslation } from 'react-i18next'

import Checkbox from 'components/ui/generic/Checkbox'
import FormControl from 'components/ui/generic/FormControl'

export const DockCapacities = ({
  appointmentPreference,
  setAppointmentPreference,
  disabled,
  equipmentTypes,
  appointmentTypes,
  secondCapacityLayerTriggerOptions
}) => {
  const { dockCapacitiesAttributes } = appointmentPreference
  const { t } = useTranslation()

  return (
    <>
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.EquipmentCapacity.Header.Text')}
      </HeadingXSmall>

      <FormControl
        label={t(
          'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.ShareEquipmentCapacity.Label.Text'
        )}>
        <>
          <FlexGrid flexGridColumnCount={2} width="150px" marginBottom="16px">
            <FlexGridItem>
              <Checkbox
                checked={appointmentPreference?.equipmentCapacityShared}
                onChange={e =>
                  setAppointmentPreference({
                    ...appointmentPreference,
                    equipmentCapacityShared: e.currentTarget.checked
                  })
                }
                label={t(
                  'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.ShareEquipmentCapacity.Items.Yes.Text'
                )}></Checkbox>
            </FlexGridItem>
            <FlexGridItem>
              <Checkbox
                checked={!appointmentPreference?.equipmentCapacityShared}
                onChange={e =>
                  setAppointmentPreference({
                    ...appointmentPreference,
                    equipmentCapacityShared: !e.currentTarget.checked
                  })
                }
                label={t(
                  'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.ShareEquipmentCapacity.Items.No.Text'
                )}></Checkbox>
            </FlexGridItem>
          </FlexGrid>
          <DockCapacityContainer
            equipmentTypes={equipmentTypes}
            appointmentTypes={appointmentTypes}
            appointmentPreference={appointmentPreference}
            dockCapacitiesAttributes={dockCapacitiesAttributes}
            setAppointmentPreference={setAppointmentPreference}
            secondCapacityLayer={false}
          />
        </>
      </FormControl>
      <FlexGridItem>
        <ParagraphSmall>
          {t(
            'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Label.Text'
          )}
        </ParagraphSmall>
      </FlexGridItem>
      <FlexGrid flexGridColumnCount={2} width="150px" marginBottom="20px">
        <FlexGridItem>
          <Checkbox
            checked={appointmentPreference?.secondCapacityLayerActive}
            onChange={e =>
              setAppointmentPreference({
                ...appointmentPreference,
                secondCapacityLayerActive: e.currentTarget.checked
              })
            }
            label={t(
              'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Items.Yes.Text'
            )}></Checkbox>
        </FlexGridItem>
        <FlexGridItem>
          <Checkbox
            checked={!appointmentPreference?.secondCapacityLayerActive}
            onChange={e =>
              setAppointmentPreference({
                ...appointmentPreference,
                secondCapacityLayerActive: !e.currentTarget.checked
              })
            }
            label={t(
              'AppointmentPreferences.Form.Fields.EquipmentCapacity.Fields.EnableSecondCapacityLayer.Items.No.Text'
            )}></Checkbox>
        </FlexGridItem>
      </FlexGrid>
      {appointmentPreference?.secondCapacityLayerActive && (
        <>
          <SecondCapacityLayerTrigger
            options={secondCapacityLayerTriggerOptions}
            secondCapacityLayerTrigger={appointmentPreference.secondCapacityLayerTrigger}
            setAppointmentPreference={setAppointmentPreference}
          />
          <DockCapacityContainer
            equipmentTypes={equipmentTypes}
            appointmentTypes={appointmentTypes}
            appointmentPreference={appointmentPreference}
            dockCapacitiesAttributes={dockCapacitiesAttributes}
            setAppointmentPreference={setAppointmentPreference}
            secondCapacityLayer={true}
          />
        </>
      )}
    </>
  )
}
