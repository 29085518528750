import { useStyletron } from 'baseui'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import { useDriversCheckinContext } from 'components/contexts/drivers-checkin-context'
import StyledSpinner from 'components/shared/styled-spinner'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DriversCheckedInMessage from '../DriversCheckedInMessage/DriversCheckedInMessage'
import DriversCustomQuestions from '../DriversCustomQuestions'
import DriversNavbar from '../DriversNavbar'
import DriversResultDetail from '../DriversResultDetail/DriversResultDetail'
import { FormContainer } from '../DriversSearchForm/DriversSearchForm.styled'
import DriversSearchInput from '../DriversSearchInput'
import DriversUpdateAnswersMessage from '../DriversUpdateAnswersMessage/DriversUpdateAnswersMessage'
import {
  setIsAnswerListUpdated,
  setIsCheckedIn,
  setLoading,
  setMatches,
  setPickedAppointment,
  setShowQuestionList,
  updateSearchTerm
} from '../reducer/actions'
import { CHECKED_OUT } from 'components/utils/appointment-statuses'

const DriversCheckinDetailPage = (): JSX.Element => {
  const [css] = useStyletron()
  const {
    loading,
    pickedAppointment,
    searchTerm,
    dispatch,
    isCheckedIn,
    showQuestionList,
    isAnswerListUpdated,
    getResults,
    confirmAppointment,
    resetForm
  } = useDriversCheckinContext()
  const history = useHistory()

  useEffect(() => {
    if (!pickedAppointment) {
      dispatch(updateSearchTerm(''))
      dispatch(setMatches([]))
      dispatch(setPickedAppointment(null))
      dispatch(setIsCheckedIn(false))
      dispatch(setLoading(false))
      dispatch(setShowQuestionList(false))
      dispatch(setIsAnswerListUpdated(false))

      history.push('/pass')
    }
  }, [history, pickedAppointment, dispatch])

  if (isAnswerListUpdated) {
    return (
      <div className={css({ height: '100vh' })}>
        <DriversNavbar />
        <DriversUpdateAnswersMessage reset={resetForm} appointment={pickedAppointment} />
      </div>
    )
  }

  if (showQuestionList) {
    return (
      <div className={css({ height: '100vh' })}>
        <DriversNavbar />
        <CustomQuestionsProvider>
          <DriversCustomQuestions
            disabled={pickedAppointment?.status === CHECKED_OUT}
            appointment={pickedAppointment}
            setSuccess={appointment => {
              dispatch(setIsAnswerListUpdated(true))
              dispatch(setPickedAppointment(appointment))
            }}
          />
        </CustomQuestionsProvider>
      </div>
    )
  }

  if (isCheckedIn) {
    return (
      <div className={css({ height: '100vh' })}>
        <DriversNavbar />
        <DriversCheckedInMessage
          showQuestionList={() => dispatch(setShowQuestionList(true))}
          welcomeMessage={pickedAppointment.welcomeMessage}
        />
      </div>
    )
  }

  return (
    <div className={css({ height: '100vh' })}>
      <DriversNavbar />
      <FormContainer>
        <DriversSearchInput
          value={searchTerm}
          handleValueUpdate={value => dispatch(updateSearchTerm(value))}
          getResults={getResults}
        />
        {loading || pickedAppointment === null ? (
          <StyledSpinner />
        ) : (
          <div>
            <DriversResultDetail
              resetResult={() => {
                dispatch(setPickedAppointment(null))
                history.push('/pass')
              }}
              confirmAppointment={confirmAppointment}
              appointment={pickedAppointment}
            />
          </div>
        )}
      </FormContainer>
    </div>
  )
}

export default DriversCheckinDetailPage
