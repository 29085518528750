import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import ShipperDashboard from '../shipper/shipper-dashboard'
import CheckinKiosk from '../checkins/checkin-kiosk'
import { CurrentUserContext } from './current-user-context'
import CurrentFacilityBook from 'components/facilities/current-facility-book'
import { BROKER, CARRIER, SCHEDULER } from 'components/models/User'

// TODO(adenta) this file is really confusing
const Homepage = ({ match }) => {
  const { currentUser } = useContext(CurrentUserContext)

  if (!currentUser) {
    return <Redirect to="/" />
  }
  if ([BROKER, CARRIER, SCHEDULER].includes(currentUser.userType) || currentUser.schedulerMode) {
    return <Route path={match.url} exact component={CurrentFacilityBook} />
  }
  if (currentUser.shipperId) {
    return <Route path={match.url} exact component={ShipperDashboard} />
  }
  return <Route path={match.url} exact component={CheckinKiosk} />
}

export default Homepage
