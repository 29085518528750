import Datepicker from 'components/ui/generic/DatePicker'

import React, { ReactElement } from 'react'
import { DATE_PLACEHOLDER } from '../../constants/date-format'
import DEFAULT_DATE_FORMAT from '../../constants/default-date-format'
import { isValidDate } from '../../utils/is-valid-date'

interface DateTypeProps {
  value: string
  onChange: (e: { date: Date | Date[] }) => void
  disabled?: boolean
}

const DateType = ({ value, onChange, disabled }: DateTypeProps): ReactElement => (
  <Datepicker
    disabled={disabled}
    value={value && isValidDate(value) ? new Date(value) : []}
    formatString={DEFAULT_DATE_FORMAT}
    placeholder={DATE_PLACEHOLDER}
    onChange={onChange}
  />
)
export default DateType
