import { PAGE_SIZE } from 'components/constants/page-size'
import { PaginationResponse } from 'components/types/pagination-response'
import http from 'components/utils/authenticated-fetch'
import { HttpMethods } from '../constants/http-methods'
import authenticatedFetch from 'components/utils/authenticated-fetch'
const DEFAULT_PARAMS = {
  search: '',
  page: 1,
  pageSize: PAGE_SIZE
}
class FacilityService {
  getFacility = (id: string): Promise<any> =>
    http({
      path: `/facilities/${id}.json`
    }).then(([facility]) => facility)

  findFacilitiies = (params = DEFAULT_PARAMS): Promise<PaginationResponse<any>> => {
    const query = new URLSearchParams()
    if (params?.page) query.set('page', String(params.page))
    if (params?.pageSize) query.set('page_size', String(params.pageSize))
    if (params?.search) query.set('name', params.search)

    return http({
      path: `/facilities/paginate.json?${query.toString()}`,
      method: HttpMethods.GET
    }).then(([data]) => data)
  }

  findFacilitiesWithoutPagination = (params): Promise<any> => {
    const query = new URLSearchParams()

    params?.template && query.set('template', params.template)
    params?.filters && query.set('filters', JSON.stringify(params.filters))
    params?.search && query.set('search', params.search)

    return http({
      path: `/facilities.json?${query.toString()}`,
      method: HttpMethods.GET
    }).then(([data]) => data)
  }

  getFacilityDocks = (id: string): Promise<any> =>
    http({
      path: `/facilities/${id}/docks.json`
    }).then(([docks]) => docks)

  updateFacility = (id, body): Promise<any> =>
    http({
      path: `/facilities/${id}.json`,
      method: HttpMethods.PATCH,
      body
    }).then(([result, status]) => [result, status])

  getFacilities = (body): Promise<any> =>
    http({
      path: `/facilities/get_multiple_facilities_by_ids.json`,
      method: HttpMethods.POST,
      body
    }).then(([result, status]) => [result, status])

  checkOpenSchedulingOnFacilities = (id: string): Promise<any> =>
    http({
      path: `/facilities/${id}/check_open_scheduling_on_facilities.json`
    }).then(facilities => facilities[0])

  dockTypes = (id: string, openScheduling: boolean): Promise<any> =>
    http({
      path: `/facilities/dock_types${
        openScheduling ? '_open_sheduling' : ''
      }.json?appointment_id=${id}`
    }).then(docks => docks)

  availableDays = (facilityId: string, purchaseOrders: { identifier: string }[]): Promise<any> => {
    const params = new URLSearchParams()

    if (purchaseOrders) {
      purchaseOrders?.forEach(po => {
        params.append('purchase_orders[]', po.identifier)
      })
    }

    return http({
      path: `/facilities/${facilityId}/available_days.json?${params.toString()}`
    }).then(days => days)
  }

  searchFacility = async (search?: string) => {
    const [{ hits }, status] = await authenticatedFetch({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'facility',
          params: {
            query: search
          }
        }
      }
    })
    return [200, 304].includes(status) ? hits : []
  }
}

export const facilityService = new FacilityService()
