import styled from 'styled-components'

export const SelectTimeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const SelectTimeIconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  transform: translateY(-5px);
`

export const SelectTimeIcon = styled.div`
  display: inline;
  cursor: pointer;
`
export const ScrollableContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  gap: 8px;
  display: flex;
`
export const ScrollableItem = styled.div`
  display: inline-block;
  width: min-content;
`
