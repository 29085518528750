import React, { useContext } from 'react'
import { BLACKOUT_TYPE } from '../../constants/blackout-type'
import { StatefulTooltip } from 'baseui/tooltip'
import TimeFormatter from 'components/utils/time-formatter'
import { useStyletron } from 'baseui'
import EventIcon from 'components/ui/specific/EventIcon'
import { ArrowLineDown, ArrowLineUp, CaretRight, Door, Prohibit } from '@phosphor-icons/react'
import {
  FIXED_UNASSIGNED_COLUMN,
  useDockAssigmentContext
} from '../../contexts/dock-assigment.context'
import EventTooltip from 'components/ui/specific/EventTooltip'
import { formatTitleForAppointment } from '../../utils/title-formatter'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { useLocation } from 'react-router-dom'

const DEFAULT_DOCK_VALUE = 'N/A'
export interface CalendarEventProps {
  event: {
    title: string
    start: string | Date
    end: string | Date
    allDay: boolean
    _def?: {
      resourceIds?: string[]
    }
    extendedProps: {
      subtitle?: string
      purchaseOrderIdentifiers?: string[]
      equipmentTypeId?: string
      recurringAppointmentBlueprintId?: string
      confirmationId?: string
      showAppointmentId?: boolean
      type?: string
      daysOfWeek?: boolean
      dockName?: string
      schedulerName?: string
      carrierName?: string
      status?: string
      appointmentTypeName?: string
      purchaseOrders?: string
      name?: string
      dockTime?: string
      carrierScac?: string
      identifiers?: Array<{
        prompt: string
        answer: string
      }>
    }
  }
}

const CalendarEvent = ({ event }: CalendarEventProps) => {
  const {
    start,
    allDay,
    title,
    extendedProps: {
      purchaseOrderIdentifiers,
      dockName,
      subtitle,
      appointmentTypeName,
      type,
      name,
      dockTime
    }
  } = event

  let location = useLocation()

  const { currentUser } = useContext(CurrentUserContext)
  const [css] = useStyletron()
  const dockAssignmentContext = useDockAssigmentContext()
  const { dockOptions = [] } = dockAssignmentContext || {}
  const dockId = event._def?.resourceIds && event._def?.resourceIds[0]
  const dock = dockOptions.filter(dock => dock.id === dockId)[0]

  const renderEventContent = () => {
    return (
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'flex-start',
          height: '100%',
          gap: '8px',
          textWrap: 'nowrap',
          flexWrap: 'wrap',
          minHeight: '50px'
        })}>
        <div
          className={css({
            display: 'flex',
            gap: '4px',
            alignItems: 'baseline',
            overflow: 'hidden'
          })}
          data-testid={BLACKOUT_TYPE && `blackout-${name}`}>
          {type !== BLACKOUT_TYPE ? <EventIcon event={event} /> : <Prohibit />}
          <span className="event-title">Coca-Cola</span>
          {location.pathname !== '/shipper' && location.pathname !== '/consignee' ? (
            <span className="event-date">10:30</span>
          ) : (
            ''
          )}
          <CaretRight />
          {event.title ? (
            <span className="event-title" data-testid="event-title">
              {type === BLACKOUT_TYPE ? event.title : formatTitleForAppointment(title)}
            </span>
          ) : null}
          <span className="event-date">
            {!allDay && new TimeFormatter('localCalendarTime').format(start)}
          </span>
        </div>
        {type !== BLACKOUT_TYPE ? (
          <div
            className={css({
              display: 'flex',
              gap: '4px',
              alignItems: 'baseline',
              textWrap: 'nowrap',
              flexWrap: 'wrap',
              overflow: 'hidden'
            })}>
            {type !== BLACKOUT_TYPE && subtitle && (
              <span className="event-subtitle">{subtitle}</span>
            )}
            <span className="event-type">
              {appointmentTypeName === 'Inbound' ? (
                <ArrowLineDown />
              ) : appointmentTypeName === 'Outbound' ? (
                <ArrowLineUp />
              ) : (
                appointmentTypeName
              )}
            </span>
            <span className="event-id">
              <strong>{purchaseOrderIdentifiers?.filter(item => item).join('-')}</strong>
            </span>

            {currentUser?.dockAssignmentActive && (
              <span className="event-dock" data-testid={'event-dock'}>
                <Door size={10} />
                {dockId === FIXED_UNASSIGNED_COLUMN.id
                  ? DEFAULT_DOCK_VALUE
                  : dock?.label || dockName || DEFAULT_DOCK_VALUE}
                {dockId === FIXED_UNASSIGNED_COLUMN.id
                  ? ''
                  : (dockTime as any) !== -1 &&
                    ` - ${new TimeFormatter('localCalendarTime').format(dockTime)}`}
              </span>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  if (type === BLACKOUT_TYPE) return <>{renderEventContent()}</>

  return (
    <StatefulTooltip
      triggerType="hover"
      content={<EventTooltip event={event} />}
      placement="auto"
      overrides={{
        Inner: {
          style: ({ $theme }) => ({
            paddingTop: $theme.sizing.scale600,
            paddingBottom: $theme.sizing.scale600,
            paddingLeft: $theme.sizing.scale600,
            paddingRight: $theme.sizing.scale600,
            backgroundColor: $theme.colors.backgroundPrimary,
            color: $theme.colors.contentPrimary
          })
        }
      }}>
      {renderEventContent()}
    </StatefulTooltip>
  )
}

export default CalendarEvent
