import { Button } from "baseui/button";
import React from "react";
import useSizing from "../../shared/hooks/use-sizing";

const SelectorButton = ({ Icon, onClick, disabled }) => {
  const { isMediumAndUp } = useSizing();
  return (
    <Button
      disabled={disabled}
      overrides={{
        BaseButton: {
          style: {
            height: "300px",
            padding: "0px",
            width: isMediumAndUp ? "40px" : "20px",
          },
        },
      }}
      onClick={onClick}
    >
      <Icon size="40px" />
    </Button>
  );
};
export default SelectorButton;
