import React, { useContext, useState } from 'react'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import TimeRangeToggle from '../../shipper/instant-calendar/toggles/time-range-toggle'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import { Block } from 'baseui/block'
import { HeadingXSmall } from 'baseui/typography'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import CyberSearchRefinementList from '../../../lib/cyber-components/search/refinement-list'
import SchedulerRefinementList from '../../shipper/instant-calendar/scheduler-refinement-list'
import { CurrentUserContext } from '../../homepage/current-user-context'
import DockAssignmentFacilitySelect from './DockAssignmentFacilitySelect'
import DockAssignmentDockSelect from './DockAssignmentDockSelect'
import Button from 'components/ui/specific/PrimaryButton'
import { Plus } from '@phosphor-icons/react'
import { useDockAssigmentContext } from '../../contexts/dock-assigment.context'
import { useCalendarSelectedOptionsContext } from '../../contexts/calendar-selected-options.context'
import { CalendarReferenceContext } from '../../contexts/calendar-reference-context'
import CyberSearchBox, {
  DOCK_ASSIGNMENT_PAGE
} from '../../../lib/cyber-components/search/searchbox'

interface cacheTime {
  minTime: {
    id: string
    label: string
  }[]
  maxTime: {
    id: string
    label: string
  }[]
}

interface DockAssignmentHeaderProp {
  cachedTimeRange: cacheTime
  setCachedTimeRange: (time: cacheTime) => void
  setSelectedEvent: (e: SelectedEvent) => void
  selectedDate: Date
  appliedFilters: any
  setAppliedFilters: (time: cacheTime) => void
}

const DockAssignmentHeader = ({
  cachedTimeRange,
  setCachedTimeRange,
  setSelectedEvent,
  setAppliedFilters,
  appliedFilters
}: DockAssignmentHeaderProp) => {
  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()
  const { selectedFacility } = useDockAssigmentContext()
  const { dateInCalendarFilter } = useContext(CalendarReferenceContext)

  return (
    <Header
      title={t('HeaderNavigation.NavBarLinks.DockAssignment.Text')}
      leftItems={[<DockAssignmentFacilitySelect />, <DockAssignmentDockSelect />]}
      rightItems={[
        <CyberSearchBox
          placeholder={t('DockAssignment.SearchBox.PlaceHolder.Text')}
          collapsable={true}
          cacheKey={DOCK_ASSIGNMENT_PAGE}
        />,
        <FiltersPopover tag={Object.entries(appliedFilters).length}>
          <Block
            backgroundColor="#fff"
            overrides={{
              Block: {
                style: ({ $theme }) => ({
                  maxWidth: '284px',
                  [$theme.mediaQuery.medium]: {
                    left: 'auto',
                    right: 0
                  }
                })
              }
            }}>
            <HeadingXSmall margin="0 0 8px">{t('Common.Button.Filters.Text')}</HeadingXSmall>
            <FlexGrid flexGridRowGap="scale300">
              <FlexGridItem>
                <CyberSearchRefinementList
                  attribute="checkin_status"
                  setAppliedFilters={setAppliedFilters}
                />
              </FlexGridItem>
              <FlexGridItem>
                <SchedulerRefinementList setAppliedFilters={setAppliedFilters} />
              </FlexGridItem>
              <FlexGridItem>
                <CyberSearchRefinementList
                  attribute="created_by_name"
                  setAppliedFilters={setAppliedFilters}
                />
              </FlexGridItem>
              <FlexGridItem>
                <TimeRangeToggle
                  cachedTimeRange={cachedTimeRange}
                  setCachedTimeRange={setCachedTimeRange}
                  currentUser={currentUser}
                  setAppliedFilters={setAppliedFilters}
                />
              </FlexGridItem>
            </FlexGrid>
          </Block>
        </FiltersPopover>,
        <Button
          startEnhancer={<Plus />}
          onClick={() => setSelectedEvent({ arrivalTime: dateInCalendarFilter[0] })}
          disabled={!selectedFacility}>
          {t('Common.Buttons.CreateAppointment')}
        </Button>
      ]}
    />
  )
}

export default DockAssignmentHeader
