import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import React, { useContext, useState } from 'react'
import { trim, isEmpty } from 'lodash'
import AppointmentType from '../../shared/appointment-type'
import authenticatedFetch from '../../utils/authenticated-fetch'
import { fancyToast } from '../../utils'
import PurchaseOrderIdentifiers from './fields/purchase-order-identifiers'
import ErrorMessageButton from '../../shared/error-message-button'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import FacilitySelect from './fields/facility-select'
import OpenSchedulingPOField from 'components/pages/OpenSchedulingCreateAppointment/OpenSchedulingPOField'
import { ValidateOrderError, ValidateOrderResponse } from 'components/services/order'
import { LabelSmall, LabelXSmall } from 'baseui/typography'
import useSizing from '../../shared/hooks/use-sizing'
import EquipmentTypeWithCapacity from '../../components/EquipmentType/EquipmentTypeWithCapacity'
import SchedulerEmail from './fields/scheduler-email'
import { CARRIER, SCHEDULER } from 'components/models/User'
import CarrierSelect from './fields/carrier-select'
import { CarriersProvider } from 'components/contexts/carriers.context'
import SchedulerSelect from './fields/scheduler-select'
import { useTranslation } from 'react-i18next'
import OrdersDetails from '../OrdersDetails'

export const PURCHASE_ORDER_QUERY_PARAM = 'purchase_order_numbers[]='

interface PurchaseOrderStepProps {
  facility: any
  setFacility: Function
  appointment: any
  setAppointment: Function
  setProgress: Function
  setEmail?: Function
  purchaseOrders?: any
  setPurchaseOrders?: any
}

const PurchaseOrderStep = ({
  facility,
  setFacility,
  appointment,
  setAppointment,
  setProgress,
  setEmail,
  purchaseOrders,
  setPurchaseOrders
}: PurchaseOrderStepProps) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [facilityOptions, setFacilityOptions] = useState([])
  // There is no particular reason we are using the Value type here, other than thats how
  // it was built. This originally used a select for purchase orders
  const [orderValidation, setOrderValidation] = React.useState<ValidateOrderResponse>({
    canProceed: true,
    errors: [],
    orders: [],
    totals: {}
  })
  const [scheduler, setScheduler] = useState<any>({})
  const [schedulerEmail, setSchedulerEmail] = useState<any>({
    email: currentUser?.emailAddress,
    displayError: false,
    disabled: currentUser?.emailAddress?.length > 0
  })
  const regularExpression = new RegExp(facility?.purchaseOrderValidator)
  const { customBreakPoints } = useSizing()
  const { t } = useTranslation()

  const checkPurchaseOrderNumber = async purchaseOrders => {
    const purchaseOrderNumberParams = purchaseOrders
      .map(({ identifier }, index) => {
        return `${index === 0 ? '?' : '&'}${PURCHASE_ORDER_QUERY_PARAM}${identifier}`
      })
      .join('')

    return authenticatedFetch({
      path: `/facilities/${facility.id}/check_purchase_order_numbers.json${purchaseOrderNumberParams}`,
      method: 'GET'
    })
  }

  const nextStep = async () => {
    // TODO(adenta) I think I can remove the _destroy stuff here
    const purchaseOrderParams = purchaseOrders
      .map(({ id, identifier, _destroy }) => {
        if (!id && _destroy) {
          return null
        }
        return { identifier }
      })
      .filter(element => element && trim(element.identifier))
    const [json, status] = await checkPurchaseOrderNumber(purchaseOrderParams)

    if (status == 200) {
      setAppointment({
        ...appointment,
        purchaseOrdersAttributes: purchaseOrderParams
      })
      setProgress(2)
      if (setEmail) setEmail(schedulerEmail.email)
    } else {
      setPurchaseOrders([])
      fancyToast(json, status)
    }
  }

  const purchaseOrderFilledOut = () => {
    const purchaseOrdersMustBePresent =
      purchaseOrders.filter(({ identifier }) => !isEmpty(trim(identifier))).length > 0
    const purchaseOrdersMustBeUnique =
      purchaseOrders.length ===
      new Set(purchaseOrders.map(purchaseOrder => purchaseOrder.identifier)).size
    const purchaseOrdersMustBeFormattedCorrectly =
      !purchaseOrdersMustBePresent ||
      purchaseOrders.every(({ identifier }) => {
        return regularExpression.test(identifier) || isEmpty(trim(identifier))
      })

    const trailerTypeMustBeSelected = appointment.equipmentTypeId
    const appoinmentmentTypeMustBeSelected =
      facility?.appointmentTypes?.length > 1 ? appointment.appointmentTypeId : true
    const facilityMustBeSelected = facility.id

    const schedulerEmailMustBeValid =
      schedulerEmail && schedulerEmail?.email?.length > 1 && !schedulerEmail.displayError

    return [
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.SchedulerEmailMustBeValid.Text'
        ),
        status: schedulerEmailMustBeValid
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.PurchaseOrdersMustBePresent.Text'
        ),
        status: purchaseOrdersMustBePresent
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.PurchaseOrdersMustBeUnique.Text'
        ),
        status: purchaseOrdersMustBeUnique
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.PurchaseOrdersMustBeFormattedCorrectly.Text'
        ),
        status: purchaseOrdersMustBeFormattedCorrectly
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.TrailerTypeMustBeSelected.Text'
        ),
        status: trailerTypeMustBeSelected
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.AppoinmentmentTypeMustBeSelected.Text'
        ),
        status: appoinmentmentTypeMustBeSelected
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.FacilityMustBeSelected.Text'
        ),
        status: facilityMustBeSelected
      },
      {
        label: t(
          'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.OrderValidation.Text'
        ),
        status: orderValidation.canProceed
      }
    ]
  }

  const showOrderDetails = () =>
    orderValidation.orders?.length > 0 &&
    (facility?.appointmentPreference?.showQuantityOnAppointmentCreation ||
      facility?.appointmentPreference?.showWeightOnAppointmentCreation ||
      facility?.appointmentPreference?.showDeliveryInstructionsOnAppointmentCreation)

  return (
    <FlexGrid flexGridRowGap="scale200" flexGridColumnCount={1}>
      <FlexGridItem>
        <SchedulerEmail schedulerEmail={schedulerEmail} setSchedulerEmail={setSchedulerEmail} />
      </FlexGridItem>
      <FlexGridItem maxWidth="400px">
        <FacilitySelect
          appointment={appointment}
          setAppointment={setAppointment}
          facility={facility}
          setFacility={setFacility}
          facilityOptions={facilityOptions}
          setFacilityOptions={setFacilityOptions}
        />
      </FlexGridItem>
      <FlexGridItem></FlexGridItem>
      {currentUser?.userType == SCHEDULER && (
        <FlexGridItem maxWidth="400px">
          <CarriersProvider>
            <CarrierSelect
              schedulerId={currentUser.schedulerId}
              appointment={appointment}
              setAppointment={setAppointment}
              createdAsOther={true}
            />
          </CarriersProvider>
        </FlexGridItem>
      )}
      {currentUser?.userType == CARRIER && (
        <FlexGridItem maxWidth="400px">
          <SchedulerSelect
            scheduler={scheduler}
            facility={facility}
            setScheduler={setScheduler}
            appointment={appointment}
            setAppointment={setAppointment}
          />
        </FlexGridItem>
      )}
      <FlexGridItem maxWidth={customBreakPoints.mdMax ? '400px' : ''}>
        {currentUser
          ? facility &&
            facility.appointmentPreference && (
              <PurchaseOrderIdentifiers
                purchaseOrders={purchaseOrders}
                setPurchaseOrders={setPurchaseOrders}
                regularExpression={regularExpression}
                facilityId={facility.id}
                setOrderValidation={setOrderValidation}
                orderValidation={orderValidation}
                schedulerId={
                  currentUser.userType === CARRIER
                    ? appointment.schedulerId
                    : currentUser.schedulerId
                }
              />
            )
          : facility &&
            facility?.appointmentPreference && (
              <OpenSchedulingPOField
                purchaseOrders={purchaseOrders}
                setPurchaseOrders={setPurchaseOrders}
                regularExpression={regularExpression}
                facilityId={facility.id}
                setOrderValidation={setOrderValidation}
                orderValidation={orderValidation}
                labels={facility?.appointmentPreference?.purchaseOrderLabels}
              />
            )}
      </FlexGridItem>
      {showOrderDetails() && (
        <OrdersDetails
          orders={orderValidation.orders}
          totals={orderValidation.totals}
          appointmentPreference={facility?.appointmentPreference}
        />
      )}
      {orderValidation.errors?.length > 0 && (
        <FlexGridItem display="flex" alignItems="start" justifyContent="start">
          <div style={{ margin: '-8px 0px 20px 0px' }}>
            <LabelSmall color="red">
              {t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Validations.Header.Text')}
            </LabelSmall>
            <ul style={{ marginTop: '5px', paddingLeft: '25px' }}>
              {orderValidation.errors?.map((error: ValidateOrderError, i: number) => (
                <li color="red">
                  <LabelXSmall color="red" key={error.index + i}>
                    {error.message}
                  </LabelXSmall>
                </li>
              ))}
            </ul>
          </div>
        </FlexGridItem>
      )}
      {facility && facility.appointmentTypes && facility.appointmentTypes.length > 1 && (
        <FlexGridItem>
          <AppointmentType
            label={t(
              'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.AppointmentType.Text'
            )}
            appointmentTypes={facility.appointmentTypes}
            onClick={(event, index) => {
              setAppointment({
                ...appointment,
                appointmentTypeId: facility?.appointmentTypes[index]?.id,
                appointmentType: facility?.appointmentTypes[index]
              })
            }}
          />
        </FlexGridItem>
      )}
      {appointment?.appointmentTypeId &&
        facility?.appointmentPreference &&
        facility?.appointmentPreference?.dockCapacitiesAttributes?.length > 0 && (
          <FlexGridItem>
            <EquipmentTypeWithCapacity
              record={appointment}
              setRecord={setAppointment}
              dockCapacitiesAttributes={facility?.appointmentPreference?.dockCapacitiesAttributes}
              equipmentCapacityShared={facility?.appointmentPreference?.equipmentCapacityShared}
              label={t(
                'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.EquipmentType.Text'
              )}
              recordAttributeName="equipmentTypeId"
            />
          </FlexGridItem>
        )}
      {facilityOptions.length > 0 && (
        <FlexGridItem>
          <ErrorMessageButton
            errors={purchaseOrderFilledOut()}
            buttonProps={{ onClick: nextStep }}
            label="Next"
          />
        </FlexGridItem>
      )}
    </FlexGrid>
  )
}

export default PurchaseOrderStep
