import { StyledBody } from 'baseui/card'
import React from 'react'
import { ParagraphSmall } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { CardContainer } from './DriversSearchResult.styled'

const DriversSearchResult = ({
  appointment,
  pickAppointment
}: {
  appointment: any
  pickAppointment: (any) => void
}): JSX.Element => {
  const [, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <CardContainer>
      <StyledBody>
        <ParagraphSmall margin="5px">
          {appointment.facilityName} ({appointment.shipperName})
        </ParagraphSmall>
        <ParagraphSmall margin="5px">
          {new Date(appointment.arrivalTime).toLocaleString()}
        </ParagraphSmall>
        <ParagraphSmall margin="5px">
          {appointment.purchaseOrderLabel}:
          {appointment.purchaseOrderIdentifiers.split(',').map((p, i, arr) => (
            <span key={p}>
              {` ${p}`}
              {i !== arr.length - 1 && ','}
            </span>
          ))}
        </ParagraphSmall>
        <Button
          size="compact"
          colors={
            appointment.status !== 'scheduled' && {
              backgroundColor: theme.colors.positive,
              color: 'white'
            }
          }
          overrides={{
            BaseButton: { style: { width: '100%' } }
          }}
          onClick={() => pickAppointment(appointment)}>
          {appointment.status === 'scheduled'
            ? t('DriversCheckin.Result.Scheduled')
            : t('DriversCheckin.Result.Other')}
        </Button>
      </StyledBody>
    </CardContainer>
  )
}

export default DriversSearchResult
