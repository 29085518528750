import React, { useState, useEffect, useContext } from 'react'

import { CurrentUserContext } from 'components/homepage/current-user-context'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { ParagraphSmall } from 'baseui/typography'
import { CARRIER } from 'components/models/User'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../../constants/default-shipper-subdomain'

interface FacilitySelectProps {
  facility: any
  setFacility: Function
  appointment: any
  setAppointment: Function
  facilityOptions: any[]
  setFacilityOptions: Function
}

const FacilitySelect = (props: FacilitySelectProps) => {
  const {
    facility,
    setFacility,
    setAppointment,
    appointment,
    facilityOptions,
    setFacilityOptions
  } = props
  const { currentUser } = useContext(CurrentUserContext)
  const [facilityRequestDone, setFacilityRequestDone] = useState(false)
  const { t } = useTranslation()
  const loadFacilities = async params => {
    const [json, status] = await authenticatedFetch({
      path: currentUser
        ? `/facilities.json/?id[]=${params.option.id}`
        : `/facilities/${params.option.id}/open_scheduling_facility.json`
    })

    if ([200, 304].includes(status)) {
      setFacility({ ...facility, ...json[0] })
      setAppointment({
        ...appointment,
        appointmentTypeId: json[0].appointmentType?.id,
        appointmentType: json[0].appointmentType
      })
    }
  }

  useEffect(() => {
    const facilitiesUrl = getFacilitiesUrl()
    if (facilitiesUrl) {
      authenticatedFetch({
        path: facilitiesUrl
      })
        .then(([facilityLabels, _status]) => {
          setFacilityOptions(facilityLabels)
          if (facilityLabels?.length === 1) {
            loadFacilities({ option: facilityLabels[0] })
          }
        })
        .catch(console.log)
    }
  }, [currentUser])

  const getFacilitiesUrl = () => {
    if (currentUser?.shipperId) {
      return `/shippers/${currentUser.shipperId}/facilities_list.json`
    }
    if (currentUser?.userType == CARRIER) {
      return `/users/${currentUser.id}/facilities_list_carrier_user.json`
    }
    return null
  }

  useEffect(() => {
    const subdomain = window.location.hostname.split('.')[0]

    if (!currentUser) {
      authenticatedFetch({
        path: `/facilities/open_scheduling_list.json?subdomain=${
          subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain
        }`
      })
        .then(([facilityLabels, _status]) => {
          if (facilityLabels?.length === 1) {
            loadFacilities({ option: facilityLabels[0] })
          }

          setFacilityOptions(facilityLabels)
          setFacilityRequestDone(true)
        })
        .catch(console.log)
    }
  }, [])

  if (facilityOptions.length === 0 && facilityRequestDone) {
    return (
      <ParagraphSmall color="red">
        {t(
          'Scheduler.OpenScheduling.Steps.Fields.Facility.Validations.NoFacilitiesAllowedWithOpenSchedulling.Text'
        )}
      </ParagraphSmall>
    )
  }

  if (facilityOptions.length === 0) {
    return <></>
  }

  return (
    <FormControl label={t('Scheduler.OpenScheduling.Steps.Fields.Facility.Label.Text')}>
      <Select
        options={facilityOptions}
        value={[facility]}
        searchable={false}
        clearable={false}
        onChange={async params => loadFacilities(params)}
      />
    </FormControl>
  )
}

export default FacilitySelect
