import React, { useContext, useEffect, useState } from 'react'
import { useStyletron } from 'baseui'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import TwilioPhoneNumber from 'components/shared/fields/twilio-phone-number'
import { SelectSearch } from 'components/components/SelectSearch'
import { toast } from 'components/utils/toast'
import { inviteService } from 'components/services/invite.service'
import { SHIPPER } from 'components/constants/user-types'
import { useUserRolesContext } from '../../contexts/user-roles.context'
import { INTERNAL } from '../../models/Role'
import Select from 'components/ui/generic/Select'
import Button from 'components/ui/generic/Button'
import Checkbox from 'components/ui/generic/Checkbox'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

export const InviteShipperModal = ({ isOpen, close }) => {
  const [css] = useStyletron()
  const { currentUser } = useContext(CurrentUserContext)
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedInternalUserRole, setSelectedInternalUserRole] = useState<any>([])
  const [internalUsersRoles, setInternalUsersRoles] = useState<any>([])
  const { t } = useTranslation()

  const {
    roles: { items },
    actions: { search }
  } = useUserRolesContext()

  useEffect(() => {
    search(undefined, INTERNAL)
  }, [])

  useEffect(() => {
    if (items && items.length) {
      setInternalUsersRoles(
        items.map(item => {
          return { label: item.name, id: item.id }
        })
      )
    }
  }, [items])

  const onClose = () => {
    close()
    setUser({})
    setSelectedInternalUserRole([])
  }

  const inviteUser = async () => {
    setLoading(true)
    await inviteService.inviteUser(
      {
        ...user,
        userType: SHIPPER
      },
      currentUser.shipperId,
      onClose
    )
    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{t('Settings.InternalUsers.Modal.Header.Text')}</ModalHeader>
      <ModalBody>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.Name.Label.Text')}>
          <Input
            autoComplete="off"
            name="user[name]"
            value={user.name}
            onChange={evt => {
              setUser({
                ...user,
                name: evt.currentTarget.value
              })
            }}
          />
        </FormControl>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.EmailAddress.Label.Text')}>
          <Input
            autoComplete="off"
            name="user[email_address]"
            value={user.emailAddress}
            onChange={evt => {
              setUser({
                ...user,
                emailAddress: evt.currentTarget.value
              })
            }}
          />
        </FormControl>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.PhoneNumber.Label.Text')}>
          <TwilioPhoneNumber record={user} setRecord={setUser} />
        </FormControl>
        <FormControl label={t('Settings.InternalUsers.Modal.Fields.Facility.Label.Text')}>
          <SelectSearch
            multi
            record={user}
            placeholder={t('Settings.InternalUsers.Modal.Fields.Facility.PlaceHolder.Text')}
            onValuesChange={ids =>
              setUser(user => ({
                ...user,
                facilities: ids
              }))
            }
            entity="facility"
          />
        </FormControl>
        <FormControl label="Role">
          <Select
            clearable={false}
            backspaceClearsInputValue
            maxDropdownHeight="250px"
            deleteRemoves={true}
            value={selectedInternalUserRole}
            placeholder={t('Settings.InternalUsers.Modal.Fields.Role.PlaceHolder.Text')}
            options={internalUsersRoles}
            onChange={({ option }) => {
              setUser({ ...user, userRoleId: option.id })
              setSelectedInternalUserRole([option])
            }}
          />
        </FormControl>
        <Checkbox
          checked={user.admin}
          onChange={e => {
            setUser({ ...user, admin: e.currentTarget.checked })
          }}
          label={t('Settings.InternalUsers.Modal.AdminCheckBox.Label.Text')}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!user.emailAddress || !user.name || user.facilities?.length === 0}
          isLoading={loading}
          onClick={inviteUser}>
          {t('Settings.InternalUsers.Modal.InviteButton.Text')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
