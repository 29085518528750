import { BlockProps } from 'baseui/block'
import { FlexGridItem } from 'baseui/flex-grid'
import React, { useEffect, useState } from 'react'
import ParentOption from './parentOption'

interface FilterFamiliarOption {
  isActive: boolean
  value: string
  label: string
}
interface FiltersFamiliar {
  attr: string
  items: Array<FilterFamiliarOption>
}

const FiltersPanel = ({ filtersList, getQueryParams, setFiltersList }) => {
  const [list, setList] = useState<Array<FiltersFamiliar>>(filtersList)

  useEffect(() => {
    reloadQuery(filtersList)
    setList(filtersList)
  }, [filtersList])

  const reloadQuery = filterList => {
    let queryObject = {}
    filterList.map(listItem => {
      listItem.items.map(item => {
        if (item.isActive) {
          queryObject[listItem.attr] = !queryObject[listItem.attr]
            ? `?${listItem.attr}[]=${item.value}`
            : `${queryObject[listItem.attr]}&${listItem.attr}[]=${item.value}`
        }
      })
    })

    let filtersQuery = ''
    Object.keys(queryObject).forEach(key => {
      filtersQuery += queryObject[key].replace('?', '&')
    })

    getQueryParams(filtersQuery)
  }

  const updateFiltersItem = (currentList, selectedItem, family) => {
    const newList = list.map(listItem => {
      if (listItem.attr === family) {
        const updatedItem = listItem.items.map(item => {
          if (item.label === selectedItem.label) {
            return { ...item, isActive: !selectedItem.isActive }
          }
          return item
        })
        return { ...listItem, items: updatedItem }
      }
      return listItem
    })

    reloadQuery(newList)
    setList(newList)
    setFiltersList(newList)
  }

  const itemProps: BlockProps = {
    paddingTop: '0.5rem'
  }

  return (
    <FlexGridItem>
      {list.map(filter => (
        <FlexGridItem {...itemProps}>
          <ParentOption
            attribute={filter.attr}
            items={filter.items}
            reloadList={(targetList, targetItem, family) =>
              updateFiltersItem(targetList, targetItem, family)
            }
          />
        </FlexGridItem>
      ))}
    </FlexGridItem>
  )
}

export default FiltersPanel
