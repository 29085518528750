import React, { Dispatch } from 'react'

interface FacilitiesContextProps {
  facilities: any
  slotDuration: string
  selectedDate: Date
  setSelectedDate: Dispatch<Date>
}

export const FacilitiesContext = React.createContext<FacilitiesContextProps>({
  facilities: {},
  slotDuration: '00:30',
  selectedDate: new Date(),
  setSelectedDate: () => ''
})
