import React, { useEffect } from "react";

const InactiveTimer = ({ onTimer, timeoutSeconds }) => {
  let logoutTimeout;

  const setTimeouts = () => {
    logoutTimeout = setTimeout(onTimer, timeoutSeconds * 1000);
  };

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return <></>;
};
export default InactiveTimer;
