import { toaster } from 'baseui/toast'
import { driversCheckinReducer, initialState } from 'components/pages/DriversCheckin/reducer'
import {
  setIsAnswerListUpdated,
  setIsCheckedIn,
  setLoading,
  setMatches,
  setPickedAppointment,
  setShowQuestionList,
  updateSearchTerm
} from 'components/pages/DriversCheckin/reducer/actions'
import { driversCheckinService } from 'components/services'
import React, { createContext, useContext, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

export const DriversCheckinContext = createContext({} as any)

export const DriversCheckinProvider = ({ children }) => {
  const [
    {
      searchTerm,
      loading,
      pickedAppointment,
      matches,
      isCheckedIn,
      showQuestionList,
      isAnswerListUpdated,
      isFormDisabled
    },
    dispatch
  ] = useReducer(driversCheckinReducer, initialState)
  const { t } = useTranslation()

  const getResults = async () => {
    if (searchTerm) {
      dispatch(setMatches([]))
      dispatch(setLoading(true))
      dispatch(setPickedAppointment(null))
      const searchResults = await driversCheckinService.getResults(searchTerm)
      dispatch(setLoading(false))
      dispatch(setMatches(searchResults))
    } else {
      toaster.warning(t('DriversCheckin.Form.NoTerm'), {})
    }
  }

  const resetForm = () => {
    dispatch(updateSearchTerm(''))
    dispatch(setMatches([]))
    dispatch(setPickedAppointment(null))
    dispatch(setIsCheckedIn(false))
    dispatch(setLoading(false))
    dispatch(setShowQuestionList(false))
    dispatch(setIsAnswerListUpdated(false))
  }

  const confirmAppointment = async () => {
    dispatch(setLoading(true))
    const [, status] = await driversCheckinService.checkinAppointment(pickedAppointment)

    if (status === 201) {
      dispatch(setIsCheckedIn(true))
      dispatch(setLoading(false))
    }
  }

  return (
    <DriversCheckinContext.Provider
      value={{
        searchTerm,
        loading,
        pickedAppointment,
        matches,
        isCheckedIn,
        showQuestionList,
        isAnswerListUpdated,
        getResults,
        resetForm,
        confirmAppointment,
        isFormDisabled,
        dispatch
      }}>
      {children}
    </DriversCheckinContext.Provider>
  )
}

export const useDriversCheckinContext = () => useContext(DriversCheckinContext)
