import React, { useContext } from 'react'
import { useUserRolesContext } from '../../../components/contexts/user-roles.context'
import { CyberTable } from '../../../lib/cyber-components/table/index'
import { PencilSimple } from '@phosphor-icons/react'
import { MdEdit } from 'react-icons/md'
import CreateUserRoleModal from './UserRoleModal'
import TablePill from './TablePill'
import StyledSpinner from 'components/shared/styled-spinner'
import { capitalize } from 'lodash'
import { SpinnerWrapper } from './UserRolesTable.styled'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/generic/Button'

const customStyles = { paddingTop: '0' }

export const UserRolesTable = () => {
  const {
    roles: { items, loading, isModalActive, roleToUpdate },
    actions: { setModalState, setModalType, selectedRole, setRoleToUpdate, updateRole }
  } = useUserRolesContext()

  const { currentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation()
  const COLUMNS = [
    t('Settings.UserRoles.Table.Columns.RoleName.Label.Text'),
    t('Settings.UserRoles.Table.Columns.Audience.Label.Text'),
    t('Settings.UserRoles.Table.Columns.Status.Label.Text'),
    t('Settings.UserRoles.Table.Columns.Action.Label.Text')
  ]

  const setUserRoleToEdit = role => {
    setModalType(t('Settings.UserRoles.Modal.Header.Alternatives.Edit.Text'))
    selectedRole(role)
  }

  return (
    <>
      {loading ? (
        <SpinnerWrapper>
          <StyledSpinner />
        </SpinnerWrapper>
      ) : (
        <>
          <CyberTable
            columns={COLUMNS}
            data={
              Array.isArray(items)
                ? items.map((element: any) => [
                    <div style={customStyles}>
                      {!element?.shipperId ? capitalize(element.name) : element.name}
                    </div>,
                    <div style={customStyles}>{capitalize(element.audience)}</div>,
                    <TablePill enabled={element.enabled} shipperId={element.shipperId} />,
                    element?.shipperId && currentUser?.admin && (
                      <Button
                        data-testid="edit-icon-user-roles"
                        onClick={() => setUserRoleToEdit(element)}
                        kind="tertiary"
                        shape="circle">
                        <PencilSimple
                          style={{
                            cursor: 'pointer'
                          }}
                        />
                      </Button>
                    )
                  ])
                : []
            }
          />
          <CreateUserRoleModal
            isOpen={isModalActive}
            close={() => setModalState()}
            loading={loading}
          />
          <ConfirmationModal
            isOpen={!!roleToUpdate}
            loading={loading}
            title={t('Settings.UserRoles.Table.ConfirmationModal.Message.Text')}
            cancelAction={() => setRoleToUpdate(undefined, true)}
            confirmAction={() => updateRole(roleToUpdate)}
          />
        </>
      )}
    </>
  )
}
