import React, { useContext, useRef } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import html2canvas from 'html2canvas'
import { HeadingSmall, HeadingXSmall } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter } from 'baseui/modal'
import Button from 'components/ui/specific/PrimaryButton'
import SecondaryButton from 'components/ui/specific/SecondaryButton'
import { CurrentUserContext } from '../homepage/current-user-context'
import {
  QrCodeBody,
  QrCodeHeader,
  QrCodeHeaderImage,
  QrCodeModalBody,
  QrCodeModalBodyContainer,
  QrCodeTextAlignCenter,
  SubtitleStyle
} from './FacilityForm/FacilityForm.styled'
import { jsPDF } from 'jspdf'
import { Block } from 'baseui/block'

const SCALE_FOR_QR_CODE_SIZE = 2
const PDF_WIDTH = 2480
const PDF_HEIGHT = 3508

const VPassQRCodeGeneratorModal = ({ open, onClose }) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const qrRef = useRef()
  const { currentUser } = useContext(CurrentUserContext)
  const passURL = `https://${currentUser?.shipperSubdomain}.velostics.com/pass`

  const downloadQRCode = () => {
    html2canvas(qrRef.current, { useCORS: true, allowTaint: true, scale: SCALE_FOR_QR_CODE_SIZE })
      .then(canvas => {
        const dataUrl = canvas.toDataURL('image/png')
        const pdf = new jsPDF('portrait', 'px', [PDF_WIDTH, PDF_HEIGHT])

        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight)
        pdf.save(`${currentUser?.shipperSubdomain}-PassQrCode.pdf`)
      })
      .catch(error => {
        console.error('Error generating QR code image:', error)
      })
  }

  const downloadQRCodeAsSVG = () => {
    html2canvas(qrRef.current, { useCORS: true, allowTaint: true, scale: SCALE_FOR_QR_CODE_SIZE })
      .then(canvas => {
        const pngDataUrl = canvas.toDataURL('image/png')
        const svgString = `
          <svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}">
            <image href="${pngDataUrl}" x="0" y="0" width="${canvas.width}" height="${canvas.height}" />
          </svg>
        `
        const blob = new Blob([svgString], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = `${currentUser?.shipperSubdomain}-PassQrCode.svg`
        link.click()

        URL.revokeObjectURL(url)
      })
      .catch(error => {
        console.error('Error generating QR code image:', error)
      })
  }
  return (
    <Modal isOpen={open} onClose={() => onClose(false)} closeable={true}>
      <ModalBody>
        <QrCodeModalBody>
          <QrCodeModalBodyContainer ref={qrRef}>
            <QrCodeHeader>
              <QrCodeHeaderImage
                alt="velostics-logo"
                src="https://storage.googleapis.com/velostics-public/velostics-logo.svg"
              />
              <HeadingSmall
                margin={0}
                color="none"
                className={css({ color: `${theme.colors.white}` })}>
                {t('DriversCheckin.Header.Title')}
              </HeadingSmall>
            </QrCodeHeader>
            <QrCodeTextAlignCenter>
              <QrCodeBody>
                <HeadingXSmall className={css(SubtitleStyle)}>
                  {currentUser?.shipperName}
                </HeadingXSmall>
                <QRCodeSVG value={passURL} size={256} />
              </QrCodeBody>
            </QrCodeTextAlignCenter>
          </QrCodeModalBodyContainer>
        </QrCodeModalBody>
      </ModalBody>
      <ModalFooter>
        <Block display="flex" alignItems="center" justifyContent="space-around">
          <SecondaryButton onClick={downloadQRCodeAsSVG}>
            {t('Facilities.QrCode.DownloadButtonSVG.Text')}
          </SecondaryButton>
          <Button onClick={downloadQRCode}>{t('Facilities.QrCode.DownloadButtonPDF.Text')}</Button>
        </Block>
      </ModalFooter>
    </Modal>
  )
}

export default VPassQRCodeGeneratorModal
