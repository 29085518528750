import { CustomLabels, CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { RefreshSearchContext } from 'cyber/search/search'
import React, { useContext, useEffect, useState } from 'react'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import { FacilityOption } from '../../shipper/instant-calendar/hooks/use-default-facilities'
import EditAppointmentModal from '../../shipper/instant-calendar/modals/edit-appointment-modal'
import CreateAppointmentModal from '../../shipper/instant-calendar/modals/create-appointment-modal'
import { CarriersProvider } from '../../contexts/carriers.context'

interface ModalsProps {
  selectedEvent: SelectedEvent
  setSelectedEvent: (SelectedEvent) => void
  selectedFacilities?: FacilityOption[]
  mode?: string
}

const DefaultComponent = (props: any) => <div style={{ display: 'none' }}></div>

const DockAssignmentModals = (props: ModalsProps) => {
  const { customLabels, setCustomLabels } = useContext(CustomLabelsContext)
  const { refresh } = useContext(RefreshSearchContext)
  const { selectedEvent, setSelectedEvent, selectedFacilities, mode } = props

  const [initialCustomLabels, setInitialCustomLabels] = useState<CustomLabels>(customLabels)

  useEffect(() => {
    setInitialCustomLabels(customLabels)
  }, [selectedEvent])

  if (!selectedEvent) {
    return <></>
  }

  let Modal = DefaultComponent

  if (mode === 'editAppointment' || (selectedEvent.id && !selectedEvent.blackout)) {
    Modal = EditAppointmentModal
  } else if (!selectedEvent.blackout) {
    Modal = CreateAppointmentModal as any
  }

  return (
    <CustomQuestionsProvider>
      <CarriersProvider>
        <Modal
          selectedEvent={selectedEvent}
          isOpen={!!selectedEvent}
          close={() => {
            setSelectedEvent(null)
            refresh()
            setCustomLabels(initialCustomLabels)
          }}
          selectedFacilities={selectedFacilities}
          unstable_ModalBackdropScroll
        />
      </CarriersProvider>
    </CustomQuestionsProvider>
  )
}

export default DockAssignmentModals
