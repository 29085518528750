import React, { useState, useEffect } from 'react'

import CreateOrUpdateVendorModal from 'components/schedulers/SchedulerInstantSearch/create-modal'
import { schedulerService } from 'components/services'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import Select from 'components/ui/generic/Select'
import Button from 'components/ui/generic/Button'
import { useStyletron } from 'baseui'

interface FacilitySelectProps {
  facility: any
  scheduler: any
  setScheduler: Function
  appointment: any
  setAppointment: Function
  disabled?: boolean
}

const SchedulerSelect = (props: FacilitySelectProps) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const schedulerId = queryParams.get('schedulerId')
  const { facility, scheduler, setScheduler, setAppointment, appointment, disabled = false } = props
  const [schedulersOptions, setSchedulersOptions] = useState([])
  const [createVendorModalOpen, setCreateVendorModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    loadSchedulers()
  }, [facility])

  const loadSchedulers = async () => {
    if (facility.shipperId) {
      schedulerService
        .schedulersByShippers(facility.shipperId)
        .then(([schedulerLabels, _status]) => {
          setSchedulersOptions(schedulerLabels)
          if (schedulerId) {
            const scheduler = schedulerLabels.filter(sched => sched.id === schedulerId)[0]
            setScheduler(scheduler || {})
            setAppointment({
              ...appointment,
              schedulerId: scheduler?.id
            })
          }
          if (!scheduler?.id && appointment.schedulerId && schedulerLabels.length > 0) {
            const loadScheduler = schedulerLabels.filter(
              sched => sched.id === appointment.schedulerId
            )
            loadScheduler.length > 0 ? setScheduler(loadScheduler[0]) : null
          }
        })
        .catch(console.log)
    }
  }

  const setSelectedScheduler = scheduler => {
    setScheduler(scheduler)
    setAppointment({
      ...appointment,
      schedulerId: scheduler?.id
    })
  }

  const [css, theme] = useStyletron()

  return (
    <>
      <div className={css({ position: 'relative', marginBottom: theme.sizing.scale600 })}>
        <FormControl label={t('Scheduler.OpenScheduling.Steps.Fields.Vendor.Label.Text')}>
          <Select
            options={schedulersOptions}
            value={[scheduler]}
            searchable={true}
            clearable={false}
            onChange={async params => {
              setSelectedScheduler(params.option)
            }}
            disabled={disabled}
          />
        </FormControl>
        {facility.shipperId && (
          <Button
            onClick={() => {
              setCreateVendorModalOpen(true)
            }}>
            {t('Scheduler.OpenScheduling.Steps.Fields.Vendor.Link.Text')}
          </Button>
        )}
      </div>
      <CreateOrUpdateVendorModal
        createdAsOther
        isOpen={createVendorModalOpen}
        close={() => setCreateVendorModalOpen(false)}
        shipperId={facility.shipperId}
        getVendor={vendor => {
          setSelectedScheduler({
            id: vendor?.id,
            label: vendor?.name
          })
          loadSchedulers()
        }}
      />
    </>
  )
}

export default SchedulerSelect
