import React, { useContext, useState } from 'react'

import Input from 'components/ui/generic/Input'
import { Search } from 'baseui/icon'
import authenticatedFetch from '../../utils/authenticated-fetch'
import {
  OpenSchedulingSearchBarButton,
  OpenSchedulingSearchBarField
} from './OpenSchedulingUpdateAppointment.styled'
import { useHistory } from 'react-router-dom'
import { toast } from 'components/utils/toast'
import ErrorMessageButton from 'components/shared/error-message-button'
import { PurchaseOrderLabelsContext } from 'components/facilities/facility-book'
import { MagnifyingGlass } from '@phosphor-icons/react'

const OpenSchedulingUpdateSearchBar = ({ setAppointments, recaptchaComplete }) => {
  const [value, setValue] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const purchaseOrderLabelsContext = useContext(PurchaseOrderLabelsContext)
  const { purchaseOrderLabels } = purchaseOrderLabelsContext

  const formErrors = () => [
    {
      label: 'Resolve Captcha to proceed',
      status: !!recaptchaComplete
    }
  ]

  const search = async () => {
    setLoading(true)

    const [json, status] = await authenticatedFetch({
      path: `/appointments/get_for_open_scheduling.json?limit=10&${
        value ? `identifier=${value}` : ''
      }`
    })

    if ([200, 204].includes(status)) {
      if (json.length === 0) {
        toast({ info: 'No Match Found. Please try again with a different PO number' }, 500)
      } else if (json.length === 1) {
        history.push(`/openscheduling/${json[0].id}/schedule`)
      } else {
        setAppointments(json)
      }
    }

    setLoading(false)
  }

  return (
    <OpenSchedulingSearchBarField>
      <Input
        autoComplete="off"
        name="purchase_order[identifier]"
        value={value}
        onChange={e => {
          setValue(e.currentTarget.value)
        }}
        placeholder={
          purchaseOrderLabels && purchaseOrderLabels?.purchase_order_identifiers
            ? purchaseOrderLabels?.purchase_order_identifiers
            : 'Unique Load Identifer. ' +
              'Enter the number completely, partial search is not supported.'
        }
        minWidth={'320px'}
      />
      <OpenSchedulingSearchBarButton>
        <ErrorMessageButton
          errors={formErrors()}
          buttonProps={{
            onClick: search,
            isLoading: loading,
            startEnhancer: MagnifyingGlass
          }}
          statefulTooltipProps={{ placement: 'left' }}
          label="Search"
        />{' '}
      </OpenSchedulingSearchBarButton>
    </OpenSchedulingSearchBarField>
  )
}
export default OpenSchedulingUpdateSearchBar
