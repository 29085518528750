import { Card, StyledAction, StyledBody } from "baseui/card";
import React from "react";
import { useStyletron } from "baseui";
import TimeFormatter from "../../utils/time-formatter";
import { truncate } from "../../shared/css-mixins";

const AppointmentCard = ({ selected, appointment }) => {
  const [css, theme] = useStyletron();

  const {
    arrivalTime,
    purchaseOrderIdentifiers,
    createdByOrganizationName,
    timeZone,
  } = appointment;
  const formattedArrivalTime = new TimeFormatter("shortTime").format(
    arrivalTime,
    timeZone
  );

  return (
    <Card
      title={formattedArrivalTime}
      overrides={{
        Title: {
          style: {
            ...truncate,
            fontSize: "18px",
          },
        },
        Root: {
          style: ({ $theme }) => {
            return {
              userSelect: "none",
              boxSizing: "border-box",
              height: "100%",
              border: selected ? `solid 2px ${$theme.colors.accent}` : "",
            };
          },
        },
      }}
    >
      <div className={css({ ...truncate })}>{createdByOrganizationName}</div>
      <hr />
      <div className={css({ ...truncate })}>{purchaseOrderIdentifiers}</div>
    </Card>
  );
};

export default AppointmentCard;
