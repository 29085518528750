import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { ParagraphSmall } from 'baseui/typography'
import { Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import React, { useEffect, useState } from 'react'
import Button from 'components/ui/specific/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { DEFAULT_SCHEDULER_PERMISSION } from '../../../constants/default-permissions'
import { DOCUMENT_TYPE, TEXT_TYPE } from '../../../models/Question'
import QuestionsList from './QuestionsList'

interface AdditionalInformationProps {
  appointment?: any
  setAppointment?: Function
  afterSubmit: () => void
  appointmentType: any
  userType: string
  finishButtonLabel?: string
  loading?: boolean
  onSaveTemporalFiles?: boolean
  setPreparedDataToUpload?: any
  checkFileUploaderPresence?: any
}

const AdditionalInformation = (props: AdditionalInformationProps) => {
  const {
    appointment,
    setAppointment,
    appointmentType,
    afterSubmit,
    finishButtonLabel,
    loading,
    onSaveTemporalFiles,
    setPreparedDataToUpload,
    userType
  } = props

  const {
    temporalFiles,
    answers,
    actions: { setQuestions, setAnswers, updateFileUploadState }
  } = useCQStateValue()

  const [mustSubmit, setMustSubmit] = useState<boolean>(false)

  const { t } = useTranslation()

  const questions =
    (appointmentType &&
      appointmentType.questions &&
      appointmentType.questions.filter(question => {
        const { equipmentTypeIds } = question
        if (question.disabled) {
          return false
        }
        if (!equipmentTypeIds?.includes(appointment.equipmentTypeId)) {
          return false
        }
        return true
      })) ||
    []

  const checkQuestionError = question => {
    const permission = question.questionPermissionsAttributes[0]
    return permission ? permission?.required : true
  }

  useEffect(() => {
    setQuestions(questions)
    setAnswers(
      questions.map((question: Question) => ({
        response: '',
        questionId: question.id,
        error: checkQuestionError(question)
      }))
    )
  }, [])

  useEffect(() => {
    const cleanedTemporalFiles = temporalFiles.filter(tf => {
      if (tf && Object.keys(tf).length !== 0) {
        return { ...tf }
      }
    })
    if (
      mustSubmit &&
      cleanedTemporalFiles.length &&
      cleanedTemporalFiles.every(tf => tf?.uploadState === 'saved')
    ) {
      setMustSubmit(false)
      afterSubmit()
    }
  }, [temporalFiles, mustSubmit])

  const isDisabled = () => {
    const promptedQuestionsIndexes = questions
      .map((question, index) => {
        const permission =
          question?.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION

        if (!question.disabled && permission.canEdit && permission.showOnCreate) {
          return index
        }
      })
      .filter(result => Number.isInteger(result))

    return (
      answers.filter((_, index) => promptedQuestionsIndexes.includes(index)).some(e => e.error) ||
      !questions.every(question => {
        const permission = question.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION

        if (!permission || permission?.required) {
          if (question.answerType === DOCUMENT_TYPE) {
            const answerTemporalFiles = temporalFiles.filter(tf => tf?.questionId === question?.id)

            if (answerTemporalFiles.length === 0) {
              return false
            }

            return answerTemporalFiles.every(tf => {
              if (tf.files) {
                return tf.files.length > 0
              }
              return false
            })
          }
        }
        return true
      })
    )
  }

  return (
    <FlexGrid flexGridRowGap="scale200">
      {questions && questions.length > 0 ? (
        <QuestionsList
          {...{
            appointment,
            setAppointment,
            questions,
            onSaveTemporalFiles,
            setPreparedDataToUpload,
            userType
          }}
        />
      ) : (
        <FlexGridItem>
          <ParagraphSmall data-testid="no-questions-msg">
            {t('Scheduler.OpenScheduling.Steps.AdditionalInformation.Header.Text')}
          </ParagraphSmall>
        </FlexGridItem>
      )}
      <FlexGridItem>
        <Button
          data-testid="next-btn"
          isLoading={loading}
          disabled={isDisabled()}
          onClick={() => {
            setMustSubmit(true)
            if (temporalFiles.length) {
              return updateFileUploadState(true)
            }
            afterSubmit()
          }}>
          {finishButtonLabel || 'Next'}
        </Button>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default AdditionalInformation
