import React from 'react'
import { Check, Delete } from 'baseui/icon'
import {
  BaseFileContainer,
  FileNameLabel
} from 'components/shipper/instant-calendar/modals/styled/TemporalFile.styled'
import { parseLabelMaxChars } from 'components/utils/label-char-limit-formatter'
import { TooltipBox, TooltipCard, TooltipText } from 'components/shared/styles/CustomTooltip.styled'

export const AnswerFile = ({ props, onRemoveFile, selectedForRemove, disabled }) => {
  return (
    <BaseFileContainer isAnswer selectedForRemove={selectedForRemove} disabled={disabled}>
      <TooltipCard>
        <TooltipText>
          {selectedForRemove ? (
            <FileNameLabel>{`${parseLabelMaxChars(
              props?.name,
              14
            )} (Ready for delete)`}</FileNameLabel>
          ) : (
            <a href={props?.url}>
              <FileNameLabel>{parseLabelMaxChars(props?.name, 22)}</FileNameLabel>
            </a>
          )}
        </TooltipText>
        <TooltipBox>
          <p style={{ wordBreak: 'break-all', fontSize: '12px' }}>
            {(props?.name).replace(/%20/g, ' ')}
          </p>
        </TooltipBox>
      </TooltipCard>

      <div
        onClick={() => onRemoveFile(true)}
        style={{
          ...(disabled && { display: 'none' })
        }}
      >
        {!selectedForRemove && (
          <>
            <Check
              size={15}
              overrides={{
                Svg: {
                  style: () => ({
                    margin: '0px 15px 2px 0px',
                    border: '1px solid #99CC33',
                    borderRadius: '50%',
                    color: 'white',
                    backgroundColor: '#99CC33'
                  })
                }
              }}
            />
            <Delete
              size={22}
              overrides={{
                Svg: {
                  style: () => ({
                    margin: '9px 5px 0px 0px'
                  })
                }
              }}
            />
          </>
        )}
      </div>
    </BaseFileContainer>
  )
}
