export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const SET_LOADING = 'SET_LOADING'
export const SET_PICKED_APPOINTMENT = 'SET_PICKED_APPOINTMENT'
export const SET_MATCHES = 'SET_MATCHES'
export const SET_IS_CHECKED_IN = 'SET_IS_CHECKED_IN'
export const SET_SHOW_QUESTION_LIST = 'SET_SHOW_QUESTION_LIST'
export const SET_IS_ANSWER_LIST_UPDATED = 'SET_ANSWER_LIST_UPDATED'

interface Action {
  type: string
  payload: any
}

export const updateSearchTerm = (payload: string): Action => {
  return { type: UPDATE_SEARCH_TERM, payload }
}

export const setLoading = (payload: boolean): Action => {
  return { type: SET_LOADING, payload }
}

export const setPickedAppointment = (payload: any): Action => {
  return { type: SET_PICKED_APPOINTMENT, payload }
}

export const setMatches = (payload: any): Action => {
  return { type: SET_MATCHES, payload }
}

export const setIsCheckedIn = (payload: any): Action => {
  return { type: SET_IS_CHECKED_IN, payload }
}

export const setShowQuestionList = (payload: any): Action => {
  return { type: SET_SHOW_QUESTION_LIST, payload }
}

export const setIsAnswerListUpdated = (payload: any): Action => {
  return { type: SET_IS_ANSWER_LIST_UPDATED, payload }
}
