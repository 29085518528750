import { styled } from 'baseui'

export const DockModalAppointmentTypeContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '12px',
  columnGap: '12px',
  columnCount: 2
})

export const DockModalAppointmentType = styled('div', {
  display: 'flex',
  flex: 1,
  minWidth: '30%',
  clear: 'both',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})
