import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'

import { Plus, Minus } from '@phosphor-icons/react'
import { useDebounce } from 'react-use'
import { useStyletron } from 'baseui'
import Input from 'components/ui/generic/Input'
import Button from 'components/ui/specific/PrimaryButton'
import FormControl from 'components/ui/generic/FormControl'
import useSizing from '../../shared/hooks/use-sizing'
import { orderService } from '../../services/order'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../constants/default-shipper-subdomain'
import { Order } from '../../models/Order'
import { StatusCodes } from '../../constants/http-status-codes'
import { fancyToast } from '../../utils'
import { useTranslation } from 'react-i18next'

const DEBOUNCE_TIME_IN_MS = 500

const OpenSchedulingPOField = ({
  purchaseOrders,
  setPurchaseOrders,
  regularExpression,
  orderValidation,
  facilityId,
  setOrderValidation,
  labels
}) => {
  const subdomain = window.location.hostname.split('.')[0]
  const { customBreakPoints } = useSizing()
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  useDebounce(
    async () => {
      const [json, status] = await orderService.validateOrderOpenScheduling({
        subdomain: subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain,
        orders: purchaseOrders.map((order: Order) => order.identifier),
        facilityId
      })

      if (json && status !== StatusCodes.OK) {
        fancyToast({ info: json.error }, status)
      } else {
        setOrderValidation(json)
      }
    },
    DEBOUNCE_TIME_IN_MS,
    [purchaseOrders]
  )

  return (
    <FormControl
      label={
        labels?.purchaseOrderIdentifiers?.length
          ? labels?.purchaseOrderIdentifiers
          : t(
              'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.PurchaseOrderIdentifier.Text'
            )
      }>
      <FlexGrid
        flexGridRowGap="scale400"
        flexGridColumnGap="scale400"
        flexGridColumnCount={customBreakPoints.mdMax ? 1 : 3}
        paddingRight="88px">
        {purchaseOrders.map((purchaseOrder, index) => {
          const { identifier } = purchaseOrder
          return (
            <FlexGridItem display="flex" alignItems="center" justifyContent="center" key={index}>
              {index === 0 && (
                <div
                  className={css({
                    display: 'flex',
                    position: 'absolute',
                    right: '0',
                    gap: theme.sizing.scale200
                  })}>
                  <Button
                    onClick={() => {
                      const newPurchaseOrders = [...purchaseOrders, { identifier: '' }]
                      setPurchaseOrders(newPurchaseOrders)
                    }}>
                    <Plus />
                  </Button>
                  <Button
                    onClick={() => {
                      if (purchaseOrders.length > 1) {
                        setPurchaseOrders(purchaseOrders.slice(0, -1))
                      }
                    }}
                    disabled={purchaseOrders.length === 1}>
                    <Minus />
                  </Button>
                </div>
              )}
              <Input
                name={`appointment[purchase_order_number][${index}]`}
                positive={regularExpression.test(identifier)}
                error={orderValidation?.errors?.filter(error => error.index === index)[0]}
                value={identifier}
                onChange={e => {
                  const newPurchaseOrders = [...purchaseOrders]
                  purchaseOrders[index].identifier = e.currentTarget.value
                  setPurchaseOrders(newPurchaseOrders)
                }}
              />
            </FlexGridItem>
          )
        })}
      </FlexGrid>
    </FormControl>
  )
}

export default OpenSchedulingPOField
