import { styled } from 'baseui'
import Button from 'components/ui/generic/Button'

export const OrderSearchContainer = styled('div', {
  position: 'relative',
  diplay: 'flex',
  width: '400px'
})

export const OrderSearchButtonContainer = styled('div', {})

export const DeleteButton = styled(Button, {
  display: 'none'
})
