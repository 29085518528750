import React from 'react'
import { Paragraph1 } from 'baseui/typography'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'

const Finish = () => {
  const { currentFacility } = useCurrentFacilityContext()
  const { name } = currentFacility
  return (
    <Paragraph1>
      Welcome to {name}. The recieving coordinator has been notified that you are here, and will be
      reaching out shortly with further instruction.
    </Paragraph1>
  )
}

export default Finish
