import styled from 'styled-components'

export const DetailContainer = styled.div`
  border-top: 1px solid lightgray;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (orientation: landscape) {
    border-top: none;
    margin-top: 0;
  }
`
