import React from 'react'
import { Paragraph1 } from 'baseui/typography'
import { HeadingLevel, Heading } from 'baseui/heading'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Card, StyledBody, StyledAction } from 'baseui/card'
import { LoginForm } from '../shared/login-helpers'
import { Button } from 'baseui/button'
import { StatefulTooltip } from 'baseui/tooltip'
import { useTranslation } from 'react-i18next'
import { DEFAULT_BACKGROUND_IMG } from 'components/constants/default-media'

// TODO(adenta) the name of this file sucks. It's not even only related to email confirmation
const FacilityEmailConfirmation = ({ match, facility }) => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <HeadingLevel>
      <Heading>
        {facility.shipperName || 'Velostics'}{' '}
        <Paragraph1 color={theme.colors.mono700} marginTop="0px">
          <span>Appointment Scheduling Portal.</span>{' '}
          <span>{t('Constants.AssistanceMessage.Text')}</span>
        </Paragraph1>
      </Heading>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        })}>
        <Card
          headerImage={DEFAULT_BACKGROUND_IMG}
          overrides={{
            HeaderImage: {
              style: {
                objectFit: 'cover',
                width: '100%',
                height: '300px'
              }
            }
          }}>
          <StyledBody />
          <StyledAction>
            <Block display="flex" justifyContent="space-around" alignItems="center">
              <LoginForm
                title="Log in"
                signupMethod="password"
                allowSignUp={false}
                buttonProps={{
                  overrides: {
                    BaseButton: { style: { width: '100%' } }
                  }
                }}
              />
              <StatefulTooltip
                showArrow
                placement="top"
                triggerType="click"
                content="To sign up, please check your email for an invitation link">
                <Button>Sign up</Button>
              </StatefulTooltip>
            </Block>
          </StyledAction>
        </Card>
      </div>
    </HeadingLevel>
  )
}

export default FacilityEmailConfirmation
