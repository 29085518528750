import React, { useContext, useState } from "react";
import { Button } from "baseui/button";
import { HeadingLevel } from "baseui/heading";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Checkbox } from "baseui/checkbox";
import authenticatedFetch from "../../../utils/authenticated-fetch";
import { fancyToast } from "../../../utils";
import { StepContext } from "..";

const DriverInformation = ({ user, setUser }) => {
  const { step, setStep } = useContext(StepContext);
  const [loading, setLoading] = useState<boolean>(false);

  const updateDriver = async () => {
    setLoading(true);
    const [json, status] = await authenticatedFetch({
      path: `/users/${user.id}.json`,
      method: "PATCH",
      body: {
        user,
      },
    });

    if (status === 200) {
      setStep(step + 1);
      setUser(json);
    } else {
      fancyToast(json, status);
    }
    setLoading(false);
  };

  return (
    <HeadingLevel>
      <FormControl label="Carrier Name">
        <Input
          placeholder="J.B. Hunt, Ryder, Landstar, etc."
          autoComplete="off"
          name="user[carrier_name]"
          required
          value={user.carrierName}
          onChange={(e) => {
            setUser({ ...user, carrierName: e.currentTarget.value });
          }}
        />
      </FormControl>
      <FormControl label="Name">
        <Input
          placeholder="John Smith"
          autoComplete="off"
          name="user[name]"
          required
          value={user.name}
          onChange={(e) => {
            setUser({ ...user, name: e.currentTarget.value });
          }}
        />
      </FormControl>
      <FormControl caption="This information will not be shared with your current carrier.">
        <Checkbox
          checked={
            user &&
            user.notificationPreferenceAttributes &&
            user.notificationPreferenceAttributes.openToJobOpportunities
          }
          onChange={(e) => {
            setUser({
              ...user,
              notificationPreferenceAttributes: {
                ...user.notificationPreferenceAttributes,
                openToJobOpportunities: e.currentTarget.checked,
              },
            });
          }}
        >
          I would like to be contacted about job opportunities.
        </Checkbox>
      </FormControl>
      <Button
        isLoading={loading}
        disabled={!user.name || !user.carrierName}
        onClick={updateDriver}
      >
        Next
      </Button>
    </HeadingLevel>
  );
};

export default DriverInformation;
