import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal'
import moment from 'moment'
import React, { ChangeEvent } from 'react'
import ErrorMessageButton from 'components/shared/error-message-button'
import CyberInput from 'cyber/input'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import DeleteButton from 'components/shared/fields/delete-button'
import Select from 'components/ui/generic/Select'
import FormControl from 'components/ui/generic/FormControl'
import Checkbox from 'components/ui/generic/Checkbox'
import DaysOfWeek from './DockBlackoutDaysOfWeek'
import {
  CancelButtonStyled,
  DatepickerStyled,
  TimePickerStyled,
  FlexRow
} from './DockBlackoutModal.styled'
import { useTranslation } from 'react-i18next'
import { useDockAssigmentContext } from '../../../contexts/dock-assigment.context'
import StyledSpinner from '../../../shared/styled-spinner'

const DockBlackoutModal = () => {
  const {
    dockBlackout,
    dockBlackoutModalLoading,
    selectedFacility,
    selectedDockForBlackoutModal,
    blackoutModalIsOpenForCreate,
    blackoutModalIsOpenForUpdate,
    actions: {
      setDockBlackout,
      deleteDockBlackout,
      closeDockBlackoutModal,
      createDockBlackout,
      dockBlackoutModalformErrors,
      updateDockBlackout
    }
  } = useDockAssigmentContext()

  const { t } = useTranslation()

  return (
    <Modal
      unstable_ModalBackdropScroll
      onClose={closeDockBlackoutModal}
      isOpen={blackoutModalIsOpenForCreate || blackoutModalIsOpenForUpdate}
      closeable
      animate
      size="auto"
      autoFocus={false}
      overrides={{
        Dialog: { style: { width: '100%', maxWidth: '1230px' } },
        Close: {
          style: ({ $theme }) => ({
            right: $theme.sizing.scale800,
            top: $theme.sizing.scale800
          })
        }
      }}>
      <ModalHeader>
        {blackoutModalIsOpenForCreate
          ? t('DockAssignment.BlackoutModal.CreateTitle')
          : t('DockAssignment.BlackoutModal.UpdateTitle')}
      </ModalHeader>
      <ModalBody>
        {dockBlackoutModalLoading ? (
          <StyledSpinner />
        ) : (
          <>
            <FlexGrid flexGridRowGap="scale200">
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.Reason')}`}>
                  <CyberInput
                    value={dockBlackout?.name}
                    onChange={e => setDockBlackout({ name: e.currentTarget.value })}
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale200">
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.Facility')}`}>
                  <Select
                    value={
                      selectedFacility
                        ? [{ id: selectedFacility?.id, label: selectedFacility?.name }]
                        : []
                    }
                    placeholder=""
                    disabled
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.Dock')}`}>
                  <Select
                    value={
                      blackoutModalIsOpenForUpdate
                        ? dockBlackout.dock
                        : selectedDockForBlackoutModal
                        ? selectedDockForBlackoutModal
                        : []
                    }
                    placeholder=""
                    disabled
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
            <FlexGrid
              flexGridColumnCount={2}
              flexGridColumnGap="scale800"
              flexGridRowGap="scale200">
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.StartDate')}`}>
                  <DatepickerStyled
                    value={dockBlackout?.startTime || undefined}
                    formatString="MM/dd/yyyy"
                    onChange={({ date }) => setDockBlackout({ startTime: date as Date })}
                    maxDate={dockBlackout?.endTime}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.StartTime')}`}>
                  <TimePickerStyled
                    step={15 * 60}
                    value={dockBlackout?.startTime || undefined}
                    disabled={dockBlackout?.allDay}
                    onChange={date => setDockBlackout({ startTime: date as Date })}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.EndDate')}`}>
                  <DatepickerStyled
                    value={dockBlackout?.endTime || undefined}
                    formatString="MM/dd/yyyy"
                    onChange={({ date }) => setDockBlackout({ endTime: date as Date })}
                    minDate={dockBlackout?.startTime}
                  />
                </FormControl>
              </FlexGridItem>
              <FlexGridItem>
                <FormControl label={`${t('DockAssignment.BlackoutModal.EndTime')}`}>
                  <TimePickerStyled
                    step={15 * 60}
                    value={dockBlackout?.endTime || undefined}
                    disabled={dockBlackout?.allDay}
                    onChange={date => setDockBlackout({ endTime: date as Date })}
                  />
                </FormControl>
              </FlexGridItem>
            </FlexGrid>
            <FlexGridItem>
              <Checkbox
                checked={dockBlackout?.allDay}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.currentTarget.checked) {
                    setDockBlackout({
                      startTime: dockBlackout?.startTime
                        ? new Date(moment(dockBlackout.startTime).startOf('day').toString())
                        : new Date(),
                      endTime: dockBlackout?.endTime
                        ? new Date(moment(dockBlackout.endTime).endOf('day').toString())
                        : new Date(),
                      allDay: true
                    })
                  } else {
                    setDockBlackout({
                      allDay: false
                    })
                  }
                }}
                label={`${t('DockAssignment.BlackoutModal.AllDay')}`}
              />
            </FlexGridItem>
            <FlexGridItem>
              <Checkbox
                checked={dockBlackout?.daysOfWeek}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDockBlackout({
                    daysOfWeek: e.currentTarget.checked,
                    schedule: e.currentTarget.checked ? dockBlackout.schedule : undefined
                  })
                }
                label={`${t('DockAssignment.BlackoutModal.DaysOfWeek')}`}
              />
            </FlexGridItem>
            {dockBlackout?.daysOfWeek && <DaysOfWeek />}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {!dockBlackoutModalLoading && (
          <FlexRow>
            {blackoutModalIsOpenForUpdate ? (
              <DeleteButton onDelete={deleteDockBlackout} />
            ) : (
              <CancelButtonStyled
                onClick={closeDockBlackoutModal}
                isLoading={dockBlackoutModalLoading}>
                {t('Common.Fields.Cancel.Text')}
              </CancelButtonStyled>
            )}
            <ErrorMessageButton
              label={
                blackoutModalIsOpenForUpdate
                  ? t('Common.Button.Update.Text')
                  : t('Common.Button.Create.Text')
              }
              errors={dockBlackoutModalformErrors()}
              placement="left"
              buttonProps={{
                onClick: blackoutModalIsOpenForCreate ? createDockBlackout : updateDockBlackout,
                type: 'submit'
              }}
            />
          </FlexRow>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default DockBlackoutModal
