import Button from 'components/ui/generic/Button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import React from 'react'
import TimeFormatter from '../../utils/time-formatter'
import { ScrollableItem } from './SelectTime.styled'

const ScrollableColumn = ({ setOfTimeslots, setAppointment, onTimeSelect, facility }) => {
  const selectTimeslot = (timeSlot: Date | string) => {
    setAppointment(prevAppointment => ({
      ...prevAppointment,
      arrivalTime: timeSlot
    }))
    if (onTimeSelect) {
      onTimeSelect()
    }
  }
  return (
    <ScrollableItem>
      <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale400">
        {setOfTimeslots.map(({ timeSlot, available }) => (
          <FlexGridItem>
            <Button onClick={() => selectTimeslot(timeSlot)} size="mini" disabled={!available}>
              {new TimeFormatter('shortTime').format(timeSlot, facility.timeZone)}
            </Button>
          </FlexGridItem>
        ))}
      </FlexGrid>
    </ScrollableItem>
  )
}

export default ScrollableColumn
