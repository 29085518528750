import { Model } from './Model'

export interface Milestones extends Model {
  id?: string
  appointmentId?: string
  userId?: string
  address?: string
  latitude?: string
  longitude?: string
  facilityId: string
  checkedOutAt?: string
  checkedInAt?: string
  inGateAt?: string
  atDockAt?: string
  outDockAt?: string
  status: string
}

export const CHECKED_IN_AT = 'checkedInAt'
export const IN_GATE_AT = 'inGateAt'
export const AT_DOCK_AT = 'atDockAt'
export const OUT_DOCK_AT = 'outDockAt'
export const CHECKED_OUT_AT = 'checkedOutAt'

export const MILESTONE_KEYS = [CHECKED_IN_AT, IN_GATE_AT, AT_DOCK_AT, OUT_DOCK_AT, CHECKED_OUT_AT]

export const MILESTONES_LABELS = {
  [CHECKED_IN_AT]: 'Check In',
  [IN_GATE_AT]: 'In Gate',
  [AT_DOCK_AT]: 'At Dock',
  [OUT_DOCK_AT]: 'Out Dock',
  [CHECKED_OUT_AT]: 'Check Out'
}
