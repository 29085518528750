import React from "react";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Block } from "baseui/block";
import AppointmentCard from "./appointment-card";

import AppointmentListItem from "./appointment-list-item";
import useSizing from "../../shared/hooks/use-sizing";
import GuestCheckinCard from "./guest-checkin-card";
import GuestCheckinListItem from "./guest-checkin-list-item";

export const GUEST_CHECKIN = { id: null };

const AppointmentSelector = ({
  page,
  perPage,
  appointments,
  selectedAppointment,
  setSelectedAppointment,
}) => {
  const { isMediumAndUp } = useSizing();

  const filteredAppointments = appointments.filter((_appointment, index) => {
    const minIndex = perPage * page;
    const maxIndex = minIndex + perPage;
    return minIndex <= index && index < maxIndex;
  });

  return (
    <FlexGrid
      flexGridColumnCount={[1, 1, 3]}
      flexGridColumnGap="scale200"
      flexGridRowGap={[null, null, "scale600"]}
      height={["100%", "100%", "300px"]}
      width="90%"
    >
      {filteredAppointments.map((appointment, index) => {
        return (
          <FlexGridItem key={index} width="100%">
            <span
              onClick={() => {
                if (selectedAppointment === appointment) {
                  setSelectedAppointment(null);
                } else {
                  setSelectedAppointment(appointment);
                }
              }}
            >
              {isMediumAndUp ? (
                <AppointmentCard
                  selected={selectedAppointment === appointment}
                  appointment={appointment}
                />
              ) : (
                <AppointmentListItem
                  selected={selectedAppointment === appointment}
                  appointment={appointment}
                />
              )}
            </span>
          </FlexGridItem>
        );
      })}
      {filteredAppointments.length < perPage && (
        <FlexGridItem>
          <span
            onClick={() => {
              if (selectedAppointment === GUEST_CHECKIN) {
                setSelectedAppointment(null);
              } else {
                setSelectedAppointment(GUEST_CHECKIN);
              }
            }}
          >
            {isMediumAndUp ? (
              <GuestCheckinCard
                selected={selectedAppointment === GUEST_CHECKIN}
              />
            ) : (
              <GuestCheckinListItem
                selected={selectedAppointment === GUEST_CHECKIN}
              />
            )}
          </span>
        </FlexGridItem>
      )}
    </FlexGrid>
  );
};

export default AppointmentSelector;
