import { useStyletron } from 'baseui'
import Button from 'components/ui/specific/PrimaryButton'
import { ParagraphMedium } from 'baseui/typography'
import ScheduleFormAnswer from 'components/appointments/schedule/schedule-form-answer'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { answerService, driversCheckinService } from 'components/services'
import ErrorMessageButton from 'components/shared/error-message-button'
import StyledSpinner from 'components/shared/styled-spinner'
import checkAnswersRequireness from 'components/utils/answer-requireness-validation'
import { SUCCESS_CODE } from 'components/utils/status-codes'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CHECKED_OUT } from 'components/utils/appointment-statuses'

const DriversCustomQuestions = ({
  appointment,
  setSuccess,
  disabled = false
}: {
  setSuccess: (any) => void
  appointment: any
  disabled?: boolean
}): JSX.Element => {
  const [css] = useStyletron()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const {
    questions,
    answers,
    temporalFiles,
    documentSelections,
    actions: { setAnswers, updateFileUploadState, setQuestions, resetTemporalFilesState }
  } = useCQStateValue()
  const fileUpdateRef = useRef(null)

  const formErrors = () => {
    const errorValidations = [
      { label: 'Answers must meet the requirements', status: !answers.some(e => e.error) }
    ]

    checkAnswersRequireness(answers, temporalFiles, documentSelections, errorValidations)

    return errorValidations
  }

  const handleSubmit = async () => {
    if (documentSelections.length) {
      documentSelections.forEach(({ documentId, questionId }) =>
        answerService.removeFile(documentId, questionId)
      )
    }

    if (temporalFiles.length) {
      await updateFileUploadState(true)
      fileUpdateRef.current = true
    } else {
      fileUpdateRef.current = false
    }

    setLoading(true)
  }

  useEffect(() => {
    setLoading(true)
    fetch(`/appointments/${appointment.id}/get_answers_for_drivers_checkin.json`).then(res => {
      res.json().then(data => {
        setLoading(false)
        setAnswers(data.answersAttributes)
        setQuestions(data.answersAttributes?.map(ans => ans?.question))
      })
    })
  }, [])

  useEffect(() => {
    if (
      temporalFiles.length &&
      temporalFiles.every(tf => tf?.uploadState === 'saved') &&
      fileUpdateRef.current
    ) {
      resetTemporalFilesState()
      driversCheckinService.updateAppointment(appointment, answers).then(([data, status]) => {
        if (status === SUCCESS_CODE) {
          setSuccess(data)
        }
      })
    }
  }, [answers, temporalFiles])

  useEffect(() => {
    if (fileUpdateRef.current === false) {
      driversCheckinService.updateAppointment(appointment, answers).then(([data, status]) => {
        if (status === SUCCESS_CODE) {
          setSuccess(data)
        }
      })
    }
  }, [loading])

  return (
    <div className={css({ height: '100vh', background: '#f5f9ff', padding: '16px' })}>
      {loading ? <StyledSpinner /> : <ScheduleFormAnswer disabled={disabled} />}
      {questions.length === 0 && (
        <ParagraphMedium>{t('DriversCheckin.CustomQuestions.NoQuestions')}</ParagraphMedium>
      )}
      <div className={css({ display: 'flex', justifyContent: 'space-between' })}>
        {appointment?.status === CHECKED_OUT ? (
          <Button onClick={() => setSuccess(appointment)}>Next</Button>
        ) : (
          <ErrorMessageButton
            errors={formErrors()}
            buttonProps={{
              onClick: handleSubmit,
              isLoading: loading
            }}
            statefulTooltipProps={{ placement: 'left' }}
            label={t('DriversCheckin.CustomQuestions.Button')}
          />
        )}
      </div>
    </div>
  )
}

export default DriversCustomQuestions
