import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { HeadingXLarge, ParagraphMedium, ParagraphXSmall } from 'baseui/typography'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { AnimatedCheck } from './DriversCheckedInMessage.styled'

const DriversCheckedInMessage = ({
  showQuestionList,
  welcomeMessage
}: {
  showQuestionList: () => void
  welcomeMessage: string
}): JSX.Element => {
  const [css] = useStyletron()
  const { t } = useTranslation()

  return (
    <div
      className={css({
        padding: '16px',
        height: 'calc(100% - 180px)',
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'rgb(245, 249, 255)'
      })}>
      <HeadingXLarge>{t('DriversCheckin.CheckedInMessage.Label')}</HeadingXLarge>
      <AnimatedCheck
        src="https://storage.googleapis.com/terusama-beta/animated-check.gif"
        alt="Appointment checkin"
      />
      {welcomeMessage && (
        <ParagraphXSmall>{t('DriversCheckin.CheckedInMessage.Caption')}</ParagraphXSmall>
      )}
      {welcomeMessage && (
        <ParagraphMedium
          padding="15px"
          overrides={{ Block: { style: { lineHeight: '2em', borderTop: '1px solid lightgray' } } }}>
          {ReactHtmlParser(welcomeMessage)}
        </ParagraphMedium>
      )}

      <div className={css({ position: 'fixed', bottom: 0, padding: '30px 0' })}>
        <Button onClick={showQuestionList}>{t('DriversCheckin.CheckedInMessage.Button')}</Button>
      </div>
    </div>
  )
}

export default DriversCheckedInMessage
