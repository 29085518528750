import React, { useContext } from "react";
import { Input } from "baseui/input";
import { findIndex } from "lodash";
import { AppointmentContext } from "../../appointment-form";

const ResponseAnswer = ({ question, answer }) => {
  const { appointment, setAppointment } = useContext(AppointmentContext);
  const { response } = answer;
  const { id, fieldLimit } = question;
  return (
    <Input
      name={`answer[${id}]`}
      onChange={(e) => {
        const { answersAttributes } = appointment;

        const answerIndex = findIndex(answersAttributes, { questionId: id });
        answersAttributes[answerIndex].response = e.currentTarget.value;
        setAppointment({ ...appointment, answersAttributes });
      }}
      value={response}
      error={
        fieldLimit && response !== undefined
          ? response.length < fieldLimit
          : false
      }
    />
  );
};

export default ResponseAnswer;
