export const SET_SAVING = 'SET_SAVING'
export const SET_LOADING = 'SET_LOADING'
export const SET_CARRIERS = 'SET_CARRIERS'
export const SET_CARRIER_TO_UPDATE = 'SET_CARRIER_TO_UPDATE'
export const SET_CARRIER_TO_DELETE = 'SET_CARRIER_TO_DELETE'
export const CREATE_CARRIER = 'CREATE_CARRIER'
export const UPDATE_CARRIER = 'UPDATE_CARRIER'
export const DELETE_CARRIER = 'DELETE_CARRIER'
export const SEARCH = 'SEARCH'
export const SEARCH_SCAC = 'SEARCH_SCAC'
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE'
export const SELECT_CARRIER = 'SELECT_CARRIER'
export const CREATED_CARRIER = 'CREATED_CARRIER'
export const SET_MODAL_STATE = 'SET_MODAL_STATE'
export const SET_CARRIER = 'SET_CARRIER'
export const SET_SCAC_CODE = 'SET_SCAC_CODE'
export const SET_SEARCH_CARRIER = 'SET_SEARCH_CARRIER'
export const SET_CLOSE_MODAL_STATE = 'SET_CLOSE_MODAL_STATE'
export const SET_FORM_PARAMS = 'SET_MODAL_PARAMS'
export const SEARCH_PAGINATE = 'SEARCH_PAGINATE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_SEARCH = 'SET_SEARCH'

export const carriersReducer = (state, action) => {
  switch (action.type) {
    case SET_SAVING: {
      return {
        ...state,
        saving: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case SET_SEARCH_CARRIER: {
      return {
        ...state,
        carrier: action.payload.carrier,
        disableFields: action.payload.disableFields
      }
    }
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
        currentPage: 1
      }
    }
    case SET_CARRIERS: {
      const carriers = action.payload
      return {
        ...state,
        items: carriers
      }
    }
    case CREATE_CARRIER: {
      return {
        ...state,
        items: [...state.items],
        isModalActive: false
      }
    }
    case SEARCH: {
      return {
        ...state,
        selectItems: action.payload
      }
    }
    case SEARCH_PAGINATE: {
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.total
      }
    }
    case SEARCH_SCAC: {
      return {
        ...state,
        searchScac: action.payload
      }
    }
    case UPDATE_CARRIER: {
      const updatedCarrier = action.payload

      const currentCarrier = [...state.items]
      const indexOfUpdatedCarrier = currentCarrier.findIndex(
        carrier => carrier.id === updatedCarrier.id
      )
      currentCarrier[indexOfUpdatedCarrier] = updatedCarrier

      return {
        ...state,
        items: currentCarrier,
        isModalActive: false
      }
    }
    case DELETE_CARRIER: {
      const deleteCarrier = action.payload

      const currentCarrier = [...state.items]
      const indexOfDeletedCarrier = currentCarrier.findIndex(
        carrier => carrier.id === deleteCarrier.id
      )
      currentCarrier.splice(indexOfDeletedCarrier, 1)

      return {
        ...state,
        items: currentCarrier
      }
    }
    case SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: action.payload,
        isModalActive: true
      }
    }
    case SELECT_CARRIER: {
      return {
        ...state,
        selectedCarrier: action.payload
      }
    }
    case CREATED_CARRIER: {
      return {
        ...state,
        createdCarrier: action.payload
      }
    }
    case SET_MODAL_STATE: {
      return {
        ...state,
        isModalActive: action.payload
      }
    }
    case SET_CLOSE_MODAL_STATE: {
      return {
        ...state,
        isModalActive: false,
        disableFields: false,
        carrier: action.payload
      }
    }
    case SET_CARRIER_TO_UPDATE: {
      return {
        ...state,
        carrierToUpdate: action.payload.carrier,
        isModalActive: action.payload.isModalActive
      }
    }
    case SET_CARRIER_TO_DELETE: {
      return {
        ...state,
        carrierToDelete: action.payload.carrier,
        isDeleteModalActive: action.payload.isDeleteModalActive
      }
    }
    case SET_CARRIER: {
      return {
        ...state,
        carrier: action.payload
      }
    }
    case SET_SCAC_CODE: {
      return {
        ...state,
        scacCode: action.payload.scacCode
      }
    }
    case SET_FORM_PARAMS: {
      return {
        ...state,
        externalUser: action.payload.externalUser,
        schedulerId: action.payload.schedulerId,
        createdAsOther: action.payload.createdAsOther
      }
    }
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      }
    }
    default:
      return state
  }
}

const finalCarriersReducer = ({ carriers }, action) => {
  return {
    carriers: carriersReducer(carriers, action)
  }
}

export default finalCarriersReducer
