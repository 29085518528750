import FormControl from 'components/ui/generic/FormControl'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import FileUploader from 'components/shared/file-uploader'
import TemporalFile from 'components/shipper/components/temporalFile'
import { fancyToast } from 'components/utils'
import formatQuestionCaption from 'components/utils/format-question-caption'
import { getFileName } from 'components/utils/parse-filename'
import React, { useState, ReactElement, useEffect } from 'react'
import Questions from '../../components/Questions/Questions'
import { DEFAULT_SCHEDULER_PERMISSION } from '../../constants/default-permissions'
import { AnswerFile } from '../shared/answer-file'
import { AnswersAttributes } from './schedule-form-answer'
import formatQuestionLabel from 'components/utils/format-question-label'
import { useTranslation } from 'react-i18next'

interface ScheduleFormAnswerInputProps {
  answer: AnswersAttributes
  setAnswer: (answer: AnswersAttributes) => void
  disabled: boolean
}

const ScheduleFormAnswerInput = ({
  answer,
  setAnswer,
  disabled
}: ScheduleFormAnswerInputProps): ReactElement => {
  const { questionId, question, id, attachedDocuments } = answer
  const permission = question?.questionPermissionsAttributes[0] || DEFAULT_SCHEDULER_PERMISSION
  const [previewData, setPreviewData] = useState<any>(null)
  const {
    temporalFiles,
    documentSelections,
    actions: { removeTemporalFile, addFileSelection }
  } = useCQStateValue()
  const { t } = useTranslation()

  useEffect(() => {
    setPreviewData(temporalFiles.filter(tf => tf?.questionId === questionId)[0]?.files)
  }, [id, temporalFiles])

  const handleRemoveFile = e => removeTemporalFile(questionId, e.id)

  return (
    permission?.canView && (
      <FormControl
        label={formatQuestionLabel(permission, question.prompt)}
        caption={formatQuestionCaption({ question, permission })}
        disabled={disabled}>
        {question?.answerType === 'document' ? (
          <div>
            {previewData &&
              previewData?.length > 0 &&
              previewData?.map(item => (
                <TemporalFile file={item?.file || item} onRemoveFile={handleRemoveFile} />
              ))}
            {attachedDocuments && (
              <div>
                {attachedDocuments.map(document => (
                  <AnswerFile
                    props={{
                      url: document?.documentSecureUrl,
                      name: getFileName(document?.documentSecureUrl)
                    }}
                    onRemoveFile={() => {
                      addFileSelection(document?.id, id)
                      fancyToast({ file: 'ready for deletion' }, 200)
                    }}
                    selectedForRemove={
                      documentSelections.find(
                        selectedItem => selectedItem?.documentId === document?.id
                      )
                        ? true
                        : false
                    }
                    disabled={!permission?.canEdit || disabled}
                  />
                ))}
              </div>
            )}
            <FileUploader
              questionId={questionId}
              disabled={!permission?.canEdit || disabled}
              multiple
              onError={() => fancyToast({ info: 'Error uploading file, try again later' }, 412)}
              options={{
                addFile: true,
                maxWidth: '381px',
                labelMarginTop: '3rem',
                labelMarginLeft: '-100px',
                iconMarginTop: '-200px',
                type: 'document',
                customLabel: t('Common.FileUploader.Label.Text')
              }}
            />
          </div>
        ) : (
          <Questions
            question={question}
            answer={answer}
            disabled={!permission?.canEdit || disabled}
            setAnswer={setAnswer}
            displayError
          />
        )}
      </FormControl>
    )
  )
}

export default ScheduleFormAnswerInput
