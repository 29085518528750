import { EARLY, LATE, ON_TIME } from 'components/checkins/check-in-statuses'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { trim } from 'lodash'
import moment from 'moment'

class DriversCheckinService {
  getResults = (query: string): Promise<any> =>
    fetch(`/appointments/get_for_drivers_checkin.json?identifier=${query}`).then(data => {
      return data.json().then(res => res)
    })

  updateAppointment = async (appointment, answers) => {
    return authenticatedFetch({
      path: `/appointments/${appointment.id}/open_scheduling.json`,
      method: 'PATCH',
      body: {
        appointment: {
          arrivalTime: appointment.arrivalTime,
          purchaseOrdersAttributes: appointment.purchaseOrders?.map(
            (order: { identifier: string; id: string }) => ({
              ...order,
              identifier: trim(order.identifier)
            })
          ),
          quantity: appointment.quantity,
          comment: appointment.comment,
          dockId: appointment.dockId,
          equipmentTypeId: appointment.equipmentTypeId,
          ...(answers && { answersAttributes: answers })
        }
      }
    })
  }

  checkinAppointment = async appointment => {
    const arrivalTime = moment(appointment.arrivalTime)
    let checkinStatus = ON_TIME
    if (arrivalTime.diff(moment(), 'hours') > 1) {
      checkinStatus = EARLY
    }
    if (arrivalTime.diff(moment(), 'minutes') < -30) {
      checkinStatus = LATE
    }
    const body = {
      checkin: {
        appointmentId: appointment.id,
        facilityId: appointment.facilityId,
        status: checkinStatus,
        checkedInAt: new Date().toISOString()
      }
    }

    return authenticatedFetch({
      path: `/checkins.json`,
      method: 'POST',
      body
    })
  }

  getAnswers = async appointmentId => {
    fetch(`/appointments/${appointmentId}/get_answers_for_drivers_checkin.json`).then(res => {
      res.json().then(data => {
        return data
      })
    })
  }
}

export const driversCheckinService = new DriversCheckinService()
