import Select from 'components/ui/generic/Select'
import React, { ReactElement } from 'react'

interface Option {
  label: string
  value: string
}
interface DropdownListTypeProps {
  value: any
  options: Option[]
  onChange: (e) => void
  disabled?: boolean
}

const DropdownListType = ({
  value,
  onChange,
  disabled,
  options
}: DropdownListTypeProps): ReactElement => (
  <Select
    options={options}
    value={value ? [{ label: value, value }] : []}
    placeholder="Select answer"
    onChange={onChange}
    disabled={disabled}
    valueKey="value"
    clearable={false}
  />
)
export default DropdownListType
