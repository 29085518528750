import { useStyletron } from 'baseui'
import { ParagraphMedium } from 'baseui/typography'
import { useDriversCheckinContext } from 'components/contexts/drivers-checkin-context'
import StyledSpinner from 'components/shared/styled-spinner'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FileSearch } from '@phosphor-icons/react'
import { useHistory } from 'react-router-dom'
import DriversSearchInput from '../DriversSearchInput'
import DriversSearchResult from '../DriversSearchResult/DriversSearchResult'

import {
  setIsAnswerListUpdated,
  setIsCheckedIn,
  setLoading,
  setMatches,
  setPickedAppointment,
  setShowQuestionList,
  updateSearchTerm
} from '../reducer/actions'
import { FormContainer, NoResults, ResultsContainer } from './DriversSearchForm.styled'

const DriversSearchForm = (): JSX.Element => {
  const { searchTerm, loading, pickedAppointment, getResults, matches, dispatch } =
    useDriversCheckinContext()
  const [, theme] = useStyletron()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    dispatch(updateSearchTerm(''))
    dispatch(setMatches([]))
    dispatch(setPickedAppointment(null))
    dispatch(setIsCheckedIn(false))
    dispatch(setLoading(false))
    dispatch(setShowQuestionList(false))
    dispatch(setIsAnswerListUpdated(false))
  }, [pickedAppointment, dispatch])

  return (
    <FormContainer>
      <DriversSearchInput
        value={searchTerm}
        handleValueUpdate={value => dispatch(updateSearchTerm(value))}
        getResults={getResults}
      />
      {loading && <StyledSpinner />}
      {!pickedAppointment && !loading && (
        <ResultsContainer>
          {matches?.length > 0 ? (
            matches?.map(result => (
              <DriversSearchResult
                key={result.id}
                appointment={result}
                pickAppointment={appointment => {
                  if (appointment.status !== 'scheduled') {
                    dispatch(setShowQuestionList(true))
                  }
                  dispatch(setPickedAppointment(appointment))
                  history.push('/pass/detail')
                }}
              />
            ))
          ) : (
            <NoResults>
              <FileSearch size={theme.sizing.scale2400} />
              <ParagraphMedium>{t('DriversCheckin.Form.NoResults')}</ParagraphMedium>
            </NoResults>
          )}
        </ResultsContainer>
      )}
    </FormContainer>
  )
}

export default DriversSearchForm
