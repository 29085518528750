import authenticatedFetch from 'components/utils/authenticated-fetch'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { Warehouse } from '@phosphor-icons/react'
import { sortBy } from 'lodash'
import { connectRefinementList } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'
import Select from 'components/ui/generic/Select'

const NonConnectedFacilityAutocomplete = ({ refine, currentRefinement, value, setValue }) => {
  const [search, setSearch] = useState<string>('')
  const [facilities, setFacilities] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    refine(value?.map((hits: { id: string }) => hits.id))
  }, [value])

  const searchRecords = async () => {
    const [{ hits }, status] = await authenticatedFetch({
      path: `/search.json`,
      method: 'POST',
      body: {
        search: {
          indexName: 'facility',
          params: {
            query: search
          }
        }
      }
    })

    const sortedHits = sortBy(hits, 'createdAt')

    if ([200, 304].includes(status)) {
      setFacilities(sortedHits)
      if (!currentRefinement[0]) {
        const [{ hits }, status] = await authenticatedFetch({
          path: `/facilities/get_multiple_facilities_by_ids.json`,
          method: 'POST',
          body: {
            ids: value.map((facility: { id: string }) => facility.id)
          }
        })

        if ([200, 304].includes(status) && hits) {
          refine(hits.map((hits: { id: string }) => hits.id))
          setValue(hits)
        }
      }
    }
  }

  useDebounce(searchRecords, 200, [search])

  return (
    <Select
      id="calendar-facility-select"
      startEnhancer={<Warehouse />}
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves
      placeholder={t('Common.Select.FacilityAutoComplete.PlaceHolder.Text')}
      value={value}
      onChange={({ value }) => {
        setSearch('')
        refine(value.map(option => option.id))
        setValue(value)
      }}
      options={facilities}
      labelKey="name"
      multi
      onInputChange={e => {
        setSearch(e.currentTarget.value)
      }}
      minWidth="135px"
      maxWidth="550px"
    />
  )
}

const FacilityAutocompleteConnectedComponent = connectRefinementList(
  NonConnectedFacilityAutocomplete
)

const FacilityAutocomplete = ({ value, setValue }) => {
  return (
    <FacilityAutocompleteConnectedComponent
      attribute="facility_id"
      defaultRefinement={['facility_id']}
      value={value}
      setValue={setValue}
    />
  )
}

export default FacilityAutocomplete
