import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import React from 'react'
import FacilityBook from './facility-book'

const CurrentFacilityBook = () => (
  <CustomQuestionsProvider>
    <FacilityBook />
  </CustomQuestionsProvider>
)
export default CurrentFacilityBook
