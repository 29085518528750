import { Card, StyledBody } from "baseui/card";
import React from "react";
import { Block } from "baseui/block";
import { Heading } from "baseui/heading";
import { useStyletron } from "baseui";

const GuestCheckinListItem = ({ selected }) => {
  const [css, theme] = useStyletron();
  return (
    <Block
      backgroundColor={selected ? theme.colors.negative100 : null}
      overrides={{
        Block: { style: { borderBottom: `solid 1px ${theme.colors.mono700}` } },
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Heading>Other</Heading>
    </Block>
  );
};

export default GuestCheckinListItem;
