import React, { useEffect } from 'react'
import { FormControl } from 'baseui/form-control'
import { HeadingXSmall, LabelSmall, ParagraphSmall } from 'baseui/typography'
import { AppointmentDurationTable } from './AppointmentDurationTable'
import { AppointmentType } from 'components/models/AppointmentType'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { useTranslation } from 'react-i18next'

import Checkbox from 'components/ui/generic/Checkbox'
import Select from 'components/ui/generic/Select'

const optionsSelectTimeDuration = [15, 30, 60]

export const AppointmentDurationSection = ({
  facility,
  appointmentPreference,
  setAppointmentPreference,
  disabled,
  equipmentTypes,
  setShouldUpdateAppointmentDurations
}) => {
  const { appointmentDurationsAttributes } = appointmentPreference
  const { t } = useTranslation()

  useEffect(
    () =>
      setAppointmentPreference({
        ...appointmentPreference,
        appointmentDurationInMinutes: facility.appointmentDuration / 60 || 30
      }),
    [facility]
  )

  return (
    <>
      <HeadingXSmall marginTop="0" marginBottom="scale800">
        {t('AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Header.Text')}
      </HeadingXSmall>

      <FormControl
        label={t(
          'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.TakeIntoAccountDurations.Label.Text'
        )}>
        <FlexGrid flexGridColumnCount={2} width="150px" marginBottom="16px">
          <FlexGridItem>
            <Checkbox
              checked={appointmentPreference?.appointmentDurationActive}
              onChange={e => {
                setShouldUpdateAppointmentDurations(true)
                setAppointmentPreference({
                  ...appointmentPreference,
                  appointmentDurationActive: e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.TakeIntoAccountDurations.Items.Yes.Text'
              )}
            />
          </FlexGridItem>
          <FlexGridItem>
            <Checkbox
              checked={!appointmentPreference?.appointmentDurationActive}
              onChange={e => {
                setShouldUpdateAppointmentDurations(true)
                setAppointmentPreference({
                  ...appointmentPreference,
                  appointmentDurationActive: !e.currentTarget.checked
                })
              }}
              label={t(
                'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.TakeIntoAccountDurations.Items.No.Text'
              )}
            />
          </FlexGridItem>
        </FlexGrid>
      </FormControl>
      <FormControl
        label={t(
          'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.MinimumAppointmentDurations.Label.Text'
        )}>
        <Select
          options={optionsSelectTimeDuration.map(timeDuration => ({
            label: t(
              'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.MinimumAppointmentDurations.Items.Minutes.Text',
              { count: timeDuration }
            ),
            id: timeDuration
          }))}
          value={[
            {
              id: appointmentPreference?.appointmentDurationInMinutes,
              label: t(
                'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.MinimumAppointmentDurations.Minutes.Text',
                { count: appointmentPreference?.appointmentDurationInMinutes }
              )
            }
          ]}
          placeholder=""
          disabled={disabled}
          onChange={params => {
            setShouldUpdateAppointmentDurations(true)
            setAppointmentPreference({
              ...appointmentPreference,
              appointmentDurationInMinutes: params.value[0].id
            })
          }}
          clearable={false}
          maxWidth="250px"
        />
      </FormControl>
      <ParagraphSmall marginBottom="32px">
        {t(
          'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Fields.MinimumAppointmentDurations.Caption.Text'
        )}
      </ParagraphSmall>
      <div>
        <LabelSmall>
          {t('AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Captions.Label.Text')}
        </LabelSmall>
        <ParagraphSmall>
          {t(
            'AppointmentPreferences.Form.Fields.ControlAppoinmentDurations.Captions.Paragraph.Text'
          )}
        </ParagraphSmall>
      </div>
      <div style={{ maxWidth: '100%' }}>
        <AppointmentDurationTable
          appointmentTypes={facility?.appointmentTypes as AppointmentType[]}
          equipmentTypes={equipmentTypes}
          appointmentDurationsAttributes={appointmentDurationsAttributes}
          setAppointmentPreference={setAppointmentPreference}
          appointmentPreference={appointmentPreference}
          setShouldUpdateAppointmentDurations={setShouldUpdateAppointmentDurations}
        />
      </div>
    </>
  )
}
