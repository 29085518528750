import { LabelXSmall } from 'baseui/typography'
import React, { ReactElement } from 'react'
import { Question } from '../../../appointments/types'
import {
  DEFAULT_SCHEDULER_PERMISSION,
  DEFAULT_SHIPPER_PERMISSION
} from '../../../constants/default-permissions'
import { Role } from '../../../models/Role'
import { INTERNAL } from '../../../models/Role'
import PermissionRow from './PermissionRow'
import {
  FieldContainer,
  QuestionPermissionsContainer,
  QuestionPermissionsDivider,
  QuestionPermissionsTitle
} from './QuestionsPermissions.styled'
import i18n from 'translations/i18n'

const titles = [
  i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.Audience'),
  i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.AccessControl'),
  i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.Required'),
  i18n.t('Settings.AppointmentTypes.CustomQuestions.Permissions.ShowOnCreate')
]

interface QuestionPermissionsProps {
  questions: Question[]
  setQuestions: (question: Question[]) => void
  index: number
  listOfEnabledRoles: Role[]
}

const defaultPermissionPerAudience = (audience: string) => {
  if (audience === INTERNAL) {
    return DEFAULT_SHIPPER_PERMISSION
  }

  return DEFAULT_SCHEDULER_PERMISSION
}
const QuestionPermissions = ({
  questions,
  setQuestions,
  index,
  listOfEnabledRoles
}: QuestionPermissionsProps): ReactElement => {
  return (
    <QuestionPermissionsContainer>
      <QuestionPermissionsTitle>
        {titles.map((title, index) => (
          <FieldContainer
            key={title}
            justifyContent={title.length < 9 && index !== 0 ? 'center' : 'start'}>
            <LabelXSmall>{title}</LabelXSmall>
          </FieldContainer>
        ))}
      </QuestionPermissionsTitle>
      <QuestionPermissionsDivider />
      {listOfEnabledRoles.map(role => {
        const permission =
          questions[index].questionPermissionsAttributes?.filter(
            qp => qp.userRoleId === role.id
          )[0] || defaultPermissionPerAudience(role.audience)

        return (
          <PermissionRow
            questions={questions}
            permission={permission}
            setQuestions={setQuestions}
            index={index}
            userRoleId={role.id}
            role={role}
            key={role.name + permission}
          />
        )
      })}
    </QuestionPermissionsContainer>
  )
}

export default QuestionPermissions
