import { DEFAULT_SHIPPER_SUBDOMAIN } from 'components/constants/default-shipper-subdomain'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import http from 'components/utils/authenticated-fetch'

class ShipperService {
  getFacilityList = (id: string): Promise<any> =>
    http({
      path: `/shippers/${id}/facilities_list.json`
    }).then(([facilities]) => facilities)

  getSignUpInfo = (subdomain: string): Promise<any> =>
    authenticatedFetch({
      path: `/shippers/sign_up_info.json?subdomain=${
        subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain
      }`,
      method: 'GET'
    }).then(([json]) => (json?.name ? json : { name: 'Velostics' }))

  removeShipperSettingsImage = (id: string, shipperId: string, type: string): Promise<any> =>
    authenticatedFetch({
      path: `/shippers/remove_image_attachment.json`,
      method: 'DELETE',
      body: { id, shipperId, type }
    }).then(([json]) => json)

  updateShipperInitialSettings = (params: any): Promise<any> =>
    authenticatedFetch({
      path: `/shippers/${params.id}.json`,
      method: 'PATCH',
      body: {
        shipper: {
          ...(params.logo && {
            active_logo: params.logo
          }),
          ...(params.background && {
            active_background: params.background
          })
        }
      }
    }).then(([json]) => json)
}

export const shipperService = new ShipperService()
