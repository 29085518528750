import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'baseui/button'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { BlockProps } from 'baseui/block'
import { ChevronLeft, ChevronRight } from 'baseui/icon'
import { Paragraph1 } from 'baseui/typography'
import { StepContext } from '..'
import authenticatedFetch from '../../../utils/authenticated-fetch'
import AppointmentSelector, { GUEST_CHECKIN } from '../appointment-selector'
import SelectorButton from '../selector-button'
import { fancyToast } from '../../../utils'
import useSizing from '../../../shared/hooks/use-sizing'
import { authService } from 'components/services/auth.service'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'
import { useTranslation } from 'react-i18next'

const LoadDetails = ({ selectedAppointment, setSelectedAppointment }) => {
  const { isMediumAndUp } = useSizing()
  const perPage = isMediumAndUp ? 6 : 3
  const { currentFacility } = useCurrentFacilityContext()
  const [appointments, setAppointments] = useState<any[]>([])
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const itemProps: BlockProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }

  const narrowItemProps = {
    ...itemProps,
    overrides: {
      Block: {
        style: {
          flexGrow: 0,
          width: '4%'
        }
      }
    }
  }

  const selectAppointment = async () => {
    if (selectedAppointment === GUEST_CHECKIN) {
      setLoading(true)
      const [json, status] = await authenticatedFetch({
        path: `checkins/notify_facility.json`,
        method: 'POST'
      })

      if (status == 200) {
        setStep(step + 2)
        setTimeout(authService.logout, 45000)
      } else {
        fancyToast(json, status)
      }
      setLoading(false)
    } else {
      setStep(step + 1)
    }
  }

  const HelpText = () => {
    if (selectedAppointment === null) {
      return <span>Select an appointment to see more details</span>
    }
    if (selectedAppointment === GUEST_CHECKIN) {
      return (
        <span>
          Call dispatch, and ask them for your scheduled arrival time / purchase order information
        </span>
      )
    }
    return (
      <span>
        {selectedAppointment.createdByOrganizationName}:{' '}
        {selectedAppointment.purchaseOrderIdentifiers}
      </span>
    )
  }

  useEffect(() => {
    currentFacility &&
      authenticatedFetch({
        path: `/facilities/${currentFacility.id}/appointments.json`
      }).then(([json, _status]) => setAppointments(json))
  }, [currentFacility])

  const { step, setStep } = useContext(StepContext)
  const minPage = 0
  const maxPage = Math.ceil((appointments.length + 1) / perPage) - 1
  const { t } = useTranslation()

  return appointments.length === 0 ? (
    <Paragraph1>
      No appointments are scheduled for today. {t('Constants.AssistanceMessage.Text')}
    </Paragraph1>
  ) : (
    <FlexGrid flexGridRowGap="scale600">
      <FlexGridItem>
        <FlexGrid flexGridColumnCount={3}>
          <FlexGridItem {...narrowItemProps}>
            <SelectorButton
              Icon={ChevronLeft}
              disabled={page === minPage}
              onClick={() => {
                setPage(Math.max(minPage, page - 1))
              }}
            />
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <AppointmentSelector
              {...{
                page,
                perPage,
                appointments,
                selectedAppointment,
                setSelectedAppointment
              }}
            />
          </FlexGridItem>
          <FlexGridItem {...narrowItemProps}>
            <SelectorButton
              Icon={ChevronRight}
              disabled={page === maxPage}
              onClick={() => {
                setPage(Math.min(maxPage, page + 1))
              }}
            />
          </FlexGridItem>
        </FlexGrid>
      </FlexGridItem>
      <FlexGridItem margin="0" display="flex" alignItems="center" justifyContent="left">
        <Button
          disabled={
            !selectedAppointment ||
            (selectedAppointment &&
              selectedAppointment === GUEST_CHECKIN &&
              !currentFacility.guestMode)
          }
          onClick={selectAppointment}>
          Next
        </Button>
        <Paragraph1 margin="0" marginLeft="scale200">
          <HelpText />
        </Paragraph1>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default LoadDetails
