import authenticatedFetch from 'components/utils/authenticated-fetch'
import http from 'components/utils/authenticated-fetch'

class AppointmentService {
  get = (id: string): Promise<[any, number]> =>
    http({
      path: `/appointments/${id}.json`,
      method: 'GET'
    })

  getAnsweredQuestions = (id: string): Promise<any> =>
    http({
      path: `/appointments/${id}/answered_questions.json`,
      method: 'GET'
    })

  create = (body): Promise<any> =>
    authenticatedFetch({
      path: `/appointments.json`,
      method: 'POST',
      body
    }).then(([result, status]) => [result, status])

  assignDock = (appointmentId: string, dockId: string, date?: string): Promise<any> =>
    authenticatedFetch({
      path: `/appointments/assign_dock_to_appointment.json?id=${dockId}&appointment_id=${appointmentId}${
        date ? `&date=${date}` : ''
      }`,
      method: 'POST'
    }).then(([result, status]) => [result, status])

  update = (id, body): Promise<any> =>
    authenticatedFetch({
      path: `/appointments/${id}.json`,
      method: 'PATCH',
      body
    }).then(([result, status]) => [result, status])
}

export const appointmentService = new AppointmentService()
