import React from 'react'
import { useStyletron } from 'baseui'
import LocaleSwitcher from './locale-switcher'
import { FooterContainer } from './Footer.styled'

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const logoFile = require('../../../assets/images/velostics-logo.svg') as string

const Footer = () => {
  const [css, theme] = useStyletron()
  return (
    <FooterContainer
      marginTop={theme.sizing.scale1000}
      background={theme.colors.backgroundSecondary}
      borderTop={theme.borders.border400}
      id="footer-component">
      <div
        className={css({
          padding: theme.sizing.scale1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '1200px',
          margin: '0 auto'
        })}>
        <img className={css({ width: '80px' })} src={logoFile} alt="Velostics Logo" />
        <div className={css({ paddingTop: theme.sizing.scale200 })}>
          <LocaleSwitcher />
        </div>
      </div>
    </FooterContainer>
  )
}

export default Footer
