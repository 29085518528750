import React, { useState, useEffect, useContext } from 'react'
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid'
import { Paragraph1 } from 'baseui/typography'
import { Button } from 'baseui/button'
import moment from 'moment'
import QuestionsList from '../../../facilities/book/additional-information/QuestionsList'
import StyledSpinner from '../../../shared/styled-spinner'
import authenticatedFetch from '../../../utils/authenticated-fetch'
import { CurrentUserContext } from '../../../homepage/current-user-context'
import { fancyToast } from '../../../utils'
import { StepContext } from '..'
import { DRIVER } from '../../../models/User'
import { ON_TIME, EARLY, LATE } from '../../check-in-statuses'
import { authService } from 'components/services/auth.service'
import { useCurrentFacilityContext } from 'components/contexts/current-facility.context'

const AdditionalInformation = ({ appointment, setAppointment }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const { currentFacility } = useCurrentFacilityContext()
  const { step, setStep } = useContext(StepContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [appointmentType, setAppointmentType] = useState<any>(null)

  const fetchAppointmentType = async () => {
    if (!appointment.appointmentTypeId || appointmentType) {
      return
    }
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/appointment_types/${appointment.appointmentTypeId}.json`
    })

    setLoading(false)

    if (status === 200) {
      setAppointmentType(json)
    } else {
      fancyToast(json, status)
    }
  }

  useEffect(() => {
    fetchAppointmentType()
  }, [appointment])

  const createCheckin = async () => {
    const arrivalTime = moment(appointment.arrivalTime)

    let status = ON_TIME

    if (arrivalTime.diff(moment(), 'hours') > 1) {
      status = EARLY
    }

    if (arrivalTime.diff(moment(), 'minutes') < -30) {
      status = LATE
    }

    setLoading(true)
    const body = {
      checkin: {
        userId: currentUser.id,
        appointmentId: appointment.id,
        facilityId: (currentFacility && currentFacility.id) || appointment.facilityId,
        status
      }
    }
    const [checkinJson, checkinStatus] = await authenticatedFetch({
      path: `/checkins.json`,
      method: 'POST',
      body
    })

    // TODO(adenta) we shoudl be able to run these in paralell
    // we have to do this to set the drivers question answers
    if (checkinStatus === 201 && appointment.answersAttributes) {
      await authenticatedFetch({
        path: `/appointments/${appointment.id}.json`,
        method: 'PATCH',
        body: {
          appointment
        }
      })
    }

    fancyToast(
      {
        info: `you have been successfully checked in, and will be contacted at ${checkinJson.phoneNumber}.`
      },
      checkinStatus
    )
    if (checkinStatus === 201) {
      setStep(step + 1)
      setTimeout(authService.logout, 15000)
    }

    setLoading(false)
  }

  if (
    (appointment.appointmentTypeId && !appointmentType) ||
    (appointmentType && !appointmentType.questions)
  ) {
    return <StyledSpinner />
  }

  const questions =
    (appointmentType &&
      appointmentType.questions &&
      appointmentType.questions.filter(
        ({ audienceType, disabled }) => audienceType === DRIVER && !disabled
      )) ||
    []

  return (
    <FlexGrid flexGridRowGap="scale200">
      {questions.length > 0 ? (
        <QuestionsList {...{ appointment, setAppointment, questions }} />
      ) : (
        <FlexGridItem>
          <Paragraph1>No additional information needed.</Paragraph1>
        </FlexGridItem>
      )}
      <FlexGridItem>
        <Button
          isLoading={loading}
          disabled={
            !questions.every(question => {
              if (question.response !== undefined && question.fieldLimit) {
                return question.response.length >= question.fieldLimit
              }
              return !!question.response || (question.documents && question.documents.length > 0)
            })
          }
          onClick={createCheckin}>
          Check-in
        </Button>
      </FlexGridItem>
    </FlexGrid>
  )
}

export default AdditionalInformation
