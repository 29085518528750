import React from 'react'
import {
  DockModalAppointmentTypeContainer,
  DockModalAppointmentType
} from './DockModalAppointmentTypeCheckboxes.styled'

import Checkbox from 'components/ui/generic/Checkbox'

type Props = {
  loading: boolean
  disabled?: boolean
  onAppointmentChange: (ids: string[]) => void
  appointmentTypes: any[]
  dock: any
}

export const DockModalAppointmentTypeCheckboxes = ({
  dock,
  disabled = true,
  appointmentTypes,
  loading,
  onAppointmentChange
}: Props) => {
  const appointmentTypeIds = dock?.appointmentTypeIds || []

  return (
    <DockModalAppointmentTypeContainer>
      {appointmentTypes?.map((appointmentType, index) => {
        const id = appointmentType.id
        return (
          <DockModalAppointmentType key={index}>
            <Checkbox
              labelPlacement="right"
              disabled={disabled || loading}
              checked={appointmentTypeIds?.includes(id)}
              onChange={evt => {
                onAppointmentChange(
                  evt.currentTarget.checked
                    ? [...appointmentTypeIds, id]
                    : appointmentTypeIds.filter(typeId => typeId !== id)
                )
              }}
              label={appointmentType.name}
            />
          </DockModalAppointmentType>
        )
      })}
    </DockModalAppointmentTypeContainer>
  )
}
