// We have to import this @fullcalendar/react before 'Draggable. Something about tree shaking.
// https://github.com/fullcalendar/fullcalendar-angular/issues/306#issuecomment-647591856
import '@fullcalendar/react'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { HeadingXSmall } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useRef, useState } from 'react'
import CyberSearchRefinementList from 'cyber/search/refinement-list'
import CyberSearch from 'cyber/search/search'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import { useIdle } from 'components/hooks/use-idle'
import { useLocalStorage } from 'react-use'

import {
  CustomLabels,
  INITIAL_CUSTOM_FIELD_NAMES,
  CustomLabelKey,
  CustomLabelsContext
} from 'components/contexts/custom-labels-context'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import CalendarHits from './calendar-hits'
import QuickActions from './toggles/quick-actions'
import Modals from './modals'
import FacilityAutocomplete from './toggles/facility-autocomplete'
import TimeRangeToggle from './toggles/time-range-toggle'
import SchedulerRefinementList from './scheduler-refinement-list'
import useDefaultFacilities from './hooks/use-default-facilities'
import useFacilityInfo from './hooks/use-facility-info'
import { FacilitiesContext } from './context/facilities-context'
import AppointmentBulkForm from './modals/bulk-upload/bulk-upload-modal'
import { CalendarSelectedOptionsProvider } from '../../contexts/calendar-selected-options.context'
import Header from 'components/ui/generic/Header'
import UploadButton from 'components/ui/specific/UploadButton'
import CreateButton from 'components/ui/specific/CreateButton'
import FiltersPopover from 'components/ui/specific/FiltersPopover'
import { DEFAULT_LOCALE_STRING_LANGUAGE } from '../../constants/default-locale-string-language'
import moment from 'moment'
import FilterSummary from './filter-summary'

const InstantCalendar = () => {
  const waitActionTimerInMiliseconds = 600_000
  const calendarReference = useRef(null)
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>(null)
  const [numberOfHits, setNumberOfHits] = useState<number>(0)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [weekMode, setWeekMode] = useState(false)
  const [selectedFacilities, setSelectedFacilities] = useDefaultFacilities(currentUser)

  const { facilities, slotDuration } = useFacilityInfo(selectedFacilities)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const active = useIdle(waitActionTimerInMiliseconds)
  const { t } = useTranslation()

  useEffect(() => {
    if (!active) {
      window.location.reload()
    }
  }, [active])

  const [cachedTimeRange, setCachedTimeRange] = useLocalStorage(
    `DASHBOARD_TIME_RANGE_BY_USER_AND_ORG`,
    {
      minTime: [
        {
          id: '1970-01-01T00:00',
          label: '00:00'
        }
      ],
      maxTime: [{ id: '1970-01-01T24:00', label: '24:00' }]
    }
  )

  const [customLabels, setCustomLabels] = useState<CustomLabels>(INITIAL_CUSTOM_FIELD_NAMES)

  const customLabel = (customLabelKey: CustomLabelKey) => customLabels[customLabelKey]
  const [showBulkModal, setShowBulkModal] = useState<boolean>(false)

  const onUploadClick = () => {
    setShowBulkModal(prev => !prev)
  }

  const [css, theme] = useStyletron()

  const initialDate = [
    moment(new Date().toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).startOf('day').toDate(),
    moment(new Date().toLocaleString(DEFAULT_LOCALE_STRING_LANGUAGE)).endOf('day').toDate()
  ]

  const [cacheDate, setCacheDate] = useLocalStorage(`DASHBOARD_SELECTED_DATE_BY_USER_AND_ORG`, {})

  const [dateInCalendarFilter, setDateInCalendarFilter] = useState(
    cacheDate &&
      cacheDate[currentUser.shipperId] &&
      cacheDate[currentUser.shipperId][currentUser.id] &&
      cacheDate[currentUser.shipperId][currentUser.id][0] &&
      cacheDate[currentUser.shipperId][currentUser.id][1]
      ? [
          moment(
            new Date(cacheDate[currentUser.shipperId][currentUser.id][0]).toLocaleString(
              DEFAULT_LOCALE_STRING_LANGUAGE
            )
          )
            .startOf('day')
            .toDate(),
          moment(
            new Date(cacheDate[currentUser.shipperId][currentUser.id][0]).toLocaleString(
              DEFAULT_LOCALE_STRING_LANGUAGE
            )
          )
            .endOf('day')
            .toDate()
        ]
      : initialDate
  )

  useEffect(() => {
    if (
      cacheDate &&
      cacheDate[currentUser.shipperId] &&
      cacheDate[currentUser.shipperId][currentUser.id] &&
      cacheDate[currentUser.shipperId][currentUser.id][0]
    ) {
      setSelectedDate(new Date(cacheDate[currentUser.shipperId][currentUser.id][0]))
    }
  }, [cacheDate])

  return (
    <CustomLabelsContext.Provider value={{ customLabels, setCustomLabels, customLabel }}>
      <CalendarReferenceContext.Provider
        value={{
          calendarReference,
          cacheDate,
          setCacheDate,
          setDateInCalendarFilter,
          dateInCalendarFilter,
          weekMode,
          setWeekMode
        }}>
        <FacilitiesContext.Provider
          value={{ facilities, slotDuration, selectedDate, setSelectedDate }}>
          <CalendarSelectedOptionsProvider>
            <CyberSearch
              indexName="appointment"
              queryBy="purchase_order_identifiers"
              hitsPerPage={100}>
              <Modals
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                selectedFacilities={selectedFacilities}
              />
              <AppointmentBulkForm
                showBulkModal={showBulkModal}
                onClose={onUploadClick}
                selectedFacilities={selectedFacilities}
              />
              <Header
                title={t('HeaderNavigation.NavBarLinks.Home.Text')}
                leftItems={[
                  <FacilityAutocomplete
                    value={selectedFacilities}
                    setValue={setSelectedFacilities}
                  />,
                  <QuickActions selectedDate={selectedDate} />
                ]}
                rightItems={[
                  <FiltersPopover tag={Object.entries(appliedFilters).length}>
                    <Block
                      backgroundColor="#fff"
                      overrides={{
                        Block: {
                          style: ({ $theme }) => ({
                            maxWidth: '284px',
                            [$theme.mediaQuery.medium]: {
                              left: 'auto',
                              right: 0
                            }
                          })
                        }
                      }}>
                      <HeadingXSmall margin="0 0 8px">
                        {t('Common.Button.Filters.Text')}
                      </HeadingXSmall>
                      <FlexGrid flexGridRowGap="scale300">
                        <FlexGridItem>
                          <CyberSearchRefinementList
                            attribute="checkin_status"
                            setAppliedFilters={setAppliedFilters}
                          />
                        </FlexGridItem>
                        <FlexGridItem>
                          <SchedulerRefinementList setAppliedFilters={setAppliedFilters} />
                        </FlexGridItem>
                        <FlexGridItem>
                          <CyberSearchRefinementList
                            attribute="created_by_name"
                            setAppliedFilters={setAppliedFilters}
                          />
                        </FlexGridItem>
                        <FlexGridItem>
                          <TimeRangeToggle
                            cachedTimeRange={cachedTimeRange}
                            setCachedTimeRange={setCachedTimeRange}
                            currentUser={currentUser}
                            setAppliedFilters={setAppliedFilters}
                          />
                        </FlexGridItem>
                      </FlexGrid>
                    </Block>
                  </FiltersPopover>,
                  <UploadButton onClick={onUploadClick} />,
                  <CreateButton
                    disabled={selectedFacilities.length > 1}
                    onClick={() => {
                      setSelectedEvent({
                        facilityId: selectedFacilities[0].id,
                        arrivalTime: selectedDate.toString()
                      })
                    }}
                  />
                ]}
              />
              <div className={css({ position: 'relative' })}>
                {Object.keys(appliedFilters).length > 0 && (
                  <FilterSummary
                    numberOfHits={numberOfHits}
                    appliedFilters={appliedFilters}
                    currentUser={currentUser}
                    cachedTimeRange={cachedTimeRange}
                    setCachedTimeRange={setCachedTimeRange}
                    styling={css({
                      marginBottom: theme.sizing.scale600
                    })}
                  />
                )}
                <CalendarHits
                  selectedEvent={selectedEvent}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                  setSelectedEvent={setSelectedEvent}
                  timeRange={
                    cachedTimeRange[currentUser.shipperId] &&
                    cachedTimeRange[currentUser.shipperId][currentUser.id]
                      ? cachedTimeRange[currentUser.shipperId][currentUser.id]
                      : cachedTimeRange
                  }
                  selectedFacilities={selectedFacilities}
                  setNumberOfHits={setNumberOfHits}
                />
              </div>
            </CyberSearch>
          </CalendarSelectedOptionsProvider>
        </FacilitiesContext.Provider>
      </CalendarReferenceContext.Provider>
    </CustomLabelsContext.Provider>
  )
}

export default InstantCalendar
