import { useStyletron } from 'baseui'

import { useCarriersContext } from 'components/contexts/carriers.context'
import CarrierModal from 'components/pages/Carriers/CarrierModal'
import { StyledLinkStyled } from 'components/pages/CarriersUsers/CarrierUsers.styled'
import Button from 'components/ui/generic/Button'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from 'components/ui/generic/FormControl'

interface CarrierSelectProps {
  appointment: any
  setAppointment: any
  schedulerId?: string
  createdAsOther?: boolean
  disabled?: boolean
}

const CarrierSelect = ({
  appointment,
  setAppointment,
  schedulerId,
  createdAsOther,
  disabled = false
}: CarrierSelectProps) => {
  const {
    carriers: { createdCarrier },
    actions: { setModalState, setFormParams }
  } = useCarriersContext()

  const [key, setKey] = useState(0)
  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  useEffect(() => {
    setFormParams({
      schedulerId: schedulerId,
      externalUser: true,
      createdAsOther: createdAsOther
    })
  }, [])

  useEffect(() => {
    setAppointment({ ...appointment, carrierId: createdCarrier.id })
    setKey(key + 1)
  }, [createdCarrier])

  return (
    <>
      <div className={css({ position: 'relative', marginBottom: theme.sizing.scale600 })}>
        <FormControl
          label={t('Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.Carrier.Text')}
          disabled={disabled}>
          <CyberAutocomplete
            key={key}
            record={appointment}
            setRecord={setAppointment}
            indexName="carrier"
            placeholder={t(
              'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.Carrier.Placeholder'
            )}
          />
        </FormControl>
        <Button size="mini" onClick={() => setModalState(true)} disabled={disabled}>
          {t(
            'Scheduler.OpenScheduling.Steps.Fields.PurchaseOrder.Fields.Carrier.CreateCarrier.Text'
          )}
        </Button>
      </div>
      <CarrierModal />
    </>
  )
}

export default CarrierSelect
