import { CustomLabels, CustomLabelsContext } from 'components/contexts/custom-labels-context'
import { RefreshSearchContext } from 'cyber/search/search'
import React, { useContext, useEffect, useState } from 'react'
import { FacilityOption } from '../hooks/use-default-facilities'
import CreateAppointmentModal from './create-appointment-modal'
import BlackoutModal from './BlackoutModal'
import EditAppointmentModal from './edit-appointment-modal'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import { CarriersProvider } from '../../../contexts/carriers.context'

interface ModalsProps {
  selectedEvent: SelectedEvent
  setSelectedEvent: (SelectedEvent) => void
  selectedFacilities?: FacilityOption[]
  mode?: string
}

const Modals = (props: ModalsProps) => {
  const { customLabels, setCustomLabels } = useContext(CustomLabelsContext)
  const { refresh } = useContext(RefreshSearchContext)
  const { selectedEvent, setSelectedEvent, selectedFacilities, mode } = props

  const [initialCustomLabels, setInitialCustomLabels] = useState<CustomLabels>(customLabels)

  useEffect(() => {
    setInitialCustomLabels(customLabels)
  }, [selectedEvent])

  if (!selectedEvent) {
    return <></>
  }

  let Modal = null

  if (mode === 'editAppointment' || (selectedEvent.id && !selectedEvent.blackout)) {
    Modal = EditAppointmentModal
  } else if (selectedEvent.blackout) {
    Modal = BlackoutModal
  } else {
    Modal = CreateAppointmentModal
  }

  return (
    <CustomQuestionsProvider>
      <CarriersProvider>
        <Modal
          selectedEvent={selectedEvent}
          isOpen={!!selectedEvent}
          close={() => {
            setSelectedEvent(null)
            refresh()
            setCustomLabels(initialCustomLabels)
          }}
          selectedFacilities={selectedFacilities}
          unstable_ModalBackdropScroll
        />
      </CarriersProvider>
    </CustomQuestionsProvider>
  )
}

export default Modals
