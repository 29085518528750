import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { ParagraphMedium } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ResultBox from '../ResultBox'
import { DetailContainer } from './DriversResultDetail.styled'

const DriversResultDetail = ({
  resetResult,
  confirmAppointment,
  appointment
}: {
  resetResult: () => void
  confirmAppointment: () => void
  appointment: any
}): JSX.Element => {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()

  return (
    <DetailContainer>
      <ParagraphMedium>{appointment.shipperName}</ParagraphMedium>
      <ResultBox
        type="multi"
        content={{
          label: t('DriversCheckin.ResultDetail.AppointmentId'),
          text: appointment.confirmationId
        }}
        key={appointment.confirmationId}
      />
      <ResultBox
        type="single"
        content={{
          label: appointment.purchaseOrderLabel,
          text: appointment.purchaseOrderIdentifiers.split(',').map((p, i, arr) => (
            <span key={p}>
              {` ${p}`}
              {i !== arr.length - 1 && ','}
            </span>
          ))
        }}
        key={appointment.purchaseOrderLabel}
      />
      {appointment?.schedulerName && (
        <ResultBox
          type="single"
          content={{
            label: t('DriversCheckin.ResultDetail.Scheduler'),
            text: appointment?.schedulerName
          }}
          key="Scheduler"
        />
      )}
      <ResultBox
        type="single"
        content={{
          label: t('DriversCheckin.ResultDetail.Facility'),
          text: appointment.facilityName
        }}
        key={appointment.facilityName}
      />
      <ResultBox
        type="single"
        content={{
          label: t('DriversCheckin.ResultDetail.AppointmentType'),
          text: appointment.appointmentType
        }}
        key="Appointment type"
      />
      <ResultBox
        type="multi"
        content={{
          label: t('DriversCheckin.ResultDetail.ArrivalTime'),
          text: new Date(appointment.arrivalTime).toLocaleString()
        }}
        key={appointment.arrivalTime}
      />

      <div className={css({ width: '100%', display: 'flex', justifyContent: 'space-between' })}>
        <Button onClick={resetResult}>{t('DriversCheckin.ResultDetail.Back')}</Button>
        <Button
          colors={{ backgroundColor: theme.colors.positive, color: 'white' }}
          onClick={confirmAppointment}>
          {t('DriversCheckin.ResultDetail.Checkin')}
        </Button>
      </div>
    </DetailContainer>
  )
}

export default DriversResultDetail
