import React, { useState, useEffect, useContext } from 'react'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import DateAndTimeStep from 'components/facilities/steps/date-and-time-step'
import StyledSpinner from 'components/shared/styled-spinner'
import { Card, StyledAction } from 'baseui/card'
import PurchaseOrderIdentifiers from '../fields/purchase-order-identifiers'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import Header from 'components/ui/generic/Header'
import FormControl from 'components/ui/generic/FormControl'
import Button from 'components/ui/specific/ErrorButton'
import ArrivalTime from '../fields/arrival-time'
import ErrorMessageButton from 'components/shared/error-message-button'

import CancelAppointment from '../modals/cancel-appointment'
import moment from 'moment'
import { isEqual } from 'lodash'
import { fancyToast } from 'components/utils'
import Success from './success'
import { useStyletron } from 'baseui'
import ReasonCode from './reason-code'
import { trim } from 'lodash'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { answerService } from 'components/services/answer.service'
import checkAnswersRequireness from '../../utils/answer-requireness-validation'
import EquipmentTypeWithCapacity from '../../components/EquipmentType/EquipmentTypeWithCapacity'
import useSizing from '../../shared/hooks/use-sizing'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { CarriersProvider } from 'components/contexts/carriers.context'
import CarrierSelect from 'components/facilities/steps/fields/carrier-select'
import { CARRIER, SCHEDULER, SHIPPER } from 'components/models/User'
import SchedulerSelect from 'components/facilities/steps/fields/scheduler-select'
import { ValidateOrderError, ValidateOrderResponse } from 'components/services/order'
import { LabelSmall, LabelXSmall } from 'baseui/typography'
import ScheduleFormAnswer from './schedule-form-answer'
import { DOCUMENT_TYPE } from 'components/models/Question'
import { CHECKED_OUT } from 'components/utils/appointment-statuses'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const Schedule = ({ match }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [css, theme] = useStyletron()
  const [loading, setLoading] = useState<boolean>(false)
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [appointment, setAppointment] = useState(null)
  const [initialPurchaseOrders, setInitialPurchaseOrders] = useState(null)
  const [facility, setFacility] = useState(null)
  const [scheduler, setScheduler] = useState<any>({})
  const { customBreakPoints } = useSizing()
  const history = useHistory()
  const [orderValidation, setOrderValidation] = React.useState<ValidateOrderResponse>({
    canProceed: true,
    errors: []
  })

  const {
    answers,
    temporalFiles,
    documentSelections,
    actions: { setAnswers, updateFileUploadState, resetTemporalFilesState }
  } = useCQStateValue()

  const formErrors = () => {
    const filteredPurchaseOrders = appointment.purchaseOrders.filter(({ _destroy }) => !_destroy)
    const purchaseOrdersChanged = !isEqual(filteredPurchaseOrders, initialPurchaseOrders)
    const schedulerMustBeSelected = currentUser?.userType !== CARRIER || appointment.schedulerId
    const errorValidations = [
      {
        label: 'At least one purchase order must be present.',
        status: filteredPurchaseOrders.length > 0
      },
      {
        // TODO(adenta) this should really take into account the `days_in_advanced` field
        label: "Purchase orders can't be changed within 24 hours of appointment.",
        status:
          !purchaseOrdersChanged ||
          moment().add(1, 'day').isBefore(moment(appointment?.arrivalTime))
      },
      {
        label: "Purchase orders can't be updated on LTL appointments",
        status: !purchaseOrdersChanged || !appointment.lessThanTruckload
      },
      {
        label: 'Some identifiers have validation problems',
        status: orderValidation.canProceed
      },
      {
        label: 'Reason Code must be selected.',
        status: !!appointment?.comment
      },
      { label: 'Answers must meet the requirements', status: !answers.some(e => e.error) }
    ]

    checkAnswersRequireness(answers, temporalFiles, documentSelections, errorValidations)

    return errorValidations
  }

  useEffect(() => {
    authenticatedFetch({
      path: `/appointments/${match.params.handle}.json`
    }).then(([json, status]) => {
      if ([304, 200].includes(status)) {
        setAppointment({ ...json })
        setInitialPurchaseOrders(json.purchaseOrders)
        setAnswers(
          json.answersAttributes.map(answer => ({
            ...answer,
            error:
              (!answer.question?.questionPermissionsAttributes[0] ||
                answer.question?.questionPermissionsAttributes[0]?.required) &&
              ((answer.response !== null ? answer.response.length === 0 : true) ||
                answer.response === undefined) &&
              answer.type === DOCUMENT_TYPE &&
              answer.attachedDocuments?.length === true
          }))
        )
      } else {
        history.push('/appointments')
      }
    })
  }, [])

  useEffect(() => {
    if (appointment && !facility) {
      authenticatedFetch({
        path: `/facilities.json/?id[]=${appointment.facilityId}`
      }).then(([json, status]) => {
        if ([304, 200].includes(status)) {
          setFacility(json[0])
        }
      })
    }
  }, [appointment])

  const rescheduleAppointment = async () => {
    setLoading(true)
    const [json, status] = await authenticatedFetch({
      path: `/appointments/${appointment.id}.json`,
      method: 'PATCH',
      body: {
        appointment: {
          arrivalTime: appointment.arrivalTime,
          purchaseOrdersAttributes: appointment.purchaseOrders.map(
            (order: { identifier: string; id: string }) => ({
              ...order,
              identifier: trim(order.identifier)
            })
          ),
          quantity: appointment.quantity,
          comment: appointment.comment,
          dockId: appointment.dockId,
          carrierId: appointment.carrierId,
          schedulerId: appointment.schedulerId,
          equipmentTypeId: appointment.equipmentTypeId,
          ...(answers && { answersAttributes: answers })
        }
      }
    })

    setLoading(false)

    if (status === 200) {
      setAppointment(json)
      setUpdated(true)
    } else {
      fancyToast(json, status)
    }
  }

  const updateAppointmentType = () => {
    if (documentSelections.length) {
      documentSelections.forEach(({ documentId, questionId }) =>
        answerService.removeFile(documentId, questionId)
      )
    }
    if (temporalFiles.length) {
      return updateFileUploadState(true)
    }
    return rescheduleAppointment()
  }

  const isFormDisabled = () =>
    appointment?.status === CHECKED_OUT && currentUser?.userType !== SHIPPER

  useEffect(() => {
    if (temporalFiles.length && temporalFiles.every(tf => tf?.uploadState === 'saved')) {
      resetTemporalFilesState()
      rescheduleAppointment()
    }
  }, [answers, temporalFiles])

  const { t } = useTranslation()

  if (!facility || !appointment) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '60vh',
          flexDirection: 'column'
        })}>
        <div className={css({ paddingBottom: theme.sizing.scale200 })}>
          <StyledSpinner />
        </div>
      </div>
    )
  }

  if (updated) {
    return <Success />
  }

  return (
    <>
      <CancelAppointment
        isOpen={openCancelModal}
        close={() => {
          setOpenCancelModal(false)
        }}
        externalScheduler
        {...{ appointment, setAppointment }}
      />
      <Header title={t('Common.Button.UpdateAppointment.Text')} />

      <Card
        title={`${facility.name} Appointment`}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.white,
              borderTopWidth: $theme.borders.borderWidth,
              borderBottomWidth: $theme.borders.borderWidth,
              borderLeftWidth: $theme.borders.borderWidth,
              borderRightWidth: $theme.borders.borderWidth,
              borderTopColor: $theme.colors.inputBorder,
              borderBottomColor: $theme.colors.inputBorder,
              borderLeftColor: $theme.colors.inputBorder,
              borderRightColor: $theme.colors.inputBorder,
              margin: '0 auto'
            })
          }
        }}>
        <FlexGrid
          flexGridColumnGap="scale1000"
          flexDirection={['column', 'column', 'row']}
          flexGridColumnCount={[1, 1, isFormDisabled() ? 1 : 2]}>
          <FlexGridItem flex="1.5" maxWidth="400px">
            <PurchaseOrderIdentifiers
              facility={facility}
              schedulerId={appointment.schedulerId}
              purchaseOrders={appointment.purchaseOrders}
              setOrderValidation={setOrderValidation}
              orderValidation={orderValidation}
              setPurchaseOrders={purchaseOrders => {
                setAppointment({ ...appointment, purchaseOrders })
              }}
              disabled={isFormDisabled()}
            />
            {orderValidation.errors?.length > 0 && (
              <FlexGridItem display="flex" alignItems="start" justifyContent="start">
                <div style={{ margin: '-8px 0px 20px 0px' }}>
                  <LabelSmall color="red">List of Order Validation Errors:</LabelSmall>
                  <ul style={{ marginTop: '5px', paddingLeft: '25px' }}>
                    {orderValidation.errors?.map((error: ValidateOrderError, i: number) => (
                      <li color="red">
                        <LabelXSmall color="red" key={error.index + i}>
                          {error.message}
                        </LabelXSmall>
                      </li>
                    ))}
                  </ul>
                </div>
              </FlexGridItem>
            )}
            {currentUser?.userType == SCHEDULER && (
              <CarriersProvider>
                <FlexGridItem>
                  <CarrierSelect
                    schedulerId={currentUser.schedulerId}
                    appointment={appointment}
                    setAppointment={setAppointment}
                    createdAsOther={true}
                    disabled={isFormDisabled()}
                  />
                </FlexGridItem>
              </CarriersProvider>
            )}
            {currentUser?.userType == CARRIER && (
              <FlexGridItem>
                <SchedulerSelect
                  scheduler={scheduler}
                  facility={facility}
                  setScheduler={setScheduler}
                  appointment={appointment}
                  setAppointment={setAppointment}
                  disabled={isFormDisabled()}
                />
              </FlexGridItem>
            )}
            <EquipmentTypeWithCapacity
              record={appointment}
              setRecord={setAppointment}
              disabled={isFormDisabled()}
              dockCapacitiesAttributes={facility?.appointmentPreference?.dockCapacitiesAttributes}
              equipmentCapacityShared={facility?.appointmentPreference?.equipmentCapacityShared}
              label="Equipment Type"
              recordAttributeName="equipmentTypeId"
            />

            <FormControl label="Reason to reschedule">
              <ReasonCode
                appointment={appointment}
                setAppointment={setAppointment}
                disabled={isFormDisabled()}
              />
            </FormControl>
            <ScheduleFormAnswer disabled={isFormDisabled()} />
          </FlexGridItem>
          {!isFormDisabled() && (
            <FlexGridItem flex="1">
              <FormControl label="Arrival Time">
                <>
                  <ArrivalTime
                    {...{
                      appointment,
                      facility
                    }}
                  />
                  <DateAndTimeStep
                    {...{ appointment, setAppointment, facility }}
                    purchaseOrders={appointment.purchaseOrders}
                  />
                </>
              </FormControl>
            </FlexGridItem>
          )}
        </FlexGrid>
        <StyledAction>
          <FlexGrid flexGridRowGap="scale600" flexGridColumnGap="scale800" flexGridColumnCount={1}>
            <FlexGridItem display="flex" gridColumnGap="scale600" justifyContent="space-between">
              <Button
                disabled={isFormDisabled()}
                onClick={() => setOpenCancelModal(true)}
                isLoading={loading}>
                {t('Common.Button.CancelAppointment.Text')}
              </Button>
              <ErrorMessageButton
                errors={
                  isFormDisabled()
                    ? [
                        {
                          label: 'Appointment is already checked out',
                          status: false
                        }
                      ]
                    : formErrors()
                }
                buttonProps={{
                  onClick: updateAppointmentType,
                  isLoading: loading
                }}
                disabled={isFormDisabled()}
                statefulTooltipProps={{ placement: 'left' }}
                label={t('Common.Button.UpdateAppointment.Text')}
              />
            </FlexGridItem>
          </FlexGrid>
        </StyledAction>
      </Card>
    </>
  )
}

export default Schedule
