import React from 'react'
import { KIND } from 'baseui/button'
import { useTranslation } from 'react-i18next'
import { Columns } from '@phosphor-icons/react'
import { Tag, VARIANT } from 'baseui/tag'
import Button from '../generic/Button'

const ColumnsButton = ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <Button {...props} className="filters-button" kind={KIND.secondary} IconStart={<Columns />}>
      {props?.buttonText ?? t('Common.Button.Filters.Text')}

      {props.tag > 0 && (
        <Tag
          variant={VARIANT.solid}
          overrides={{
            Text: {
              style: ({ $theme }) => ({
                fontSize: $theme.typography.LabelXSmall.fontSize,
                lineHeight: 1
              })
            },
            Root: {
              style: ({ $theme }) => ({
                height: '16px',
                width: '16px',
                paddingLeft: $theme.sizing.scale100,
                paddingRight: $theme.sizing.scale100,
                marginTop: 0,
                marginBottom: 0,
                marginRight: 0,
                backgroundColor: '#496399'
              })
            }
          }}
          closeable={false}>
          {props.tag}
        </Tag>
      )}
    </Button>
  )
}
export default ColumnsButton
