import { DriversCheckinProvider } from 'components/contexts/drivers-checkin-context'
import DriversCheckinPage from 'components/pages/DriversCheckin'
import DriversCheckinDetailPage from 'components/pages/DriversCheckin/pages/DriversCheckinDetailPage'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

const DriversCheckinRoutes = ({ match }) => (
  <DriversCheckinProvider>
    <Switch>
      <Route path={`${match.url}/detail`} component={DriversCheckinDetailPage} />
      <Route path={`${match.url}`} component={DriversCheckinPage} />
    </Switch>
  </DriversCheckinProvider>
)

export default DriversCheckinRoutes
