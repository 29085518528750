import styled from 'styled-components'

export const AnimatedCheck = styled.img`
  width: 50%;
  border-radius: 50%;

  @media (orientation: landscape) {
    width: auto;
    height: 25vh;
  }
`

export const MessageContainer = styled.div`
  height: 100vh;
  background: #f5f9ff;
  padding: 16px;
  height: calc(100% - 175px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  position: relative;

  @media (orientation: landscape) {
    height: unset;
  }
`

export const BtnContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (orientation: landscape) {
    position: absolute;
    height: unset;
    top: 10px;
    right: 15px;
    padding: 0;
  }
`
