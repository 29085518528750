import { Facility } from 'components/models/Facility'
import { Order } from 'components/models/Order'
import ordersReducer, {
  SET_FACILITY,
  SET_LOADING,
  SET_MODAL,
  SET_ORDER,
  SET_ORDER_PREFERENCES,
  SET_SCHEDULER,
  SET_UOM,
  SET_UOM_OPTIONS,
  SET_WEIGHT_UOM
} from 'components/reducers/orders.reducer'
import { orderService } from 'components/services/order'
import { orderPreferencesService } from 'components/services/order/orderPreferences.service'
import { unitOfMeasureService } from 'components/services/order/unitOfMeasure.service'
import { fancyToast } from 'components/utils'
import { trim } from 'lodash'
import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react'

const CATEGORY_UOM = {
  weight: 'weight',
  package: 'package'
}
export interface OrdersContextProps {
  orders: {
    order: Order
    selectedFacility: Facility[]
    selectedScheduler: string
    selectedCarrier: string
    loading: boolean
    unitOfMeasureOptions: any[]
    selectedUnitOfMeasure: any[]
    isModalOpen: boolean
    orderPreferences: any
    weightUnitOfMeasureOptions: any[]
    selectedWeightUnitOfMeasure: any[]
  }
}

const initialState: OrdersContextProps = {
  orders: {
    order: {
      orderType: 'purchase',
      priority: 'regular',
      status: 'open'
    },
    selectedFacility: [],
    selectedScheduler: null,
    selectedCarrier: null,
    loading: false,
    unitOfMeasureOptions: [],
    selectedUnitOfMeasure: [],
    isModalOpen: false,
    orderPreferences: {},
    weightUnitOfMeasureOptions: [],
    selectedWeightUnitOfMeasure: []
  }
}

export const OrdersContext = createContext({} as any)

export const OrdersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ordersReducer, initialState)

  useEffect(() => {
    unitOfMeasureService.getUnitOfMeasures().then(([value]) => {
      if (value && value.length > 0) {
        setUnitOfMeasuresOptions({
          package: value
            .filter(uom => uom?.category === CATEGORY_UOM.package)
            .map(unitOfMeasure => ({ label: unitOfMeasure.name, id: unitOfMeasure.id })),
          weight: value
            .filter(uom => uom?.category === CATEGORY_UOM.weight)
            .map(unitOfMeasure => ({ label: unitOfMeasure.name, id: unitOfMeasure.id }))
        })
      }
    })
  }, [])
  useEffect(() => {
    orderPreferencesService.getOrderPreferences().then(([value]) => {
      if (value && value.length > 0) {
        setOrderPreferences(value[0])
      }
    })
  }, [])

  const clearForm = () => {
    setOrder(initialState.orders.order)
    setFacility([])
    setScheduler([])
    setUnitOfMeasure([])
    setWeightUnitOfMeasure([])
  }
  const setOrderPreferences = value => {
    dispatch({ type: SET_ORDER_PREFERENCES, payload: value })
  }
  const setModal = value => {
    dispatch({ type: SET_MODAL, payload: value })
  }
  const closeModal = () => {
    dispatch({ type: SET_MODAL, payload: false })
  }
  const setUnitOfMeasuresOptions = value => {
    dispatch({ type: SET_UOM_OPTIONS, payload: value })
  }
  const setScheduler = value => {
    dispatch({ type: SET_SCHEDULER, payload: value })
  }
  const setFacility = value => {
    dispatch({ type: SET_FACILITY, payload: value })
  }
  const setLoading = value => {
    dispatch({ type: SET_LOADING, payload: value })
  }
  const setUnitOfMeasure = value => {
    dispatch({ type: SET_UOM, payload: value })
  }
  const setWeightUnitOfMeasure = value => {
    dispatch({ type: SET_WEIGHT_UOM, payload: value })
  }

  const onChangeScheduler = value => {
    setScheduler(value)
    setOrder({
      ...state.orders.order,
      schedulerId: value
    })
  }

  const setOrder = (value: Order) => {
    dispatch({ type: SET_ORDER, payload: value })
  }

  const setSelectedOrder = (value: string) => {
    if (value) {
      orderService.findOrder(value).then(([order]) => {
        if (order && Object.entries(order).length > 0) {
          order.startDatePeriod = order.startDatePeriod ? getLocalDate(order.startDatePeriod) : null
          order.endDatePeriod = order.endDatePeriod ? getLocalDate(order.endDatePeriod) : null
          clearForm()
          setOrder(order)
          setFacility([order.facility])
          setScheduler(order.schedulerId)
          setUnitOfMeasure(order.quantityUom)
          setWeightUnitOfMeasure(order.weightUom)
          setModal(true)
        }
      })
    }
  }

  const formatOrderUTCDate = orderDate => {
    return orderDate ? getUTCDate(orderDate) : null
  }

  const getUTCDate = date => {
    const localDate = new Date(date)
    return new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
      .toISOString()
      .split('T')[0]
  }

  const getLocalDate = date => {
    const utcDate = new Date(date)
    return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate())
  }

  const saveChanges = useCallback(async () => {
    setLoading(true)
    if (state.orders.order?.id) {
      orderService
        .updateOrder({
          ...state.orders.order,
          number: trim(state.orders.order.number),
          startDatePeriod: formatOrderUTCDate(state.orders.order.startDatePeriod),
          endDatePeriod: formatOrderUTCDate(state.orders.order.endDatePeriod)
        })
        .then(([json, status]) => {
          if (typeof json !== 'string') {
            fancyToast({ '': json.order }, status)
            setLoading(false)
            closeModal()
          }
        })
    } else {
      orderService
        .createOrder({
          ...state.orders.order,
          number: trim(state.orders.order.number),
          startDatePeriod: formatOrderUTCDate(state.orders.order.startDatePeriod),
          endDatePeriod: formatOrderUTCDate(state.orders.order.endDatePeriod)
        })
        .then(([json, status]) => {
          fancyToast(json, status)
          setLoading(false)
          closeModal()
        })
    }
  }, [state.orders.order])

  const actions = {
    setSelectedOrder,
    setFacility,
    setLoading,
    setUnitOfMeasure,
    setWeightUnitOfMeasure,
    onChangeScheduler,
    setOrder,
    saveChanges,
    setModal,
    closeModal,
    clearForm
  }

  return <OrdersContext.Provider value={{ ...state, actions }}>{children}</OrdersContext.Provider>
}

export const useOrdersContext = () => useContext(OrdersContext)
